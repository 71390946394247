import { RootState } from '../store/configureStore';

export const getOfferItems = (state: RootState) => state?.offerItem?.offerItems;

export const getActiveOfferItem = (state: RootState) => state?.offerItem?.activeOfferItem;

export const isFetchingOfferItems = (state: RootState) => state?.offerItem?.isFetchingOfferItems;

export const isPostingOfferItem = (state: RootState) => state?.offerItem?.isPostingOfferItem;
export const postingOfferItemFailed = (state: RootState) => state?.offerItem?.postingOfferItemFailed;
export const postingOfferItemError = (state: RootState) => state?.offerItem?.postingOfferItemError;
export const postingOfferItemValidationErrors = (state: RootState) => state?.offerItem?.postingOfferItemValidationErrors;
export const fetchingOfferItemsFailed = (state: RootState) => state?.offerItem?.fetchingOfferItemsFailed;