import React, { useEffect, useRef, useState } from 'react';
import './ItemsTable.scss';
import Input from '../../Input';
import EMEModal from '../../Modal/Modal';
import { DataTableParamsModel } from '../../../types/Common';
import { fetchViews } from '../../../actions/View';
import { useDispatch, useSelector } from 'react-redux';
import { getViews } from '../../../selectors/View';
import DropDown from '../../DropDown';
import { fetchEntries } from '../../../actions/Entry';
import { getEntries } from '../../../selectors/Entry';
import TextArea from '../../TextArea/TextArea';
import { Eye, XCircleFill } from 'react-bootstrap-icons';
import { Row } from '../../../types/Common';
import { downloadQRCode, formatUrl, getRateColor } from '../../../helpers/functions';
import QRCode from 'react-qr-code';
import c, { ABB_UUIDS } from '../../../helpers/constants';
import assetGallery from '../../AssetGallery';
import { getLoggedInRole, getLoggedInUser } from '../../../selectors/Auth';

interface OfferItem {
  id: string;
  offerId: string;
  entryId: string;
  quantity: number;
  entryTitle: string;
  minRate: number;
  maxRate: number;
  actualRate: number;
  details: string | null;
  statuses: string[];
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

type Item = {
  value: string | number;
  label?: string;
  type?: string;
  minRate?: number;
  maxRate?: number;
  quantity?: number;
};

interface StripedTableProps {
  tableTitle?: string;
  info?: string;
  rowsData: OfferItem[] | undefined;
  columnCount: 4 | 5;  // Add a prop for column count
  editable?: boolean;
  onRowsUpdate?: (rows: Row[]) => void;
  clientId?: string | undefined;
  groupId?: string | undefined;
  type?: 'EME' | 'ABB';
  showQRCodes?: boolean;
}

const StripedTable: React.FC<StripedTableProps> = ({ columnCount, editable = false, tableTitle = 'Item Table', info = 'In the column “Amount,” a positive number means the Client owes money to ABB. However, if the amount is negative, it means ABB owes money to the Client.', rowsData: initialRows, onRowsUpdate, clientId, type, showQRCodes }) => {
  const dispatch = useDispatch();
  const views = useSelector(getViews);
  const entries = useSelector(getEntries);
  const [rows, setRows] = useState<Row[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false); // State to manage modal visibility
  const [selectedFormId, setSelectedFormId] = useState<string | undefined>(undefined);
  const [selectedEntryId, setSelectedEntryId] = useState<string | undefined>(undefined);
  const [hasEntry, setHasEntry] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [editValidationError, setEditValidationError] = useState<{ [key: string]: string | null }>({});
  const loggedInUser = useSelector(getLoggedInUser);
  const userRole = useSelector(getLoggedInRole);

  const [newRow, setNewRow] = useState<Row>({
    id: '',
    entryId: '',
    details: '',
    quantity: 0,
    minRate: 0,
    maxRate: 0,
    statuses: [],
    actualRate: 0,
  });
  const qrCodeRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const columnTitles = columnCount === 4 ? ['Details', 'Quantity', 'Price Per Unit', 'Amount'] :
    ['Details', 'Quantity', 'Price Per Unit Min', 'Price Per Unit Max', 'Amount'];

  const isEMESuperAdmin = loggedInUser?.groups.some(g => ABB_UUIDS.includes(g.groupId) && g.groupTitle === 'ABB') &&
      loggedInUser?.groups.some(g => g.groupTitle === 'EME') &&
      userRole?.accessLevel === 0;

  if (showQRCodes && columnCount === 4){
    columnTitles.push('Status');
  }

  const renderIcon = (icon: any) => {
    switch (icon) {
      case 'edit': {/* @ts-ignore */
      }
        return <img src={assetGallery.penIcon} alt='pen'/>;
      case 'info': {/* @ts-ignore */
      }
        return <img src={assetGallery.infoIcon2} alt='info'/>;
      case 'view': {/* @ts-ignore */
      }
        return <Eye/>;
      case 'bin': {/* @ts-ignore */
      }
        return <img src={assetGallery.archiveIcon} alt='archive'/>;
      case 'add': {/* @ts-ignore */
      }
        return <img src={assetGallery.addIcon2} alt='add' style={{ marginBottom: '3px' }}/>;
      default:
        return '';
      case 'close': {/* @ts-ignore */
      }
        return <XCircleFill/>;
    }
  };

  useEffect(() => {
    const dataTableParams1: DataTableParamsModel = {
      sortDirection: 'desc',
      paging: '100',
      searchField: 'clientId',
      searchWord: clientId,
    };
    if (clientId) {
      dispatch(fetchViews(dataTableParams1));
    }
  }, [dispatch, clientId]);


  useEffect(() => {
    const dataTableParams1: DataTableParamsModel = {
      sortDirection: 'desc',
      paging: '100',
      searchField: 'formId',
      searchWord: selectedFormId,
    };
    if (selectedFormId) {
      dispatch(fetchEntries(dataTableParams1));
    }
  }, [dispatch, selectedFormId]);

  useEffect(() => {
    if (initialRows) {
      const transformedRows = initialRows.map(item => ({
        id: item.id,
        entryId: item.entryId,
        details: item.details ?? '',
        quantity: item.quantity,
        minRate: item.minRate,
        maxRate: item.maxRate,
        actualRate: item.actualRate ?? 0,
        statuses: item.statuses ?? [],
      }));
      setRows(transformedRows);
    }
  }, [initialRows, columnCount]);

  const filteredData = views?.data.filter(item => item.formId !== null);

  const itemsViews = filteredData?.map(item => ({
    label: item.title,
    value: item.formId,
  })) || [];

  itemsViews.push({ label: 'Insert a new item', value: 'Other' });
  const itemsEntries = entries?.data.map(item => ({
    label: item.title,
    value: item.id,
    minRate: item.minRate,
    maxRate: item.maxRate,
    quantity: item.quantity,
  }));

  useEffect(() => {
    if (onRowsUpdate) {
      onRowsUpdate(rows);
    }
  }, [rows, onRowsUpdate]);

  const handleInputChange = (id: string, field: keyof Row, value: string) => {
    const updatedRows = rows.map(row =>
      row.id === id ? { ...row, [field]: value } : row,
    );

    // Validation
    const editedRow = updatedRows.find(row => row.id === id);
    if (editedRow && (field === 'minRate' || field === 'maxRate')) {
      if (Number(editedRow.minRate) > Number(editedRow.maxRate)) {
        setEditValidationError(prevErrors => ({
          ...prevErrors,
          [id]: 'Min Rate must be less than Max Rate',
        }));
      } else {
        setEditValidationError(prevErrors => ({
          ...prevErrors,
          [id]: null,
        }));
      }
    }

    setRows(updatedRows);
  };

  const handleOpenModal = () => {
    setValidationError(null);
    setShowModal(true);
    setNewRow({
      id: '',
      entryId: '',
      details: '',
      quantity: 0,
      minRate: 0,
      maxRate: 0,
      actualRate: 0,
      statuses: [],
    });
    setSelectedFormId(undefined);
    setSelectedEntryId(undefined);
  };


  const handleDeleteRow = (id: string) => {
    const updatedRows = rows.filter(row => row.id !== id);
    setRows(updatedRows);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setHasEntry(false);

  };

  const handleSaveNewRow = () => {
    if (Math.abs(Number(newRow.minRate)) > Math.abs(Number(newRow.maxRate))) {
      setValidationError('Min Rate must be less than Max Rate.');
      return;
    }
    const updatedRows = [...rows, { ...newRow, id: 'new' + (rows.length + 1).toString() }];
    setRows(updatedRows);
    setShowModal(false);
    setHasEntry(false);

  };

  const handleNewRowChange = (field: keyof Row, value: string) => {
    setNewRow({ ...newRow, [field]: value });
  };

  const handleViewsSelect = (formId: string) => {
    if (formId !== 'Other') {
      setSelectedFormId(formId);
      setNewRow(prevNewRow => ({
        ...prevNewRow,
        formId: formId ?? '',
      }));
      setHasEntry(true);
    } else {
      setSelectedFormId(formId);
      setNewRow(prevNewRow => ({
        ...prevNewRow,
        formId: formId ?? '',
      }));
      setHasEntry(false);
    }
  };

  const handleEntriesSelect = (entry: Item) => {
    setSelectedEntryId(entry.value.toString());

    // If the entry exists, set the newRow state with its values
    if (entry) {
      setNewRow(prevNewRow => ({
        ...prevNewRow,
        entryId: entry.value.toString(),
        quantity: entry.quantity || 0,
        minRate: entry.minRate || 0,
        maxRate: entry.maxRate || 0,
      }));
    }
  };

  const calculateTotalAmount = () => {
    if (columnCount === 4) {
      const total = rows.reduce((totalX, row) => totalX + row.quantity * (row.actualRate || 0), 0);
      return { minTotal: total, maxTotal: total };
    }

    const minTotal = rows.reduce((total, row) => total + row.quantity * row.minRate, 0);
    const maxTotal = rows.reduce((total, row) => total + row.quantity * row.maxRate, 0);

    return { minTotal, maxTotal };
  };

  const { minTotal, maxTotal } = calculateTotalAmount();

  const handleDownloadQRCode = (id: string) => {
    const qrCodeRef = qrCodeRefs.current[id];
    if (qrCodeRef) {
      downloadQRCode({ current: qrCodeRef });
    }
  };

  return (
      <div className="mb-4">
        <div className="striped-table__header">
          <div className="striped-table__title">
            {tableTitle}
          </div>
          <div className="striped-table__info">
            {renderIcon('info')}
            <div className="striped-table__info info-box">
              {info}
            </div>
          </div>
        </div>

        <div className="striped-table__container">
        <table className="striped-table">
          <thead>
          <tr>
            {columnTitles.map(title => (
                <th key={title} className={title === 'Details' ? 'fixed-column' : ''}>{title}</th>
            ))}
            <th colSpan={2}></th>
          </tr>
          </thead>
          <tbody>
          {rows.map(row => (
            columnCount === 4 ? (
                  <tr key={row.id}>
                    {(['details', 'quantity', 'actualRate', 'total'] as (keyof Row)[]).map(col => (
                        <>
                        <td key={col}>
                          {editable === true && col !== 'total' ? (
                            col === 'details' ?
                                  <TextArea
                                      disabled = {true}
                                      value={row[col]}
                                      onChange={(e) => handleInputChange(row.id, col, e)}
                                  /> :
                              <Input
                                  value={col === 'statuses' ? row.statuses[0] || '' : row[col]}
                                  type={'number'}
                                  disabled = { col === 'quantity'}
                                  onChange={(e) => handleInputChange(row.id, col, e)}
                              />
                          ) : (
                            col === 'total' ? Number(row.quantity) * Number(row.actualRate) : row[col]
                          )}
                        </td>
                        </>
                    ))}
                    {showQRCodes && columnCount === 4 && editable === false && (
                        <td>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {row.statuses && row.statuses.length > 0 && (
                              !isEMESuperAdmin ? (
                                    <span className="status-info">{row.statuses[0]}</span>
                              ) : (
                                row.statuses[0] === 'Delivered' ? (
                                        <span className="status-info">{row.statuses[0]}</span>
                                ) : (
                                        <a href={`/shipment/item/${row.id}`} style={{ textDecoration: 'none' }}>
              <span className="status-info">
                {row.statuses[0]}
              </span>
                                        </a>
                                )
                              )
                            )}
                            {row.statuses[0] !== 'Delivered' && <div ref={(el: any) => (qrCodeRefs.current[row.id] = el)} className="striped-table__links">
                              <QRCode style={{ display: 'none' }} value={`${c.APP_ROUTES.CORE}${formatUrl(c.APP_ROUTES.SHIPMENT_JOURNEY, row.id)}`} />
                              <img style={{ width: '20px', height: '20px' }} src={assetGallery.qrCodeDownload} onClick={() => handleDownloadQRCode(row.id)} />
                            </div>}
                          </div>
                        </td>
                    )}
                        <td>
                          {(editable === true && type === 'ABB') &&
                              <div className="striped-table__actions__action" key={row.id}
                               onClick={() => handleDeleteRow(row.id)}>
                            {renderIcon('bin')}
                          </div>
                          }
                        </td>
                  </tr>
            ) : (
                  <tr key={row.id}>
                    {(['details', 'quantity', 'minRate', 'maxRate', 'total'] as (keyof Row)[]).map(col => (
                        <td key={col}>
                          {editable === true && col !== 'total' ? (
                            col === 'details' ?
                                  <TextArea
                                      disabled = {true}
                                      value={row[col]}
                                      onChange={(e) => handleInputChange(row.id, col, e)}
                                  /> :
                                  <Input
                                      value={col === 'statuses' ? row.statuses[0] || '' : row[col]}
                                      type={'number'}
                                      onChange={(e) => handleInputChange(row.id, col, e)}
                                      customRegex={col === 'quantity' ? /^\d*\.?\d*$/ : undefined}
                                  />
                          ) : (
                              <div>
                                {col === 'total'
                                  ? `${row.quantity * row.minRate} to ${row.quantity * row.maxRate}`
                                  : row[col]}
                              </div>
                          )}
                        </td>
                    ))}
                        <td>
                          {(editable == true && type === 'ABB') &&
                              <div className="striped-table__actions__action" key={row.id}
                                   onClick={() => handleDeleteRow(row.id)}>
                                {renderIcon('bin')}
                              </div>
                          }
                    </td>
                  </tr>
            )
          ))}
          </tbody>
        </table>
        </div>


        <div className="striped-table__total-container">

        {(editable === true && type === 'ABB') && <div className="striped-table__actions__action"
                                 onClick={() => handleOpenModal()}>
          {renderIcon('add')} Add an Item
        </div>}
          <div className="striped-table__total-box" style={{ color: getRateColor(maxTotal, 'total') }}>
            {columnCount === 5
              ? `TOTAL ${minTotal} to ${maxTotal}`
              : `TOTAL ${maxTotal}`
            }
          </div>
        </div>
        <EMEModal
            show={showModal}
            onHide={handleCloseModal}
            onCta={handleSaveNewRow}
            cta="Save"
            title="Add New Item"
        >
          <>
            <DropDown
                className="item-category"
                id="viewId"
                placeholder="Select Item Category"
                required
                label="View"
                items={itemsViews}
                value={selectedFormId}
                onSelect={(e)=>handleViewsSelect(e.value.toString())}
                type="default"
            />
            {hasEntry && <DropDown
                id="entryId"
                placeholder="Please select an Entry"
                required
                label="Pick existing entry"
                items={itemsEntries}
                value={selectedEntryId}
                onSelect={(e)=>handleEntriesSelect(e)}
                type="default"
            />}
            {selectedFormId &&
                <div className="row">
                  <div className="col-12">
                    <div className="striped-table__blue-line"></div>
                    <TextArea value={newRow.details} placeholder="Insert text" label={'Details'}
                              onChange={(e) => handleNewRowChange('details', e)}/>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-12">
                      <Input value={newRow.quantity}
                             customRegex={/^\d*\.?\d*$/}
                             label="Quantity"
                             placeholder="0"
                             type={'number'}
                             onChange={(e) => handleNewRowChange('quantity', e)}/>
                    </div>
                    <div className="col-md-4 col-12">
                      <Input value={newRow.minRate}
                             label="Min Rate"
                             placeholder="0"
                             type={'number'}
                             onChange={(e) => handleNewRowChange('minRate', e)}/>
                    </div>
                    <div className="col-md-4 col-12">
                      <Input value={newRow.maxRate} placeholder="0"
                             label="Max Rate"
                             type={'number'}
                             onChange={(e) => handleNewRowChange('maxRate', e)}/>
                    </div>
                  </div>
                  <p className="mt-2 mb-0"><strong>* Negative ranges to be provided with lower value as Min Rate and higher value as Max Rate.</strong></p>
                </div>
            }
            {validationError && <div className="striped-table__validation-error">{validationError}</div>}
          </>
        </EMEModal>
        {Object.keys(editValidationError).map(key => (
          editValidationError[key] && <div key={key} className="striped-table__validation-error">{editValidationError[key]}</div>
        ))}
      </div>
  );
};

export default StripedTable;
