import { RootState } from '../store/configureStore';


export const getSubscriptions = (state: RootState) => state?.subscription?.subscriptions;
export const getActiveSubscription = (state: RootState) => state?.subscription?.activeSubscription;
export const isFetchingSubscriptions = (state: RootState) => state?.subscription?.isFetchingSubscriptions;
export const isPostingSubscription = (state: RootState) => state?.subscription?.isPostingSubscription;
export const postingSubscriptionFailed = (state: RootState) => state?.subscription?.postingSubscriptionFailed;
export const postingSubscriptionError = (state: RootState) => state?.subscription?.postingSubscriptionError;
export const postingSubscriptionValidationErrors = (state: RootState) => state?.subscription?.postingSubscriptionValidationErrors;
export const fetchingSubscriptionsFailed = (state: RootState) => state?.subscription?.fetchingSubscriptionsFailed;
