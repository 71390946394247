import {
  FETCH_OFFER_ITEM_STATUS_CODE_FAILURE,
  FETCH_OFFER_ITEM_STATUS_CODE_REQUEST,
  FETCH_OFFER_ITEM_STATUS_CODE_RESPONSE,
  FETCH_OFFER_ITEM_STATUS_CODES_FAILURE,
  FETCH_OFFER_ITEM_STATUS_CODES_REQUEST,
  FETCH_OFFER_ITEM_STATUS_CODES_RESPONSE,
  POST_OFFER_ITEM_STATUS_CODE_FAILURE,
  POST_OFFER_ITEM_STATUS_CODE_RESPONSE,
  OfferItemStatusCodeActionTypes,
  OfferItemStatusCodeState,
  POST_OFFER_ITEM_STATUS_CODE_REQUEST,
} from '../types/OfferItemStatusCode';
import { parseValidationErrors } from '../helpers/functions';

const initialState: OfferItemStatusCodeState = {
  offerItemStatusCodes: null,
  activeOfferItemStatusCode: null,
  isFetchingOfferItemStatusCodes: false,
  fetchingOfferItemStatusCodesFailed: false,
  isPostingOfferItemStatusCode: false,
  postingOfferItemStatusCodeFailed: false,
  postingOfferItemStatusCodeError: null,
  postingOfferItemStatusCodeValidationErrors: null,
};

const offerItemStatusCodesReducer = (
  state = initialState,
  action: OfferItemStatusCodeActionTypes,
): OfferItemStatusCodeState => {
  switch (action.type) {
    case FETCH_OFFER_ITEM_STATUS_CODES_REQUEST:
      return {
        ...state,
        offerItemStatusCodes: null,
        isFetchingOfferItemStatusCodes: true,
        fetchingOfferItemStatusCodesFailed: false,
      };
    case FETCH_OFFER_ITEM_STATUS_CODES_RESPONSE:
      return {
        ...state,
        offerItemStatusCodes: action.payload || [],
        isFetchingOfferItemStatusCodes: false,
        fetchingOfferItemStatusCodesFailed: false,
      };
    case FETCH_OFFER_ITEM_STATUS_CODES_FAILURE:
      return {
        ...state,
        offerItemStatusCodes: null,
        isFetchingOfferItemStatusCodes: false,
        fetchingOfferItemStatusCodesFailed: true,
      };
    case FETCH_OFFER_ITEM_STATUS_CODE_REQUEST:
      return {
        ...state,
        activeOfferItemStatusCode: null,
        isFetchingOfferItemStatusCodes: true,
        fetchingOfferItemStatusCodesFailed: false,
      };
    case FETCH_OFFER_ITEM_STATUS_CODE_RESPONSE:
      return {
        ...state,
        activeOfferItemStatusCode: action.payload,
        isFetchingOfferItemStatusCodes: false,
        fetchingOfferItemStatusCodesFailed: false,
      };
    case FETCH_OFFER_ITEM_STATUS_CODE_FAILURE:
      return {
        ...state,
        activeOfferItemStatusCode: null,
        isFetchingOfferItemStatusCodes: false,
        fetchingOfferItemStatusCodesFailed: true,
      };
    case POST_OFFER_ITEM_STATUS_CODE_REQUEST:
      return {
        ...state,
        isPostingOfferItemStatusCode: true,
        postingOfferItemStatusCodeError: null,
        postingOfferItemStatusCodeValidationErrors: null,
        postingOfferItemStatusCodeFailed: false,
      };
    case POST_OFFER_ITEM_STATUS_CODE_RESPONSE:
      return {
        ...state,
        isPostingOfferItemStatusCode: false,
        postingOfferItemStatusCodeError: null,
        postingOfferItemStatusCodeValidationErrors: null,
        postingOfferItemStatusCodeFailed: false,
      };
    case POST_OFFER_ITEM_STATUS_CODE_FAILURE:
      return {
        ...state,
        isPostingOfferItemStatusCode: false,
        postingOfferItemStatusCodeError: action.payload.error || 'An error occurred',
        postingOfferItemStatusCodeValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingOfferItemStatusCodeFailed: true,
      };
    default:
      return state;
  }
};

export default offerItemStatusCodesReducer;
