import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_RESPONSE,
  FETCH_CONTACTS_FAILURE,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_RESPONSE,
  FETCH_CONTACT_FAILURE,
  POST_CONTACT_FAILURE,
  POST_CONTACT_REQUEST,
  POST_CONTACT_RESPONSE,
  ContactActionTypes, ContactDataModel, Contact,
} from '../types/Contact';

import { RootState } from '../store/configureStore';

import { getContact, getContacts, postContact, putContact, archiveContact } from '../api/Contact';
import { DataTableParamsModel } from '../types/Common';

export function fetchContactsRequest(): ContactActionTypes {
  return {
    type: FETCH_CONTACTS_REQUEST,
    payload: null,
  };
}

export function fetchContactsResponse(
  contacts: ContactDataModel,
): ContactActionTypes {
  return {
    type: FETCH_CONTACTS_RESPONSE,
    payload: contacts,
  };
}

export function fetchContactsFailure(): ContactActionTypes {
  return {
    type: FETCH_CONTACTS_FAILURE,
    payload: null,
  };
}

export function fetchContactRequest(): ContactActionTypes {
  return {
    type: FETCH_CONTACT_REQUEST,
    payload: null,
  };
}

export function fetchContactResponse(
  contact: Contact,
): ContactActionTypes {
  return {
    type: FETCH_CONTACT_RESPONSE,
    payload: contact,
  };
}

export function fetchContactFailure(): ContactActionTypes {
  return {
    type: FETCH_CONTACT_FAILURE,
    payload: null,
  };
}

export const fetchActiveContact = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchContactRequest());
    const asyncResp: any = await getContact(id);
    if (asyncResp?.success) {
      await dispatch(fetchContactResponse(asyncResp.data));
    } else {
      await dispatch(fetchContactFailure());
    }
  };

export function postContactRequest(): ContactActionTypes {
  return {
    type: POST_CONTACT_REQUEST,
    payload: null,
  };
}

export function postContactResponse(): ContactActionTypes {
  return {
    type: POST_CONTACT_RESPONSE,
    payload: null,
  };
}

export function postContactFailure(error: string, validationErrors: any): ContactActionTypes {
  return {
    type: POST_CONTACT_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchContacts = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchContactsRequest());
    const asyncResp: any = await getContacts(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchContactsResponse(asyncResp));
    } else {
      await dispatch(fetchContactsFailure());
    }
  };


export const createContact = (
  firstName: string, lastName: string, email: string, phoneNumber: string, companyName: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postContactRequest());
    const asyncResp: any = await postContact(firstName, lastName, email, phoneNumber, companyName);
    if (asyncResp?.success) {
      await dispatch(postContactResponse());
    } else {
      await dispatch(postContactFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateContact = (
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  companyName: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postContactRequest());
    const asyncResp: any = await putContact(id, firstName, lastName, email, phoneNumber, companyName);
    if (asyncResp?.success) {
      await dispatch(postContactResponse());
    } else {
      await dispatch(postContactFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const storeContact = async (id: string) => {
  try {
    await archiveContact(id);
  } catch (error) {
    throw error;
  }
};
