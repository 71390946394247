import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createOfferStatusCode, fetchActiveOfferStatusCode, updateOfferStatusCode } from '../../../actions/OfferStatusCode';
import {
  getActiveOfferStatusCode,
  isPostingOfferStatusCode,
  postingOfferStatusCodeError,
  postingOfferStatusCodeFailed,
  postingOfferStatusCodeValidationErrors,
  isFetchingOfferStatusCodes,
} from '../../../selectors/OfferStatusCode';
import { fetchOffers } from '../../../actions/Offer';
import { getOffers } from '../../../selectors/Offer';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { Alert } from 'react-bootstrap';
import TextArea from '../../../components/TextArea';
import { fetchStatusCodes } from '../../../actions/StatusCode';
import { getStatusCodes } from '../../../selectors/StatusCode';

const OfferStatusCodeForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeOfferStatusCode = useSelector(getActiveOfferStatusCode);
  const isPosting = useSelector(isPostingOfferStatusCode);
  const isFetching = useSelector(isFetchingOfferStatusCodes);
  const postingFailed = useSelector(postingOfferStatusCodeFailed);
  const postingError = useSelector(postingOfferStatusCodeError);
  const postingValidationErrors = useSelector(postingOfferStatusCodeValidationErrors);

  const offers = useSelector(getOffers);
  const statusCodes = useSelector(getStatusCodes);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.OFFER_STATUS_CODE_UPDATE);

  const [offerId, setOfferId] = useState('');
  const [offerCodeId, setOfferCodeId] = useState('');
  const [notes, setNotes] = useState('');

  const formConfig = [
    { field: 'offerId', validators: [isRequired] },
    { field: 'offerCodeId', validators: [isRequired] },
    { field: 'notes', validators: [] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    dispatch(fetchOffers());
    dispatch(fetchStatusCodes());
    if (editMode) {
      dispatch(fetchActiveOfferStatusCode(activeId));
    }
  }, [dispatch, editMode, activeId]);

  useEffect(() => {
    if (activeOfferStatusCode && editMode) {
      setOfferId(activeOfferStatusCode.offerId);
      setOfferCodeId(activeOfferStatusCode.offerCodeId);
      if (activeOfferStatusCode.notes){
        setNotes(activeOfferStatusCode.notes);
      }
    }
  }, [activeOfferStatusCode, editMode]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.OFFER_STATUS_CODE);
    }
  }, [isPosting, postingFailed, hasSubmitted, history]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createOfferStatusCode(offerId, offerCodeId, notes));
    }
  };

  const onSave = async () => {
    setHasSubmitted(true);
    dispatch(updateOfferStatusCode(activeId, offerId, offerCodeId, notes));
  };

  const itemsOffers = offers?.data.map(r => ({ label: r.subject, value: r.id })) || [];
  const itemsStatusCodes = statusCodes?.data.map(r => ({ label: r.value, value: r.id })) || [];

  const onOfferSelect = (obj: any) => {
    setOfferId(obj.value);
  };

  const onStatusCodeSelect = (obj: any) => {
    setOfferCodeId(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Offer StatusCode</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown
                    v={v}
                    err={err}
                    name="offerId"
                    id="offerId"
                    label="Offer"
                    required
                    items={itemsOffers}
                    value={offerId}
                    onSelect={onOfferSelect}
                    disabled={isPosting || isFetching}
                />
                <DropDown
                    v={v}
                    err={err}
                    name="offerCodeId"
                    id="offerCodeId"
                    label="Status Code"
                    required
                    items={itemsStatusCodes}
                    value={offerCodeId}
                    onSelect={onStatusCodeSelect}
                    disabled={isPosting || isFetching}
                />
                <TextArea
                    v={v}
                    err={err}
                    name="notes"
                    id="notes"
                    label="Notes"
                    value={notes}
                    onChange={setNotes}
                    disabled={isPosting || isFetching}
                    onBlur={setNotes}
                    placeholder="Insert notes"
                />
                <div className="row">
                    <div className="col-md-4">
                        <Button
                            disabled={!isFormValid()}
                            isLoading={isPosting || isFetching}
                            onClick={() => (editMode ? onSave() : onSubmit())}
                            title={editMode ? 'SAVE' : 'CREATE'}
                        />
                    </div>
                </div>
            </form>
        </View>
  );
};

export default OfferStatusCodeForm;
