import React from 'react';
import './Loading.scss';

type Props = {
  text?: string;
};

const  Loading = (props: Props) =>{
  const { text } = props;
  return <div className="loading">
        <div className="row">
            <div className="spinner-border text-dark" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <span className="sr-only">{text ? text : 'Loading, please wait.'}</span>
        </div>
    </div>;
};

export default Loading;
