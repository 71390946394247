import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createEntryEdge } from '../../../actions/EntryEdge';
import { getEntries } from '../../../selectors/Entry';
import { getGroups } from '../../../selectors/Group';
import { fetchClients } from '../../../actions/Client';
import { fetchGroups } from '../../../actions/Group';
import { fetchEntries } from '../../../actions/Entry';

import {
  isPostingEntryEdge,
  postingEntryEdgeError,
  postingEntryEdgeFailed,
  postingEntryEdgeValidationErrors,
  isFetchingEntryEdges,
} from '../../../selectors/EntryEdge';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import CheckBox from '../../../components/CheckBox';
import { getClients } from '../../../selectors/Client';


const EntryEdgeForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const entries = useSelector(getEntries);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const isPosting = useSelector(isPostingEntryEdge);
  const isFetching = useSelector(isFetchingEntryEdges);
  const postingFailed = useSelector(postingEntryEdgeFailed);
  const postingError = useSelector(postingEntryEdgeError);
  const postingValidationErrors = useSelector(postingEntryEdgeValidationErrors);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'fromEntryId', validators: [isRequired] },
    { field: 'toEntryId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [fromEntryId, setFromEntryId] = useState('');
  const [toEntryId, setToEntryId] = useState('');
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchEntries());

  }, [dispatch]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ENTRY_EDGE);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createEntryEdge(groupId, clientId, fromEntryId, toEntryId, active ));
    }
  };

  const itemsEntries = entries?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  const onFromEntrySelect = (obj: any) => {
    setFromEntryId(obj.value);
  };

  const onToEntrySelect = (obj: any) => {
    setToEntryId(obj.value);
  };

  return <View>
    <h4>Create EntryEdge</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
    )}
    <form>
      <DropDown v={v} err={err}
                id="groupId"
                placeholder="Please select a Group"
                required label="Group"
                items={itemsGroups}
                value={groupId}
                disabled={isPosting || isFetching}
                onSelect={onGroupSelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="clientId"
                placeholder="Please select a Client"
                required
                label="Client"
                items={itemsClients}
                disabled={isPosting || isFetching || disabledClient}
                value={clientId}
                onSelect={onClientSelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="fromEntryId"
                placeholder="Please select a starting Entry"
                required
                label="From Entry"
                items={itemsEntries}
                disabled={isPosting || isFetching}
                value={fromEntryId}
                onSelect={onFromEntrySelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="toEntryId"
                placeholder="Please select an Entry to connect to"
                required
                label="To Entry"
                items={itemsEntries}
                disabled={isPosting || isFetching}
                value={toEntryId}
                onSelect={onToEntrySelect}
                type="default"
      />
      <CheckBox label="Is Active?" checked={active} onChange={setActive}/>

      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                  onClick={() => onSubmit()}
                  title={'CREATE'}/>
        </div>
      </div>
    </form>
  </View>;
};

export default EntryEdgeForm;
