// EntryCompositionForm.tsx

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createEntryComposition, fetchActiveEntryComposition, updateEntryComposition } from '../../../actions/EntryComposition';
import {
  getActiveEntryComposition,
  isPostingEntryComposition,
  postingEntryCompositionError,
  postingEntryCompositionFailed,
  postingEntryCompositionValidationErrors,
  isFetchingEntryCompositions,
} from '../../../selectors/EntryComposition';
import { fetchEntries } from '../../../actions/Entry';
import { fetchCompositions } from '../../../actions/Composition';
import { getEntries } from '../../../selectors/Entry';
import { getCompositions } from '../../../selectors/Composition';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { isRequired } from '../../../helpers/validators';
import { Alert } from 'react-bootstrap';

const EntryCompositionForm: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeEntryComposition = useSelector(getActiveEntryComposition);
  const isPosting = useSelector(isPostingEntryComposition);
  const isFetching = useSelector(isFetchingEntryCompositions);
  const postingFailed = useSelector(postingEntryCompositionFailed);
  const postingError = useSelector(postingEntryCompositionError);
  const postingValidationErrors = useSelector(postingEntryCompositionValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.ENTRY_COMPOSITION_UPDATE);

  const formConfig = [
    { field: 'entryId', validators: [isRequired] },
    { field: 'compositionId', validators: [isRequired] },
    { field: 'quantity', validators: [isRequired] },
    { field: 'unitOfMeasure', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [entryId, setEntryId] = useState('');
  const [compositionId, setCompositionId] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unitOfMeasure, setUnitOfMeasure] = useState('');

  const entries = useSelector(getEntries);
  const compositions = useSelector(getCompositions);

  useEffect(() => {
    dispatch(fetchEntries());
    dispatch(fetchCompositions());
    if (editMode) {
      dispatch(fetchActiveEntryComposition(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeEntryComposition && editMode) {
      setEntryId(activeEntryComposition.entryId);
      setCompositionId(activeEntryComposition.compositionId);
      setQuantity(activeEntryComposition.quantity.toString());
      setUnitOfMeasure(activeEntryComposition.unitOfMeasure);
    }
  }, [activeEntryComposition]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ENTRY_COMPOSITION);
    }
  }, [isPosting, postingFailed, hasSubmitted, history]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createEntryComposition(entryId, compositionId, parseFloat(quantity), unitOfMeasure));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateEntryComposition(activeId, entryId, compositionId, parseFloat(quantity), unitOfMeasure));
    }
  };

  const itemsEntries = entries?.data.map((entry: any) => ({ label: entry.title, value: entry.id })) || [];
  const itemsCompositions = compositions?.data.map((composition: any) => ({ label: composition.material, value: composition.id })) || [];

  const onEntrySelect = (obj: any) => {
    setEntryId(obj.value);
  };

  const onCompositionSelect = (obj: any) => {
    setCompositionId(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Entry Composition</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown
                    v={v}
                    err={err}
                    id="entryId"
                    label="Entry"
                    placeholder="Select an Entry"
                    items={itemsEntries}
                    value={entryId}
                    onSelect={onEntrySelect}
                    disabled={isPosting || isFetching}
                />
                <DropDown
                    v={v}
                    err={err}
                    id="compositionId"
                    label="Composition"
                    placeholder="Select a Composition"
                    items={itemsCompositions}
                    value={compositionId}
                    onSelect={onCompositionSelect}
                    disabled={isPosting || isFetching}
                />
                <Input
                    v={v}
                    err={err}
                    name="quantity"
                    id="quantity"
                    label="Quantity"
                    required
                    value={quantity}
                    onChange={setQuantity}
                    disabled={isPosting || isFetching}
                    onBlur={setQuantity}
                    placeholder="Insert quantity"
                />
                <Input
                    v={v}
                    err={err}
                    name="unitOfMeasure"
                    id="unitOfMeasure"
                    label="Unit of Measure"
                    required
                    value={unitOfMeasure}
                    onChange={setUnitOfMeasure}
                    disabled={isPosting || isFetching}
                    onBlur={setUnitOfMeasure}
                    placeholder="Insert unit of measure"
                />
                <div className="row">
                    <div className="col-md-4">
                        <Button
                            disabled={!isFormValid()}
                            isLoading={isPosting || isFetching}
                            onClick={editMode ? onSave : onSubmit}
                            title={editMode ? 'SAVE' : 'CREATE'}
                        />
                    </div>
                </div>
            </form>
        </View>
  );
};

export default EntryCompositionForm;
