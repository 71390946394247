import {
  FETCH_PLAN_FAILURE,
  FETCH_PLAN_REQUEST,
  FETCH_PLAN_RESPONSE,
  FETCH_PLANS_FAILURE,
  FETCH_PLANS_REQUEST,
  FETCH_PLANS_RESPONSE,
  POST_PLAN_FAILURE,
  POST_PLAN_RESPONSE,
  PlanActionTypes,
  PlanState,
  POST_PLAN_REQUEST,
} from '../types/Plan';
import { parseValidationErrors } from '../helpers/functions';

const initialState: PlanState = {
  plans: null,
  activePlan: null,
  isFetchingPlans: false,
  fetchingPlansFailed: false,
  isPostingPlan: false,
  postingPlanFailed: false,
  postingPlanError: null,
  postingPlanValidationErrors: null,
};

const plansReducer = (
  state = initialState,
  action: PlanActionTypes,
): PlanState => {
  switch (action.type) {
    case FETCH_PLANS_REQUEST:
      return {
        ...state,
        plans: null,
        isFetchingPlans: true,
        fetchingPlansFailed: false,
      };
    case FETCH_PLANS_RESPONSE:
      return {
        ...state,
        plans: action.payload || [],
        isFetchingPlans: false,
        fetchingPlansFailed: false,
      };
    case FETCH_PLANS_FAILURE:
      return {
        ...state,
        plans: null,
        isFetchingPlans: false,
        fetchingPlansFailed: true,
      };
    case FETCH_PLAN_REQUEST:
      return {
        ...state,
        activePlan: null,
        isFetchingPlans: true,
        fetchingPlansFailed: false,
      };
    case FETCH_PLAN_RESPONSE:
      return {
        ...state,
        activePlan: action.payload,
        isFetchingPlans: false,
        fetchingPlansFailed: false,
      };
    case FETCH_PLAN_FAILURE:
      return {
        ...state,
        activePlan: null,
        isFetchingPlans: false,
        fetchingPlansFailed: true,
      };
    case POST_PLAN_REQUEST:
      return {
        ...state,
        isPostingPlan: true,
        postingPlanError: null,
        postingPlanValidationErrors: null,
        postingPlanFailed: false,
      };
    case POST_PLAN_RESPONSE:
      return {
        ...state,
        isPostingPlan: false,
        postingPlanError: null,
        postingPlanValidationErrors: null,
        postingPlanFailed: false,
      };
    case POST_PLAN_FAILURE:
      return {
        ...state,
        isPostingPlan: false,
        postingPlanError: action.payload.error || 'An error occurred',
        postingPlanValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingPlanFailed: true,
      };
    default:
      return state;
  }
};

export default plansReducer;
