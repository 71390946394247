import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_OFFER_STATUS_CODES_REQUEST,
  FETCH_OFFER_STATUS_CODES_RESPONSE,
  FETCH_OFFER_STATUS_CODES_FAILURE,
  FETCH_OFFER_STATUS_CODE_REQUEST,
  FETCH_OFFER_STATUS_CODE_RESPONSE,
  FETCH_OFFER_STATUS_CODE_FAILURE,
  POST_OFFER_STATUS_CODE_FAILURE,
  POST_OFFER_STATUS_CODE_REQUEST,
  POST_OFFER_STATUS_CODE_RESPONSE,
  OfferStatusCodeActionTypes, OfferStatusCodeDataModel, OfferStatusCode,
} from '../types/OfferStatusCode';

import { RootState } from '../store/configureStore';

import { getOfferStatusCode, getOfferStatusCodes, postOfferStatusCode, putOfferStatusCode, archiveOfferStatusCode } from '../api/OfferStatusCode';
import { DataTableParamsModel } from '../types/Common';

export function fetchOfferStatusCodesRequest(): OfferStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_STATUS_CODES_REQUEST,
    payload: null,
  };
}

export function fetchOfferStatusCodesResponse(
  offerStatusCodes: OfferStatusCodeDataModel,
): OfferStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_STATUS_CODES_RESPONSE,
    payload: offerStatusCodes,
  };
}

export function fetchOfferStatusCodesFailure(): OfferStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_STATUS_CODES_FAILURE,
    payload: null,
  };
}

export function fetchOfferStatusCodeRequest(): OfferStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_STATUS_CODE_REQUEST,
    payload: null,
  };
}

export function fetchOfferStatusCodeResponse(
  offerStatusCode: OfferStatusCode,
): OfferStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_STATUS_CODE_RESPONSE,
    payload: offerStatusCode,
  };
}

export function fetchOfferStatusCodeFailure(): OfferStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_STATUS_CODE_FAILURE,
    payload: null,
  };
}

export const fetchActiveOfferStatusCode = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOfferStatusCodeRequest());
    const asyncResp: any = await getOfferStatusCode(id);
    if (asyncResp?.success) {
      await dispatch(fetchOfferStatusCodeResponse(asyncResp.data));
    } else {
      await dispatch(fetchOfferStatusCodeFailure());
    }
  };

export function postOfferStatusCodeRequest(): OfferStatusCodeActionTypes {
  return {
    type: POST_OFFER_STATUS_CODE_REQUEST,
    payload: null,
  };
}

export function postOfferStatusCodeResponse(): OfferStatusCodeActionTypes {
  return {
    type: POST_OFFER_STATUS_CODE_RESPONSE,
    payload: null,
  };
}

export function postOfferStatusCodeFailure(error: string, validationErrors: any): OfferStatusCodeActionTypes {
  return {
    type: POST_OFFER_STATUS_CODE_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchOfferStatusCodes = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOfferStatusCodesRequest());
    const asyncResp: any = await getOfferStatusCodes(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchOfferStatusCodesResponse(asyncResp));
    } else {
      await dispatch(fetchOfferStatusCodesFailure());
    }
  };

export const createOfferStatusCode = (
  offerId: string, offerCodeId: string, notes: string | null,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOfferStatusCodeRequest());
    const asyncResp: any = await postOfferStatusCode(offerId, offerCodeId, notes);
    if (asyncResp?.success) {
      await dispatch(postOfferStatusCodeResponse());
    } else {
      await dispatch(postOfferStatusCodeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateOfferStatusCode = (
  id: string,
  offerId: string, offerCodeId: string, notes: string | null,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOfferStatusCodeRequest());
    const asyncResp: any = await putOfferStatusCode(id, offerId, offerCodeId, notes);
    if (asyncResp?.success) {
      await dispatch(postOfferStatusCodeResponse());
    } else {
      await dispatch(postOfferStatusCodeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const storeOfferStatusCode = async (id: string) => {
  try {
    await archiveOfferStatusCode(id);
  } catch (error) {
    throw error;
  }
};
