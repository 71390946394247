import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_COMPOSITIONS_REQUEST,
  FETCH_COMPOSITIONS_RESPONSE,
  FETCH_COMPOSITIONS_FAILURE,
  FETCH_COMPOSITION_REQUEST,
  FETCH_COMPOSITION_RESPONSE,
  FETCH_COMPOSITION_FAILURE,
  POST_COMPOSITION_FAILURE,
  POST_COMPOSITION_REQUEST,
  POST_COMPOSITION_RESPONSE,
  CompositionActionTypes, CompositionDataModel, Composition,
} from '../types/Composition';

import { RootState } from '../store/configureStore';

import { getComposition, getCompositions, postComposition, putComposition, archiveComposition } from '../api/Composition';
import { DataTableParamsModel } from '../types/Common';

export function fetchCompositionsRequest(): CompositionActionTypes {
  return {
    type: FETCH_COMPOSITIONS_REQUEST,
    payload: null,
  };
}

export function fetchCompositionsResponse(
  compositions: CompositionDataModel,
): CompositionActionTypes {
  return {
    type: FETCH_COMPOSITIONS_RESPONSE,
    payload: compositions,
  };
}

export function fetchCompositionsFailure(): CompositionActionTypes {
  return {
    type: FETCH_COMPOSITIONS_FAILURE,
    payload: null,
  };
}

export function fetchCompositionRequest(): CompositionActionTypes {
  return {
    type: FETCH_COMPOSITION_REQUEST,
    payload: null,
  };
}

export function fetchCompositionResponse(
  composition: Composition,
): CompositionActionTypes {
  return {
    type: FETCH_COMPOSITION_RESPONSE,
    payload: composition,
  };
}

export function fetchCompositionFailure(): CompositionActionTypes {
  return {
    type: FETCH_COMPOSITION_FAILURE,
    payload: null,
  };
}

export const fetchActiveComposition = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCompositionRequest());
    const asyncResp: any = await getComposition(id);
    if (asyncResp?.success) {
      await dispatch(fetchCompositionResponse(asyncResp.data));
    } else {
      await dispatch(fetchCompositionFailure());
    }
  };

export function postCompositionRequest(): CompositionActionTypes {
  return {
    type: POST_COMPOSITION_REQUEST,
    payload: null,
  };
}

export function postCompositionResponse(): CompositionActionTypes {
  return {
    type: POST_COMPOSITION_RESPONSE,
    payload: null,
  };
}

export function postCompositionFailure(error: string, validationErrors: any): CompositionActionTypes {
  return {
    type: POST_COMPOSITION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchCompositions = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCompositionsRequest());
    const asyncResp: any = await getCompositions(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchCompositionsResponse(asyncResp));
    } else {
      await dispatch(fetchCompositionsFailure());
    }
  };

export const createComposition = (
  material: string, variation: string, CO2: number, unitOfMeasure: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postCompositionRequest());
    const asyncResp: any = await postComposition(material, variation, CO2, unitOfMeasure);
    if (asyncResp?.success) {
      await dispatch(postCompositionResponse());
    } else {
      await dispatch(postCompositionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateComposition = (
  id: string,
  material: string, variation: string, CO2: number, unitOfMeasure: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postCompositionRequest());
    const asyncResp: any = await putComposition(id, material, variation, CO2, unitOfMeasure);
    if (asyncResp?.success) {
      await dispatch(postCompositionResponse());
    } else {
      await dispatch(postCompositionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const storeComposition = async (id: string) => {
  try {
    await archiveComposition(id);
  } catch (error) {
    throw error;
  }
};
