import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_RESPONSE,
  FETCH_REPORTS_FAILURE,
  ReportActionTypes, ReportItemDataModel,
} from '../types/GreenReport';

import { RootState } from '../store/configureStore';

import { getReports } from '../api/GreenReport';

export function fetchReportsRequest(): ReportActionTypes {
  return {
    type: FETCH_REPORTS_REQUEST,
    payload: null,
  };
}

export function fetchReportsResponse(
  reports: ReportItemDataModel,
): ReportActionTypes {
  return {
    type: FETCH_REPORTS_RESPONSE,
    payload: reports,
  };
}

export function fetchReportsFailure(): ReportActionTypes {
  return {
    type: FETCH_REPORTS_FAILURE,
    payload: null,
  };
}

export const fetchReports = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchReportsRequest());
    const asyncResp: any = await getReports(id);
    if (asyncResp?.success) {
      await dispatch(fetchReportsResponse(asyncResp));
    } else {
      await dispatch(fetchReportsFailure());
    }
  };