import React from 'react';
import { PieChart } from 'recharts';

type Props = {
  children?: React.ReactNode;
  width: number;
  height: number;
};

const Chart = (props: Props) => {
  return (
        <PieChart width={props.width} height={props.height} >
            {props.children}
        </PieChart>
  );
};

export default Chart;