import { RootState } from '../store/configureStore';

export const getPlans = (state: RootState) => state?.plan?.plans;

export const getActivePlan = (state: RootState) => state?.plan?.activePlan;

export const isFetchingPlans = (state: RootState) => state?.plan?.isFetchingPlans;

export const isPostingPlan = (state: RootState) => state?.plan?.isPostingPlan;

export const postingPlanFailed = (state: RootState) => state?.plan?.postingPlanFailed;

export const postingPlanError = (state: RootState) => state?.plan?.postingPlanError;

export const postingPlanValidationErrors = (state: RootState) => state?.plan?.postingPlanValidationErrors;

export const fetchingPlansFailed = (state: RootState) => state?.plan?.fetchingPlansFailed;
