import { RootState } from '../store/configureStore';

export const getStatusCodes = (state: RootState) => state?.statusCode?.statusCodes;

export const getActiveStatusCode = (state: RootState) => state?.statusCode?.activeStatusCode;

export const isFetchingStatusCodes = (state: RootState) => state?.statusCode?.isFetchingStatusCodes;

export const isPostingStatusCode = (state: RootState) => state?.statusCode?.isPostingStatusCode;

export const postingStatusCodeFailed = (state: RootState) => state?.statusCode?.postingStatusCodeFailed;

export const postingStatusCodeError = (state: RootState) => state?.statusCode?.postingStatusCodeError;

export const postingStatusCodeValidationErrors = (state: RootState) => state?.statusCode?.postingStatusCodeValidationErrors;

export const fetchingStatusCodesFailed = (state: RootState) => state?.statusCode?.fetchingStatusCodesFailed;