import {
  FETCH_OFFER_FAILURE,
  FETCH_OFFER_REQUEST,
  FETCH_OFFER_RESPONSE,
  FETCH_OFFERS_FAILURE,
  FETCH_OFFERS_REQUEST,
  FETCH_OFFERS_RESPONSE,
  POST_OFFER_FAILURE,
  POST_OFFER_RESPONSE,
  OfferActionTypes,
  OfferState,
  POST_OFFER_REQUEST,
} from '../types/Offer';
import { parseValidationErrors } from '../helpers/functions';

const initialState: OfferState = {
  offers: null,
  activeOffer: null,
  isFetchingOffers: false,
  fetchingOffersFailed: false,
  isPostingOffer: false,
  postingOfferFailed: false,
  postingOfferError: null,
  postingOfferValidationErrors: null,
};

const offersReducer = (
  state = initialState,
  action: OfferActionTypes,
): OfferState => {
  switch (action.type) {
    case FETCH_OFFERS_REQUEST:
      return {
        ...state,
        offers: null,
        isFetchingOffers: true,
        fetchingOffersFailed: false,
      };
    case FETCH_OFFERS_RESPONSE:
      return {
        ...state,
        offers: action.payload || [],
        isFetchingOffers: false,
        fetchingOffersFailed: false,
      };
    case FETCH_OFFERS_FAILURE:
      return {
        ...state,
        offers: null,
        isFetchingOffers: false,
        fetchingOffersFailed: true,
      };
    case FETCH_OFFER_REQUEST:
      return {
        ...state,
        offers: null,
        isFetchingOffers: true,
        fetchingOffersFailed: false,
      };
    case FETCH_OFFER_RESPONSE:
      return {
        ...state,
        activeOffer: action.payload,
        isFetchingOffers: false,
        fetchingOffersFailed: false,
      };
    case FETCH_OFFER_FAILURE:
      return {
        ...state,
        offers: null,
        isFetchingOffers: false,
        fetchingOffersFailed: true,
      };
    case POST_OFFER_REQUEST:
      return {
        ...state,
        isPostingOffer: true,
        postingOfferError: null,
        postingOfferValidationErrors: null,
        postingOfferFailed: false,
      };
    case POST_OFFER_RESPONSE:
      return {
        ...state,
        isPostingOffer: false,
        postingOfferError: null,
        postingOfferValidationErrors: null,
        postingOfferFailed: false,
      };
    case POST_OFFER_FAILURE:
      return {
        ...state,
        isPostingOffer: false,
        postingOfferError: action.payload.error || 'An error occurred',
        postingOfferValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingOfferFailed: true,
      };
    default:
      return state;
  }
};

export default offersReducer;
