import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getTransactions, isFetchingTransactions } from '../../../selectors/Transaction';
import { fetchTransactions, storeTransaction } from '../../../actions/Transaction';
import { Transaction } from '../../../types/Transaction';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const TransactionDataTable = () => {

  const transactions = useSelector(getTransactions);
  const isLoading = useSelector(isFetchingTransactions);
  const [toggle, setToggle] = useState(false);
  const [transaction, setActiveTransaction] = useState<Transaction | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.TRANSACTION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.TRANSACTION_UPDATE, row.id));
  };

  const onArchive = (row: Transaction) => {
    setActiveTransaction(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (transaction?.id) {
      await storeTransaction(transaction?.id);
      setRefetch(!refetch);
    }
    setActiveTransaction(null);
  };

  const config = {
    columns: [
      { key: 'oppTransactionId', label: 'OPP Transaction ID', mobileFriendly: true },
      { key: 'status', label: 'Status', mobileFriendly: true },
      { key: 'merchant_uid', label: 'Merchant ID', mobileFriendly: false },
      { key: 'profile_uid', label: 'Profile ID', mobileFriendly: true },
      { key: 'amount', label: 'Amount', mobileFriendly: true },
      { key: 'currency', label: 'Currency', mobileFriendly: false },
      { key: 'payment_method', label: 'Payment Method', mobileFriendly: true },
      { key: 'payment_flow', label: 'Payment Flow', mobileFriendly: true },
      { key: 'redirect_url', label: 'Redirect URL', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
    ],
    hideButton: true,
  };

  return (
        <View title="Transactions" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchTransactions} data={transactions?.data || []}
                       isLoading={isLoading} paging={transactions?.paging || []} config={config} dependencies={refetch} />
            <Modal show={toggle} title="Archive Transaction" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
                Are you sure you want to delete this transaction?
            </Modal>
        </View>
  );
};

export default TransactionDataTable;
