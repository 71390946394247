import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getLeads, isFetchingLeads, isPostingLead, postingLeadError } from '../../../selectors/Lead';
import { convertLead, fetchLeads, storeLead } from '../../../actions/Lead';
import { Lead } from '../../../types/Lead';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';
import { getClients, isFetchingClients } from '../../../selectors/Client';
import { fetchClients } from '../../../actions/Client';
import DropDown from '../../../components/DropDown';
import { fetchGroups } from '../../../actions/Group';
import { getGroups } from '../../../selectors/Group';
import { Alert } from 'react-bootstrap';

const LeadDataTable = () => {
  const leads = useSelector(getLeads);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const isLoading = useSelector(isFetchingLeads);
  const isPosting = useSelector(isPostingLead);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [toggleConvert, setToggleConvert] = useState(false);
  const [lead, setActiveLead] = useState<Lead | null>();
  const [clientId, setClientId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const isFetching = useSelector(isFetchingClients);
  const [disabledClient, setDisabledClients] = useState(true);
  const [showConverted, setShowConverted] = useState(false);
  const postingLeadErrors = useSelector(postingLeadError);
  const [refetch, setRefetch] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const onConverted = () => {
    setShowConverted(!showConverted);
  };

  const history = useHistory();

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
  }, [dispatch]);

  const onDismiss = () => {
    setToggle(false);
    setToggleConvert(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.LEAD_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.LEAD_UPDATE, row.id));
  };

  const onArchive = (row: Lead) => {
    setActiveLead(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (lead?.id) {
      await storeLead(lead?.id);
      setRefetch(!refetch);
    }
    setActiveLead(null);
  };

  const onConversion = (row: Lead) => {
    setActiveLead(row);
    setToggleConvert(true);
  };

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onConfirmConversion = () => {
    setToggleConvert(false);
    if (lead?.id) {
      dispatch(convertLead(lead?.id, groupId, clientId));
      setRefetch(!refetch);
      setSuccessMessage('Conversion successful');
    }
    setActiveLead(null);
  };

  const actions = [
    { icon: 'edit', label: '', onClick: onUpdate },
    { icon: 'bin', label: '', onClick: onArchive },
  ];

  if (!showConverted) {
    actions.push({ icon: 'convert', label: '', onClick: onConversion });
  }

  const config = {
    columns: [
      { key: 'firstName', label: 'First Name', mobileFriendly: false },
      { key: 'lastName', label: 'Last Name', mobileFriendly: true },
      { key: 'companyName', label: 'Company Name', mobileFriendly: true },
      { key: 'email', label: 'Email', mobileFriendly: false },
      { key: 'phoneNumber', label: 'Phone Number', mobileFriendly: false },
      { key: 'verification', label: 'Verification', mobileFriendly: true },
      { key: 'transaction', label: 'Payment', mobileFriendly: true }, //changed to payment in frontend due to client confusion
      { key: 'agreement', label: 'Agreement', mobileFriendly: true },
      { key: 'onboarded', label: 'Onboarded', mobileFriendly: true },
    ],
    actions,
    hideButton: true,
    hasExtraAction: true,
  };

  const onSelectDropdownValue = (obj: any, setStateFunction: Function) => {
    setStateFunction(obj.value);
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];

  return (
      <View title="Leads" onAddNew={onCreate}>
        {postingLeadErrors && (
            <Alert className="mt-3" variant="danger">
              {postingLeadErrors}
            </Alert>
        )}
        {(!postingLeadErrors && !isPosting && successMessage) && (
            <Alert className="mt-3" variant="success">
              {successMessage}
            </Alert>
        )}
        <DataTable
            fetchFunction={fetchLeads}
            dependencies={[showConverted, refetch]}
            baseSearch={showConverted ? { 'converted': 'notNull' } : { 'converted': 'null' }}
            data={leads?.data || []}
            isLoading={isLoading}
            paging={leads?.paging || []}
            config={config}
            extraActionTitle={!showConverted ? 'Show Converted' : 'Show Unconverted'}
            extraFunc={onConverted}
        />
        <Modal show={toggle} title="Archive Lead" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
          Are you sure you want to delete this lead?
        </Modal>
        <Modal show={toggleConvert} title="Convert Lead" cta="Convert" onCta={onConfirmConversion} buttonDisabled={!clientId} onHide={onDismiss}>
          <DropDown
              id="groupId"
              placeholder="Please select a group"
              label="Group"
              items={itemsGroups}
              value={groupId}
              disabled={isFetching}
              onSelect={onGroupSelect}
              type="default"
          />
          <DropDown
              id="clientId"
              placeholder="Please select an existing client template to initialize the new client"
              label="Client Template"
              items={itemsClients}
              value={clientId}
              disabled={isFetching || disabledClient}
              onSelect={(obj: any) => onSelectDropdownValue(obj, setClientId)}
              type="default"
          />
        </Modal>
      </View>
  );
};

export default LeadDataTable;
