import { ApiDataModel, FormError } from './Common';

export const FETCH_OFFER_ITEM_STATUS_CODES_REQUEST = 'FETCH_OFFER_ITEM_STATUS_CODES_REQUEST';
export const FETCH_OFFER_ITEM_STATUS_CODES_RESPONSE = 'FETCH_OFFER_ITEM_STATUS_CODES_RESPONSE';
export const FETCH_OFFER_ITEM_STATUS_CODES_FAILURE = 'FETCH_OFFER_ITEM_STATUS_CODES_FAILURE';

export const FETCH_OFFER_ITEM_STATUS_CODE_REQUEST = 'FETCH_OFFER_ITEM_STATUS_CODE_REQUEST';
export const FETCH_OFFER_ITEM_STATUS_CODE_RESPONSE = 'FETCH_OFFER_ITEM_STATUS_CODE_RESPONSE';
export const FETCH_OFFER_ITEM_STATUS_CODE_FAILURE = 'FETCH_OFFER_ITEM_STATUS_CODE_FAILURE';

export const POST_OFFER_ITEM_STATUS_CODE_REQUEST = 'POST_OFFER_ITEM_STATUS_CODE_REQUEST';
export const POST_OFFER_ITEM_STATUS_CODE_RESPONSE = 'POST_OFFER_ITEM_STATUS_CODE_RESPONSE';
export const POST_OFFER_ITEM_STATUS_CODE_FAILURE = 'POST_OFFER_ITEM_STATUS_CODE_FAILURE';

export interface OfferItemStatusCode {
  id: string;
  offerItemId: string;
  status: string;
  notes: string | null;
}

export interface OfferItemStatusCodeDataModel extends Omit<ApiDataModel, 'data'> {
  data: OfferItemStatusCode[];
}

export interface OfferItemStatusCodeState {
  offerItemStatusCodes: OfferItemStatusCodeDataModel | null,
  activeOfferItemStatusCode: OfferItemStatusCode | null,
  isFetchingOfferItemStatusCodes: boolean;
  fetchingOfferItemStatusCodesFailed: boolean;
  postingOfferItemStatusCodeFailed?: boolean;
  isPostingOfferItemStatusCode: boolean;
  postingOfferItemStatusCodeValidationErrors: FormError[] | null;
  postingOfferItemStatusCodeError: string | null;
}

interface FetchOfferItemStatusCodesRequestAction {
  type: typeof FETCH_OFFER_ITEM_STATUS_CODES_REQUEST;
  payload: null;
}

interface FetchOfferItemStatusCodesResponseAction {
  type: typeof FETCH_OFFER_ITEM_STATUS_CODES_RESPONSE;
  payload: OfferItemStatusCodeDataModel;
}

interface FetchOfferItemStatusCodesFailureAction {
  type: typeof FETCH_OFFER_ITEM_STATUS_CODES_FAILURE;
  payload: null;
}

interface FetchOfferItemStatusCodeRequestAction {
  type: typeof FETCH_OFFER_ITEM_STATUS_CODE_REQUEST;
  payload: null;
}

interface FetchOfferItemStatusCodeResponseAction {
  type: typeof FETCH_OFFER_ITEM_STATUS_CODE_RESPONSE;
  payload: OfferItemStatusCode;
}

interface FetchOfferItemStatusCodeFailureAction {
  type: typeof FETCH_OFFER_ITEM_STATUS_CODE_FAILURE;
  payload: null;
}

interface PostOfferItemStatusCodeRequestAction {
  type: typeof POST_OFFER_ITEM_STATUS_CODE_REQUEST;
  payload: null;
}

interface PostOfferItemStatusCodeResponseAction {
  type: typeof POST_OFFER_ITEM_STATUS_CODE_RESPONSE;
  payload: null;
}

interface PostOfferItemStatusCodeFailureAction {
  type: typeof POST_OFFER_ITEM_STATUS_CODE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type OfferItemStatusCodeActionTypes =
    | FetchOfferItemStatusCodesRequestAction
    | FetchOfferItemStatusCodesResponseAction
    | FetchOfferItemStatusCodesFailureAction
    | PostOfferItemStatusCodeRequestAction
    | PostOfferItemStatusCodeResponseAction
    | PostOfferItemStatusCodeFailureAction
    | FetchOfferItemStatusCodeRequestAction
    | FetchOfferItemStatusCodeResponseAction
    | FetchOfferItemStatusCodeFailureAction;
