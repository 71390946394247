import { ApiDataModel, FormError } from './Common';

export const FETCH_COMPOSITIONS_REQUEST = 'FETCH_COMPOSITIONS_REQUEST';
export const FETCH_COMPOSITIONS_RESPONSE = 'FETCH_COMPOSITIONS_RESPONSE';
export const FETCH_COMPOSITIONS_FAILURE = 'FETCH_COMPOSITIONS_FAILURE';

export const FETCH_COMPOSITION_REQUEST = 'FETCH_COMPOSITION_REQUEST';
export const FETCH_COMPOSITION_RESPONSE = 'FETCH_COMPOSITION_RESPONSE';
export const FETCH_COMPOSITION_FAILURE = 'FETCH_COMPOSITION_FAILURE';

export const POST_COMPOSITION_REQUEST = 'POST_COMPOSITION_REQUEST';
export const POST_COMPOSITION_RESPONSE = 'POST_COMPOSITION_RESPONSE';
export const POST_COMPOSITION_FAILURE = 'POST_COMPOSITION_FAILURE';

export interface Composition {
  id: string;
  material: string;
  variation: string;
  CO2: number;
  unitOfMeasure: string;
  created: Date;
  createdBy: string | null;
  updated: Date | null;
  updatedBy: string | null;
  archived: Date | null;
  archivedBy: string | null;
}

export interface CompositionDataModel extends Omit<ApiDataModel, 'data'> {
  data: Composition[];
}

export interface CompositionState {
  compositions: CompositionDataModel | null,
  activeComposition: Composition | null,
  isFetchingCompositions: boolean;
  fetchingCompositionsFailed: boolean;
  postingCompositionFailed?: boolean;
  isPostingComposition: boolean;
  postingCompositionValidationErrors: FormError[] | null;
  postingCompositionError: string | null;
}

interface FetchCompositionsRequestAction {
  type: typeof FETCH_COMPOSITIONS_REQUEST;
  payload: null;
}

interface FetchCompositionsResponseAction {
  type: typeof FETCH_COMPOSITIONS_RESPONSE;
  payload: CompositionDataModel;
}

interface FetchCompositionsFailureAction {
  type: typeof FETCH_COMPOSITIONS_FAILURE;
  payload: null;
}

interface FetchCompositionRequestAction {
  type: typeof FETCH_COMPOSITION_REQUEST;
  payload: null;
}

interface FetchCompositionResponseAction {
  type: typeof FETCH_COMPOSITION_RESPONSE;
  payload: Composition;
}

interface FetchCompositionFailureAction {
  type: typeof FETCH_COMPOSITION_FAILURE;
  payload: null;
}

interface PostCompositionRequestAction {
  type: typeof POST_COMPOSITION_REQUEST;
  payload: null;
}

interface PostCompositionResponseAction {
  type: typeof POST_COMPOSITION_RESPONSE;
  payload: null;
}

interface PostCompositionFailureAction {
  type: typeof POST_COMPOSITION_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type CompositionActionTypes =
    | FetchCompositionsRequestAction
    | FetchCompositionsResponseAction
    | FetchCompositionsFailureAction
    | PostCompositionRequestAction
    | PostCompositionResponseAction
    | PostCompositionFailureAction
    | FetchCompositionRequestAction
    | FetchCompositionResponseAction
    | FetchCompositionFailureAction;
