import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c, { SearchType } from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPopularSearches, createOrUpdatePopularSearch } from '../../../actions/PopularSearch';
import {
  isPostingPopularSearch,
  postingPopularSearchError,
  postingPopularSearchFailed,
  postingPopularSearchValidationErrors,
  isFetchingPopularSearches,
} from '../../../selectors/PopularSearch';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';



const PopularSearchForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingPopularSearch);
  const isFetching = useSelector(isFetchingPopularSearches);
  const postingFailed = useSelector(postingPopularSearchFailed);
  const postingError = useSelector(postingPopularSearchError);
  const postingValidationErrors = useSelector(postingPopularSearchValidationErrors);


  const formConfig = [
    { field: 'searchType', validators: [isRequired] },
    { field: 'searchTerm', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [searchType, setSearchType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    dispatch(fetchPopularSearches());
  }, [dispatch]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.POPULAR_SEARCH);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createOrUpdatePopularSearch(searchType, searchTerm));
    }
  };
  
  const SEARCH_TYPES = [
    { label: 'Marketplace', value: SearchType.MARKETPLACE },
    { label: 'Auction', value: SearchType.AUCTION },
  ];

  const itemsSearchTypes = SEARCH_TYPES?.map(r => ({ label: r.label, value: r.value })) || [];

  const onSearchTypeSelect = (obj: any) => {
    setSearchType(obj.value);
  };

  return (
        <View>
            <h4>{'Create'} PopularSearch</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown v={v} err={err}
                          id="searchType"
                          placeholder="Please select a search type"
                          required label="Search Type"
                          items={itemsSearchTypes}
                          value={searchType}
                          disabled={isPosting || isFetching}
                          onSelect={onSearchTypeSelect}
                          type="default"
                />

                <Input v={v} err={err}
                       name="searchTerm"
                       id="searchTerm"
                       label="Search Term"
                       value={searchTerm}
                       onChange={setSearchTerm}
                       disabled={isPosting || isFetching}
                       onBlur={setSearchTerm}
                       placeholder="Insert a search term"
                />

                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => onSubmit()}
                                title={'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default PopularSearchForm;
