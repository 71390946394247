import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c, { offerItemStatusCodes } from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createOfferItemStatusCode, fetchActiveOfferItemStatusCode, updateOfferItemStatusCode } from '../../../actions/OfferItemStatusCode';
import {
  getActiveOfferItemStatusCode,
  isPostingOfferItemStatusCode,
  postingOfferItemStatusCodeError,
  postingOfferItemStatusCodeFailed,
  postingOfferItemStatusCodeValidationErrors,
  isFetchingOfferItemStatusCodes,
} from '../../../selectors/OfferItemStatusCode';
import { fetchOfferItems } from '../../../actions/OfferItem';
import { getOfferItems } from '../../../selectors/OfferItem';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { Alert } from 'react-bootstrap';
import TextArea from '../../../components/TextArea';
import { fetchStatusCodes } from '../../../actions/StatusCode';

const OfferItemStatusCodeForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeOfferItemStatusCode = useSelector(getActiveOfferItemStatusCode);
  const isPosting = useSelector(isPostingOfferItemStatusCode);
  const isFetching = useSelector(isFetchingOfferItemStatusCodes);
  const postingFailed = useSelector(postingOfferItemStatusCodeFailed);
  const postingError = useSelector(postingOfferItemStatusCodeError);
  const postingValidationErrors = useSelector(postingOfferItemStatusCodeValidationErrors);

  const offerItems = useSelector(getOfferItems);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.OFFER_ITEM_STATUS_CODE_UPDATE);

  const [offerItemId, setOfferItemId] = useState('');
  const [status, setStatus] = useState('');
  const [notes, setNotes] = useState('');

  const formConfig = [
    { field: 'offerItemId', validators: [isRequired] },
    { field: 'status', validators: [isRequired] },
    { field: 'notes', validators: [] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    dispatch(fetchOfferItems());
    dispatch(fetchStatusCodes());
    if (editMode) {
      dispatch(fetchActiveOfferItemStatusCode(activeId));
    }
  }, [dispatch, editMode, activeId]);

  useEffect(() => {
    if (activeOfferItemStatusCode && editMode) {
      setOfferItemId(activeOfferItemStatusCode.offerItemId);
      setStatus(activeOfferItemStatusCode.status);
      if (activeOfferItemStatusCode.notes){
        setNotes(activeOfferItemStatusCode.notes);
      }
    }
  }, [activeOfferItemStatusCode, editMode]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.OFFER_ITEM_STATUS_CODE);
    }
  }, [isPosting, postingFailed, hasSubmitted, history]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createOfferItemStatusCode(offerItemId, status, notes));
    }
  };

  const onSave = async () => {
    setHasSubmitted(true);
    dispatch(updateOfferItemStatusCode(activeId, offerItemId, status, notes));
  };

  const itemsOfferItems = offerItems?.data.map(r => ({ label: r.details ?? 'Details not provided', value: r.id })) || [];
  const itemsStatusCodes = offerItemStatusCodes?.map(r => ({ label: r.label, value: r.value })) || [];

  const onOfferItemSelect = (obj: any) => {
    setOfferItemId(obj.value);
  };

  const onStatusCodeSelect = (obj: any) => {
    setStatus(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Offer StatusCode</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown
                    v={v}
                    err={err}
                    name="offerItemId"
                    id="offerItemId"
                    label="Offer Item"
                    required
                    items={itemsOfferItems}
                    value={offerItemId}
                    onSelect={onOfferItemSelect}
                    disabled={isPosting || isFetching}
                />
                <DropDown
                    v={v}
                    err={err}
                    name="status"
                    id="status"
                    label="Status"
                    required
                    items={itemsStatusCodes}
                    value={status}
                    onSelect={onStatusCodeSelect}
                    disabled={isPosting || isFetching}
                />
                <TextArea
                    v={v}
                    err={err}
                    name="notes"
                    id="notes"
                    label="Notes"
                    value={notes}
                    onChange={setNotes}
                    disabled={isPosting || isFetching}
                    onBlur={setNotes}
                    placeholder="Insert notes"
                />
                <div className="row">
                    <div className="col-md-4">
                        <Button
                            disabled={!isFormValid()}
                            isLoading={isPosting || isFetching}
                            onClick={() => (editMode ? onSave() : onSubmit())}
                            title={editMode ? 'SAVE' : 'CREATE'}
                        />
                    </div>
                </div>
            </form>
        </View>
  );
};

export default OfferItemStatusCodeForm;
