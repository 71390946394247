import {
  FETCH_ENTRY_COMPOSITIONS_FAILURE,
  FETCH_ENTRY_COMPOSITIONS_REQUEST,
  FETCH_ENTRY_COMPOSITIONS_RESPONSE,
  FETCH_ENTRY_COMPOSITION_FAILURE,
  FETCH_ENTRY_COMPOSITION_REQUEST,
  FETCH_ENTRY_COMPOSITION_RESPONSE,
  POST_ENTRY_COMPOSITION_FAILURE,
  POST_ENTRY_COMPOSITION_REQUEST,
  POST_ENTRY_COMPOSITION_RESPONSE,
  EntryCompositionActionTypes,
  EntryCompositionState,
} from '../types/EntryComposition';
import { parseValidationErrors } from '../helpers/functions';

const initialState: EntryCompositionState = {
  entryCompositions: null,
  activeEntryComposition: null,
  isFetchingEntryCompositions: false,
  fetchingEntryCompositionsFailed: false,
  isPostingEntryComposition: false,
  postingEntryCompositionFailed: false,
  postingEntryCompositionError: null,
  postingEntryCompositionValidationErrors: null,
};

const entryCompositionsReducer = (
  state = initialState,
  action: EntryCompositionActionTypes,
): EntryCompositionState => {
  switch (action.type) {
    case FETCH_ENTRY_COMPOSITIONS_REQUEST:
      return {
        ...state,
        entryCompositions: null,
        isFetchingEntryCompositions: true,
        fetchingEntryCompositionsFailed: false,
      };
    case FETCH_ENTRY_COMPOSITIONS_RESPONSE:
      return {
        ...state,
        entryCompositions: action.payload || [],
        isFetchingEntryCompositions: false,
        fetchingEntryCompositionsFailed: false,
      };
    case FETCH_ENTRY_COMPOSITIONS_FAILURE:
      return {
        ...state,
        entryCompositions: null,
        isFetchingEntryCompositions: false,
        fetchingEntryCompositionsFailed: true,
      };
    case FETCH_ENTRY_COMPOSITION_REQUEST:
      return {
        ...state,
        entryCompositions: null,
        isFetchingEntryCompositions: true,
        fetchingEntryCompositionsFailed: false,
      };
    case FETCH_ENTRY_COMPOSITION_RESPONSE:
      return {
        ...state,
        activeEntryComposition: action.payload,
        isFetchingEntryCompositions: false,
        fetchingEntryCompositionsFailed: false,
      };
    case FETCH_ENTRY_COMPOSITION_FAILURE:
      return {
        ...state,
        entryCompositions: null,
        isFetchingEntryCompositions: false,
        fetchingEntryCompositionsFailed: true,
      };
    case POST_ENTRY_COMPOSITION_REQUEST:
      return {
        ...state,
        isPostingEntryComposition: true,
        postingEntryCompositionError: null,
        postingEntryCompositionValidationErrors: null,
        postingEntryCompositionFailed: false,
      };
    case POST_ENTRY_COMPOSITION_RESPONSE:
      return {
        ...state,
        isPostingEntryComposition: false,
        postingEntryCompositionError: null,
        postingEntryCompositionValidationErrors: null,
        postingEntryCompositionFailed: false,
      };
    case POST_ENTRY_COMPOSITION_FAILURE:
      return {
        ...state,
        isPostingEntryComposition: false,
        postingEntryCompositionError: action.payload.error || 'An error occurred',
        postingEntryCompositionValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingEntryCompositionFailed: true,
      };
    default:
      return state;
  }
};

export default entryCompositionsReducer;
