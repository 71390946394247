import { RootState } from '../store/configureStore';

export const getOfferStatusCodes = (state: RootState) => state?.offerStatusCode?.offerStatusCodes;

export const getActiveOfferStatusCode = (state: RootState) => state?.offerStatusCode?.activeOfferStatusCode;

export const isFetchingOfferStatusCodes = (state: RootState) => state?.offerStatusCode?.isFetchingOfferStatusCodes;

export const isPostingOfferStatusCode = (state: RootState) => state?.offerStatusCode?.isPostingOfferStatusCode;
export const postingOfferStatusCodeFailed = (state: RootState) => state?.offerStatusCode?.postingOfferStatusCodeFailed;
export const postingOfferStatusCodeError = (state: RootState) => state?.offerStatusCode?.postingOfferStatusCodeError;
export const postingOfferStatusCodeValidationErrors = (state: RootState) => state?.offerStatusCode?.postingOfferStatusCodeValidationErrors;
export const fetchingOfferStatusCodesFailed = (state: RootState) => state?.offerStatusCode?.fetchingOfferStatusCodesFailed;