import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  CLEAR_POPULAR_SEARCH,
  FETCH_POPULAR_SEARCH_FAILURE,
  FETCH_POPULAR_SEARCH_REQUEST,
  FETCH_POPULAR_SEARCH_RESPONSE,
  FETCH_POPULAR_SEARCHES_FAILURE,
  FETCH_POPULAR_SEARCHES_REQUEST,
  FETCH_POPULAR_SEARCHES_RESPONSE,
  PopularSearch,
  PopularSearchActionTypes,
  PopularSearchDataModel,
  POST_POPULAR_SEARCH_FAILURE,
  POST_POPULAR_SEARCH_REQUEST,
  POST_POPULAR_SEARCH_RESPONSE,
} from '../types/PopularSearch';

import { RootState } from '../store/configureStore';

import {
  deletePopularSearch,
  getPopularSearch,
  getPopularSearches,
  getPopularSearchesByType, getPopularSearchesWithPublicData,
  putPopularSearch,
} from '../api/PopularSearch';
import { DataTableParamsModel } from '../types/Common';


export function fetchPopularSearchesRequest(): PopularSearchActionTypes {
  return {
    type: FETCH_POPULAR_SEARCHES_REQUEST,
    payload: null,
  };
}

export function fetchPopularSearchesResponse(
  popularSearches: PopularSearchDataModel,
): PopularSearchActionTypes {
  return {
    type: FETCH_POPULAR_SEARCHES_RESPONSE,
    payload: popularSearches,
  };
}

export function fetchPopularSearchesFailure(): PopularSearchActionTypes {
  return {
    type: FETCH_POPULAR_SEARCHES_FAILURE,
    payload: null,
  };
}

export function fetchPopularSearchRequest(): PopularSearchActionTypes {
  return {
    type: FETCH_POPULAR_SEARCH_REQUEST,
    payload: null,
  };
}

export function fetchPopularSearchResponse(
  popularSearch: PopularSearch,
): PopularSearchActionTypes {
  return {
    type: FETCH_POPULAR_SEARCH_RESPONSE,
    payload: popularSearch,
  };
}

export function fetchPopularSearchFailure(): PopularSearchActionTypes {
  return {
    type: FETCH_POPULAR_SEARCH_FAILURE,
    payload: null,
  };
}


export function postPopularSearchRequest(): PopularSearchActionTypes {
  return {
    type: POST_POPULAR_SEARCH_REQUEST,
    payload: null,
  };
}

export function postPopularSearchResponse(): PopularSearchActionTypes {
  return {
    type: POST_POPULAR_SEARCH_RESPONSE,
    payload: null,
  };
}

export function clearPopularSearch(): PopularSearchActionTypes {
  return {
    type: CLEAR_POPULAR_SEARCH,
    payload: null,
  };
}

export function postPopularSearchFailure(error: string, validationErrors: any): PopularSearchActionTypes {
  return {
    type: POST_POPULAR_SEARCH_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActivePopularSearch = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchPopularSearchRequest());
    const asyncResp: any = await getPopularSearch(id);
    if (asyncResp?.success) {
      await dispatch(fetchPopularSearchResponse(asyncResp.data));
    } else {
      await dispatch(fetchPopularSearchFailure());
    }
  };


export const fetchPopularSearches = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchPopularSearchesRequest());
    const asyncResp: any = await getPopularSearches(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchPopularSearchesResponse(asyncResp));
    } else {
      await dispatch(fetchPopularSearchesFailure());
    }
  };

export const fetchPopularSearchesWithPublicData = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchPopularSearchesRequest());
    const asyncResp: any = await getPopularSearchesWithPublicData(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchPopularSearchesResponse(asyncResp));
    } else {
      await dispatch(fetchPopularSearchesFailure());
    }
  };

export const fetchPopularSearchesByType = (searchType: string, datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchPopularSearchesRequest());
    const asyncResp: any = await getPopularSearchesByType(searchType, datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchPopularSearchesResponse(asyncResp));
    } else {
      await dispatch(fetchPopularSearchesFailure());
    }
  };

export const createOrUpdatePopularSearch = (
  searchType: string,
  searchTerm: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postPopularSearchRequest());
    const asyncResp: any = await putPopularSearch(searchType, searchTerm);
    if (asyncResp?.success) {
      await dispatch(postPopularSearchResponse());
      await dispatch(fetchPopularSearches());
    } else {
      await dispatch(postPopularSearchFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const destroyPopularSearch = async (id: string) => {
  try {
    await deletePopularSearch(id);
  } catch (error) {
    throw error;
  }
};


