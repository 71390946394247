import { ApiDataModel, FormError } from './Common';

export const FETCH_ENTRY_COMPOSITIONS_REQUEST = 'FETCH_ENTRY_COMPOSITIONS_REQUEST';
export const FETCH_ENTRY_COMPOSITIONS_RESPONSE = 'FETCH_ENTRY_COMPOSITIONS_RESPONSE';
export const FETCH_ENTRY_COMPOSITIONS_FAILURE = 'FETCH_ENTRY_COMPOSITIONS_FAILURE';

export const FETCH_ENTRY_COMPOSITION_REQUEST = 'FETCH_ENTRY_COMPOSITION_REQUEST';
export const FETCH_ENTRY_COMPOSITION_RESPONSE = 'FETCH_ENTRY_COMPOSITION_RESPONSE';
export const FETCH_ENTRY_COMPOSITION_FAILURE = 'FETCH_ENTRY_COMPOSITION_FAILURE';

export const POST_ENTRY_COMPOSITION_REQUEST = 'POST_ENTRY_COMPOSITION_REQUEST';
export const POST_ENTRY_COMPOSITION_RESPONSE = 'POST_ENTRY_COMPOSITION_RESPONSE';
export const POST_ENTRY_COMPOSITION_FAILURE = 'POST_ENTRY_COMPOSITION_FAILURE';

export interface EntryComposition {
  id: string;
  entryId: string;
  compositionId: string;
  quantity: number;
  unitOfMeasure: string;
  createdBy: string | null;
  updatedBy: string | null;
  archivedBy: string | null;
  created: Date | null;
  updated: Date | null;
  archived: Date | null;
}

export interface EntryCompositionDataModel extends Omit<ApiDataModel, 'data'> {
  data: EntryComposition[];
}

export interface EntryCompositionState {
  entryCompositions: EntryCompositionDataModel | null,
  activeEntryComposition: EntryComposition | null,
  isFetchingEntryCompositions: boolean;
  fetchingEntryCompositionsFailed: boolean;
  postingEntryCompositionFailed?: boolean;
  isPostingEntryComposition: boolean;
  postingEntryCompositionValidationErrors: FormError[] | null;
  postingEntryCompositionError: string | null;
}

interface FetchEntryCompositionsRequestAction {
  type: typeof FETCH_ENTRY_COMPOSITIONS_REQUEST;
  payload: null;
}

interface FetchEntryCompositionsResponseAction {
  type: typeof FETCH_ENTRY_COMPOSITIONS_RESPONSE;
  payload: EntryCompositionDataModel;
}

interface FetchEntryCompositionsFailureAction {
  type: typeof FETCH_ENTRY_COMPOSITIONS_FAILURE;
  payload: null;
}

interface FetchEntryCompositionRequestAction {
  type: typeof FETCH_ENTRY_COMPOSITION_REQUEST;
  payload: null;
}

interface FetchEntryCompositionResponseAction {
  type: typeof FETCH_ENTRY_COMPOSITION_RESPONSE;
  payload: EntryComposition;
}

interface FetchEntryCompositionFailureAction {
  type: typeof FETCH_ENTRY_COMPOSITION_FAILURE;
  payload: null;
}

interface PostEntryCompositionRequestAction {
  type: typeof POST_ENTRY_COMPOSITION_REQUEST;
  payload: null;
}

interface PostEntryCompositionResponseAction {
  type: typeof POST_ENTRY_COMPOSITION_RESPONSE;
  payload: null;
}

interface PostEntryCompositionFailureAction {
  type: typeof POST_ENTRY_COMPOSITION_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type EntryCompositionActionTypes =
    | FetchEntryCompositionsRequestAction
    | FetchEntryCompositionsResponseAction
    | FetchEntryCompositionsFailureAction
    | PostEntryCompositionRequestAction
    | PostEntryCompositionResponseAction
    | PostEntryCompositionFailureAction
    | FetchEntryCompositionRequestAction
    | FetchEntryCompositionResponseAction
    | FetchEntryCompositionFailureAction;
