import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_RESPONSE,
  FETCH_SUBSCRIPTIONS_FAILURE,
  FETCH_SUBSCRIPTION_REQUEST,
  FETCH_SUBSCRIPTION_RESPONSE,
  FETCH_SUBSCRIPTION_FAILURE,
  POST_SUBSCRIPTION_REQUEST,
  POST_SUBSCRIPTION_RESPONSE,
  POST_SUBSCRIPTION_FAILURE,
  SubscriptionActionTypes,
  SubscriptionState,
} from '../types/Subscription';
import { parseValidationErrors } from '../helpers/functions';

const initialState: SubscriptionState = {
  subscriptions: null,
  activeSubscription: null,
  isFetchingSubscriptions: false,
  fetchingSubscriptionsFailed: false,
  isPostingSubscription: false,
  postingSubscriptionFailed: false,
  postingSubscriptionError: null,
  postingSubscriptionValidationErrors: null,
};

const subscriptionReducer = (
  state = initialState,
  action: SubscriptionActionTypes,
): SubscriptionState => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        subscriptions: null,
        isFetchingSubscriptions: true,
        fetchingSubscriptionsFailed: false,
      };
    case FETCH_SUBSCRIPTIONS_RESPONSE:
      return {
        ...state,
        subscriptions: action.payload || [],
        isFetchingSubscriptions: false,
        fetchingSubscriptionsFailed: false,
      };
    case FETCH_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        subscriptions: null,
        isFetchingSubscriptions: false,
        fetchingSubscriptionsFailed: true,
      };
    case FETCH_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        activeSubscription: null,
        isFetchingSubscriptions: true,
        fetchingSubscriptionsFailed: false,
      };
    case FETCH_SUBSCRIPTION_RESPONSE:
      return {
        ...state,
        activeSubscription: action.payload,
        isFetchingSubscriptions: false,
        fetchingSubscriptionsFailed: false,
      };
    case FETCH_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        activeSubscription: null,
        isFetchingSubscriptions: false,
        fetchingSubscriptionsFailed: true,
      };
    case POST_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isPostingSubscription: true,
        postingSubscriptionError: null,
        postingSubscriptionValidationErrors: null,
        postingSubscriptionFailed: false,
      };
    case POST_SUBSCRIPTION_RESPONSE:
      return {
        ...state,
        isPostingSubscription: false,
        postingSubscriptionError: null,
        postingSubscriptionValidationErrors: null,
        postingSubscriptionFailed: false,
      };
    case POST_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isPostingSubscription: false,
        postingSubscriptionError: action.payload.error || 'An error occurred',
        postingSubscriptionValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingSubscriptionFailed: true,
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
