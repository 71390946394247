import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createOffer, fetchActiveOffer, updateOffer } from '../../../actions/Offer';
import { getClients } from '../../../selectors/Client';
import { getUsers } from '../../../selectors/User';
import {
  getActiveOffer,
  isPostingOffer,
  postingOfferError,
  postingOfferFailed,
  postingOfferValidationErrors,
  isFetchingOffers,
} from '../../../selectors/Offer';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { fetchClients } from '../../../actions/Client';
import { fetchUsers } from '../../../actions/User';
import { fetchContacts } from '../../../actions/Contact';
import { getContacts } from '../../../selectors/Contact';
import { getSolutions } from '../../../selectors/Solution';
import { fetchSolutions } from '../../../actions/Solution';
import DateSelect from '../../../components/DateSelect';
import TextArea from '../../../components/TextArea';
import MaterialMap from '../../../components/MaterialMap';

const OfferForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeOffer = useSelector(getActiveOffer);
  const clients = useSelector(getClients);
  const users = useSelector(getUsers);
  const contacts = useSelector(getContacts);
  const solutions = useSelector(getSolutions);
  let lat = 48.1312863;
  let lng = 4.1659645;
  let zoom = 4;
  const isPosting = useSelector(isPostingOffer);
  const isFetching = useSelector(isFetchingOffers);
  const postingFailed = useSelector(postingOfferFailed);
  const postingError = useSelector(postingOfferError);
  const postingValidationErrors = useSelector(postingOfferValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.OFFER_UPDATE);

  const formConfig = [
    { field: 'subject', validators: [isRequired] },
    { field: 'details', validators: [isRequired] },
    { field: 'date', validators: [] },
    { field: 'clientNumber', validators: [isRequired] },
    { field: 'currency', validators: [isRequired] },
    { field: 'pickupLocation', validators: [] },
    { field: 'collectionDate', validators: [] },
    { field: 'notesToEME', validators: [] },
    { field: 'notesToShippingProvider', validators: [] },
    { field: 'salesRepUserId', validators: [isRequired] },
    { field: 'shipmentContactId', validators: [isRequired] },
    { field: 'solutionId', validators: [isRequired] },
    { field: 'donorClientId', validators: [isRequired] },
    { field: 'projectManagerUserId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [subject, setSubject] = useState('');
  const [details, setDetails] = useState('');

  const [date, setDate] = useState(new Date());
  const [collectionDate, setCollectionDate] = useState(new Date());
  const [clientNumber, setClientNumber] = useState('');
  const [currency, setCurrency] = useState('');
  const [pickupLocation, setPickupLocation] = useState('');
  const [notesToEME, setNotesToEME] = useState('');
  const [notesToShippingProvider, setNotesToShippingProvider] = useState('');
  const [salesRepUserId, setSalesRepUserId] = useState('');
  const [shipmentContactId, setShipmentContactId] = useState('');
  const [solutionId, setSolutionId] = useState('');
  const [donorClientId, setDonorClientId] = useState('');
  const [projectManagerUserId, setProjectManagerUserId] = useState('');

  const [itemsClients, setItemsClients] = useState<{ label: string; value: string; }[]>([]);
  const [itemsUsers, setItemsUsers] = useState<{ label: string; value: string; }[]>([]);
  const [itemsContacts, setItemsContacts] = useState<{ label: string; value: string; }[]>([]);
  const [itemsSolutions, setItemsSolutions] = useState<{ label: string; value: string; }[]>([]);


  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchUsers());
    dispatch(fetchContacts());
    dispatch(fetchSolutions());
    if (editMode) {
      dispatch(fetchActiveOffer(activeId));
    }
  }, [dispatch, editMode, activeId]);

  useEffect(() => {
    if (clients) {
      setItemsClients(clients.data.map(client => ({ label: client.title, value: client.id })));
    }
  }, [clients]);

  useEffect(() => {
    if (users) {
      setItemsUsers(users.data.map(user => ({ label: user.email, value: user.id })));
    }
  }, [users]);

  useEffect(() => {
    if (contacts) {
      setItemsContacts(contacts.data.map(contact => ({ label: contact.email, value: contact.id })));
    }
  }, [contacts]);

  useEffect(() => {
    if (solutions) {
      setItemsSolutions(solutions.data.map(solution => ({ label: solution.title, value: solution.id })));
    }
  }, [solutions]);

  useEffect(() => {
    if (activeOffer && editMode) {
      setSubject(activeOffer?.subject);
      setDetails(activeOffer?.details);
      setClientNumber(activeOffer?.clientNumber);
      setCurrency(activeOffer?.currency);
      setPickupLocation(activeOffer?.pickupLocation);
      setDate(new Date(activeOffer?.date));
      setCollectionDate(new Date(activeOffer?.collectionDate));
      setNotesToEME(activeOffer?.notesToEME);
      setNotesToShippingProvider(activeOffer?.notesToShippingProvider);
      setSalesRepUserId(activeOffer?.salesRepUserId);
      setShipmentContactId(activeOffer?.shipmentContactId);
      setSolutionId(activeOffer?.solutionId);
      setDonorClientId(activeOffer?.donorClientId);
      setProjectManagerUserId(activeOffer?.projectManagerUserId);
    }
  }, [activeOffer, editMode]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.OFFER);
    }
  }, [isPosting, postingFailed, hasSubmitted, history]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createOffer(
        donorClientId,
        projectManagerUserId,
        salesRepUserId,
        shipmentContactId,
        solutionId,
        subject,
        details,
        date.toISOString(),
        clientNumber,
        currency,
        pickupLocation,
        collectionDate.toISOString(),
        notesToEME,
        notesToShippingProvider,
      ));
    }
  };

  const onSave = async () => {
    setHasSubmitted(true);
    dispatch(updateOffer(
      activeId,
      donorClientId,
      projectManagerUserId,
      salesRepUserId,
      shipmentContactId,
      solutionId,
      subject,
      details,
      date.toISOString(),
      clientNumber,
      currency,
      pickupLocation,
      collectionDate.toISOString(),
      notesToEME,
      notesToShippingProvider,
    ));
  };

  const onClientSelect = (obj: any) => {
    setDonorClientId(obj.value);
  };

  const onProjectManagerSelect = (obj: any) => {
    setProjectManagerUserId(obj.value);
  };

  const onSalesRepSelect = (obj: any) => {
    setSalesRepUserId(obj.value);
  };

  const onShipmentContactSelect = (obj: any) => {
    setShipmentContactId(obj.value);
  };

  const onSolutionSelect = (obj: any) => {
    setSolutionId(obj.value);
  };


  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Offer</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown v={v} err={err}
                          id="donorClientId"
                          placeholder="Please select a Donor Client"
                          required label="Donor Client"
                          items={itemsClients}
                          value={donorClientId}
                          disabled={isPosting || isFetching}
                          onSelect={onClientSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="projectManagerUserId"
                          placeholder="Please select a Project Manager"
                          required label="Project Manager"
                          items={itemsUsers}
                          value={projectManagerUserId}
                          disabled={isPosting || isFetching}
                          onSelect={onProjectManagerSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="salesRepUserId"
                          placeholder="Please select a Sales Rep"
                          required label="Sales Rep"
                          items={itemsUsers}
                          value={salesRepUserId}
                          disabled={isPosting || isFetching}
                          onSelect={onSalesRepSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="shipmentContactId"
                          placeholder="Please select a Shipment Contact"
                          required label="Shipment Contact"
                          items={itemsContacts}
                          value={shipmentContactId}
                          disabled={isPosting || isFetching}
                          onSelect={onShipmentContactSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="solutionId"
                          placeholder="Please select a Solution"
                          required label="Solution"
                          items={itemsSolutions}
                          value={solutionId}
                          disabled={isPosting || isFetching}
                          onSelect={onSolutionSelect}
                          type="default"
                />
                <Input v={v} err={err}
                       name="subject"
                       id="subject"
                       label="Subject"
                       required
                       value={subject}
                       onChange={setSubject}
                       disabled={isPosting || isFetching}
                       onBlur={setSubject}
                       placeholder="Insert a subject"
                />
                <Input v={v} err={err}
                       required
                       name="details"
                       id="details"
                       label="Details"
                       value={details}
                       disabled={isPosting || isFetching}
                       onChange={setDetails}
                       onBlur={setDetails}
                       placeholder="Insert details"
                />
                <DateSelect
                    onChange={setDate}
                    id="Date"
                    title="Date"
                    value={date}
                    required
                />
                <Input v={v} err={err}
                       required
                       name="clientNumber"
                       id="clientNumber"
                       type="tel"
                       label="Client Number"
                       value={clientNumber}
                       disabled={isPosting || isFetching}
                       onChange={setClientNumber}
                       onBlur={setClientNumber}
                       placeholder="Insert the client number"
                />
                <Input v={v} err={err}
                       required
                       name="currency"
                       id="currency"
                       label="Currency"
                       value={currency}
                       disabled={isPosting || isFetching}
                       onChange={setCurrency}
                       onBlur={setCurrency}
                       placeholder="Insert the currency"
                />
                <div className="row mb-4">
                    <MaterialMap
                        lat={lat}
                        lng={lng}
                        zoom={zoom}
                        address={pickupLocation}
                        isEntryRenderer={true}
                        onChange={(la, ln, zm, adr) =>
                          setPickupLocation(`${la}~${ln}~${zm}~${adr}`)}
                        editMode={true}
                    />
                </div>
                <DateSelect
                    onChange={setCollectionDate}
                    title="Collection Date"
                    id="Collection Date"
                    value={collectionDate}
                    required
                />
                <TextArea v={v} err={err}
                       name="notesToEME"
                       id="notesToEME"
                       label="Notes to EME"
                       value={notesToEME}
                       disabled={isPosting || isFetching}
                       onChange={setNotesToEME}
                       onBlur={setNotesToEME}
                       placeholder="Insert notes to EME"
                />
                <TextArea v={v} err={err}
                       name="notesToShippingProvider"
                       id="notesToShippingProvider"
                       label="Notes to Shipping Provider"
                       value={notesToShippingProvider}
                       disabled={isPosting || isFetching}
                       onChange={setNotesToShippingProvider}
                       onBlur={setNotesToShippingProvider}
                       placeholder="Insert notes to the shipping provider"
                />
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'} />
                    </div>
                </div>
            </form>
        </View>
  );
};

export default OfferForm;
