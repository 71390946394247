import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Input from '../../components/Input';
import Button from '../../components/Button';
import DropDown from '../../components/DropDown';
import c from '../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createClient, fetchActiveClient, fetchClients } from '../../actions/Client';
import { getGroups } from '../../selectors/Group';
import { fetchGroups } from '../../actions/Group';
import {
  getActiveClient,
  isPostingClient,
  postingClientError,
  postingClientFailed,
  postingClientValidationErrors,
  isFetchingClients,
} from '../../selectors/Client';
import { isRequired } from '../../helpers/validators';
import { useValidation } from '../../hooks/ValidationHook';
import { useEditMode } from '../../hooks/EditModeHook';
import { getLoggedInRole, getLoggedInUser } from '../../selectors/Auth';
import { useLocation } from 'react-router-dom';

const ClientForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isOfferForm = location.pathname.startsWith('/admin/deal');
  const activeClient = useSelector(getActiveClient);
  const groups = useSelector(getGroups);
  const isPosting = useSelector(isPostingClient);
  const isFetching = useSelector(isFetchingClients);
  const postingFailed = useSelector(postingClientFailed);
  const postingError = useSelector(postingClientError);
  const postingValidationErrors = useSelector(postingClientValidationErrors);
  const loggedInUser = useSelector(getLoggedInUser);
  const loggedInRole = useSelector(getLoggedInRole);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.CLIENT_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: loggedInUser && loggedInUser.groups.length > 1 ? [isRequired] : [] },
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [] },
    { field: 'email', validators: [isRequired] },
    { field: 'phone', validators: [isRequired] },
    { field: 'website', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [groupId, setGroupId] = useState(loggedInUser?.groups.length === 1 ? loggedInUser.groups[0].groupId : '');
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    if (editMode) {
      dispatch(fetchActiveClient(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeClient && editMode) {
      setTitle(activeClient?.title);
      setDescription(activeClient?.description);
      setEmail(activeClient?.email);
      setPhone(activeClient?.phone);
      setWebsite(activeClient?.website);
      setGroupId(activeClient?.groupId);
      // @ts-ignore
      setActive(activeClient?.active === 1 || activeClient?.active === true);
    }
  }, [activeClient]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createClient(groupId, title, description, email, phone, website, active, isOfferForm));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
  };

  return (
      <>
          <h4>Create Client</h4>
          {postingError && (
              <Alert className="mt-3" variant="danger">
                  {postingError}
              </Alert>
          )}
          <form>
              {loggedInUser && loggedInRole &&
                  loggedInUser?.groups.length > 1 &&
                  loggedInRole.accessLevel <= 1000 &&
                  <DropDown v={v} err={err}
                      id="groupId"
                      placeholder="Please select a Group"
                      required label="Group"
                      items={itemsGroups}
                      value={groupId}
                      disabled={isPosting || isFetching}
                      onSelect={onGroupSelect}
                      type="default"
              />}
              <Input v={v} err={err}
                     name="title"
                     id="title"
                     label="Title"
                     required
                     value={title}
                     onChange={setTitle}
                     disabled={isPosting || isFetching}
                     onBlur={setTitle}
                     placeholder="Insert a title"
              />
              <Input v={v} err={err}
                     name="description"
                     id="description"
                     label="Description"
                     required
                     value={description}
                     onChange={setDescription}
                     disabled={isPosting || isFetching}
                     onBlur={setDescription}
                     placeholder="Insert a description"
              />
              <Input v={v} err={err}
                     name="email"
                     id="email"
                     label="Email"
                     value={email}
                     required
                     onChange={setEmail}
                     disabled={isPosting || isFetching}
                     onBlur={setEmail}
                     placeholder="Insert an email"
              />
              <Input v={v} err={err}
                     name="phone"
                     id="phone"
                     label="Phone"
                     value={phone}
                     type="tel"
                     required
                     onChange={setPhone}
                     disabled={isPosting || isFetching}
                     onBlur={setPhone}
                     placeholder="Insert a phone number"
              />
              <Input v={v} err={err}
                     name="website"
                     id="website"
                     label="Website"
                     value={website}
                     required
                     onChange={setWebsite}
                     disabled={isPosting || isFetching}
                     onBlur={setWebsite}
                     placeholder="Insert a website"
              />
              <div className="row">
                  <div className="col-md-4">
                      <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                              onClick={() => onSubmit()}
                              title={'CREATE'}/>
                  </div>
              </div>
          </form>
      </>
  );

};

export default ClientForm;
