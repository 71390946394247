import { RootState } from '../store/configureStore';

export const getContacts = (state: RootState) => state?.contact?.contacts;

export const getActiveContact = (state: RootState) => state?.contact?.activeContact;

export const isFetchingContacts = (state: RootState) => state?.contact?.isFetchingContacts;

export const isPostingContact = (state: RootState) => state?.contact?.isPostingContact;
export const postingContactFailed = (state: RootState) => state?.contact?.postingContactFailed;
export const postingContactError = (state: RootState) => state?.contact?.postingContactError;
export const postingContactValidationErrors = (state: RootState) => state?.contact?.postingContactValidationErrors;
export const fetchingContactsFailed = (state: RootState) => state?.contact?.fetchingContactsFailed;