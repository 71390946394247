import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getPlans, isFetchingPlans } from '../../../selectors/Plan';
import { fetchPlans, storePlan } from '../../../actions/Plan';
import { Plan } from '../../../types/Plan';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const PlanDataTable = () => {

  const plans = useSelector(getPlans);
  const isLoading = useSelector(isFetchingPlans);
  const [toggle, setToggle] = useState(false);
  const [plan, setActivePlan] = useState<Plan | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.PLAN_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.PLAN_UPDATE, row.id));
  };

  const onArchive = (row: Plan) => {
    setActivePlan(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (plan?.id) {
      await storePlan(plan?.id);
      setRefetch(!refetch);
    }
    setActivePlan(null);
  };

  const config = {
    columns: [
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'monthlyFee', label: 'Monthly Fee', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
    ],
    hideButton: true,
  };

  return (
        <View title="Plans" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchPlans} data={plans?.data || []}
                       isLoading={isLoading} paging={plans?.paging || []} config={config} dependencies={refetch} />
            <Modal show={toggle} title="Archive Plan" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
                Are you sure you want to delete this plan?
            </Modal>
        </View>
  );
};

export default PlanDataTable;
