import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createMatch, fetchActiveMatch, fetchMatches, updateMatch } from '../../../actions/Match';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import { getSolutions } from '../../../selectors/Solution';
import { getEntries } from '../../../selectors/Entry';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import { fetchSolutions } from '../../../actions/Solution';
import { fetchEntries } from '../../../actions/Entry';

import {
  getActiveMatch,
  isPostingMatch,
  postingMatchError,
  postingMatchFailed,
  postingMatchValidationErrors,
  isFetchingMatches,
} from '../../../selectors/Match';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';


const MatchForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeMatch = useSelector(getActiveMatch);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const solutions = useSelector(getSolutions);
  const entries = useSelector(getEntries);
  const isPosting = useSelector(isPostingMatch);
  const isFetching = useSelector(isFetchingMatches);
  const postingFailed = useSelector(postingMatchFailed);
  const postingError = useSelector(postingMatchError);
  const postingValidationErrors = useSelector(postingMatchValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.MATCH_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'solutionId', validators: [isRequired] },
    { field: 'entryId', validators: [isRequired] },
    { field: 'status', validators: [isRequired] },
    { field: 'impact', validators: [isRequired] },
    { field: 'significance', validators: [isRequired] },
    { field: 'reuseHierarchy', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [solutionId, setSolutionId] = useState('');
  const [entryId, setEntryId] = useState('');
  const [status, setStatus] = useState('');
  const [impact, setImpact] = useState('');
  const [significance, setSignificance] = useState(0);
  const [reuseHierarchy, setReuseHierarchy] = useState(0);

  const [environmentalResponse, setEnvironmentalResponse] = useState('');
  const [financialResponse, setFinancialResponse] = useState('');
  const [socialResponse, setSocialResponse] = useState('');


  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchSolutions());
    dispatch(fetchEntries());

    dispatch(fetchMatches());
    if (editMode) {
      dispatch(fetchActiveMatch(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeMatch && editMode) {
      setGroupId(activeMatch?.groupId);
      setClientId(activeMatch?.clientId);
      setSolutionId(activeMatch?.solutionId);
      setEntryId(activeMatch?.entryId);
      setStatus(activeMatch?.status);
      setImpact(activeMatch?.impact);
      setSignificance(activeMatch?.significance);
      setReuseHierarchy(activeMatch?.reuseHierarchy);



      // @ts-ignore
      setActive(activeMatch?.active === 1 || activeMatch?.active === true);
    }
  }, [activeMatch]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.MATCH);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createMatch(groupId, clientId, solutionId, entryId, status, impact, significance, reuseHierarchy,
        environmentalResponse, financialResponse, socialResponse, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateMatch(activeId, groupId, clientId, solutionId, entryId, status, impact, significance, reuseHierarchy,
        environmentalResponse, financialResponse, socialResponse, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsClients = clients?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsSolutions = solutions?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsEntries = entries?.data.map(r => ({ label: r.title, value: r.id })) || [];
  
  const itemsImpact = [{ label: 'Low', value: 'Low' }, { label: 'Medium', value: 'Medium' }, { label: 'High', value: 'High' }];

  const itemsXY = [];
  for (let i = 0; i <= 100; i += 5) {
    itemsXY.push({ label: i.toString(), value: i.toString() });
  }


  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  const onSolutionSelect = (obj: any) => {
    setSolutionId(obj.value);
  };

  const onEntrySelect = (obj: any) => {
    setEntryId(obj.value);
  };

  const onImpactSelect = (obj: any) => {
    setImpact(obj.value);
  };

  const onSignificanceSelect = (obj: any) => {
    setSignificance(obj.value);
  };

  const onReuseHierarchySelect = (obj: any) => {
    setReuseHierarchy(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Match</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown v={v} err={err}
                          id="groupId"
                          placeholder="Please select a Group"
                          required label="Group"
                          items={itemsGroups}
                          value={groupId}
                          disabled={isPosting || isFetching}
                          onSelect={onGroupSelect}
                          type="default"
                />
              <DropDown v={v} err={err}
                        id="clientId"
                        placeholder="Please select a Client"
                        required label="Client"
                        items={itemsClients}
                        value={clientId}
                        disabled={isPosting || isFetching}
                        onSelect={onClientSelect}
                        type="default"
              />
              <DropDown v={v} err={err}
                        id="solutionId"
                        placeholder="Please select a Solution"
                        required label="Solution"
                        items={itemsSolutions}
                        value={solutionId}
                        disabled={isPosting || isFetching}
                        onSelect={onSolutionSelect}
                        type="default"
              />
              <DropDown v={v} err={err}
                        id="entryId"
                        placeholder="Please select a Entry"
                        required label="Entry"
                        items={itemsEntries}
                        value={entryId}
                        disabled={isPosting || isFetching}
                        onSelect={onEntrySelect}
                        type="default"
              />
                <Input v={v} err={err}
                       name="status"
                       id="status"
                       label="Status"
                       required
                       value={status}
                       onChange={setStatus}
                       disabled={isPosting || isFetching}
                       onBlur={setStatus}
                       placeholder="Insert a status"
                />
              <DropDown v={v} err={err}
                        id="impact"
                        placeholder="Please select an Impact level"
                        required label="Impact"
                        items={itemsImpact}
                        value={impact}
                        disabled={isPosting || isFetching}
                        onSelect={onImpactSelect}
                        type="default"
              />
              <DropDown v={v} err={err}
                        id="significance"
                        placeholder="Please select a value for Significance"
                        required label="Significance"
                        items={itemsXY}
                        value={significance}
                        disabled={isPosting || isFetching}
                        onSelect={onSignificanceSelect}
                        type="default"
              />
              <DropDown v={v} err={err}
                        id="reuseHierarchy"
                        placeholder="Please select a value for Reuse Hierarchy"
                        required label="Reuse Hierarchy"
                        items={itemsXY}
                        value={reuseHierarchy}
                        disabled={isPosting || isFetching}
                        onSelect={onReuseHierarchySelect}
                        type="default"
              />
              <Input v={v} err={err}
                     name="environmentalResponse"
                     id="environmentalResponse"
                     label="Environmental Response"
                     required
                     value={environmentalResponse}
                     onChange={setEnvironmentalResponse}
                     disabled={isPosting || isFetching}
                     onBlur={setEnvironmentalResponse}
                     placeholder="Insert an environmental response"
              />
              <Input v={v} err={err}
                     name="financialResponse"
                     id="financialResponse"
                     label="Financial Response"
                     required
                     value={financialResponse}
                     onChange={setFinancialResponse}
                     disabled={isPosting || isFetching}
                     onBlur={setFinancialResponse}
                     placeholder="Insert a financial response"
              />
              <Input v={v} err={err}
                     name="socialResponse"
                     id="socialResponse"
                     label="Social Response"
                     required
                     value={socialResponse}
                     onChange={setSocialResponse}
                     disabled={isPosting || isFetching}
                     onBlur={setSocialResponse}
                     placeholder="Insert a social response"
              />
                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default MatchForm;
