import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import {  useSelector } from 'react-redux';
import { getSubscriptions, isFetchingSubscriptions } from '../../../selectors/Subscription';
import { fetchSubscriptions, storeSubscription } from '../../../actions/Subscription';
import { Subscription } from '../../../types/Subscription';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';
import { formatUrl } from '../../../helpers/functions';


const SubscriptionDataTable = () => {
  const subscriptions = useSelector(getSubscriptions);
  const isLoading = useSelector(isFetchingSubscriptions);
  const [toggle, setToggle] = useState(false);
  const [subscription, setActiveSubscription] = useState<Subscription | null>(null);
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.SUBSCRIPTION_ADMIN_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl((c.APP_ROUTES.SUBSCRIPTION_ADMIN_UPDATE), row.id));
  };

  const onArchive = (row: Subscription) => {
    setActiveSubscription(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (subscription?.id) {
      await storeSubscription(subscription.id);
      setRefetch(!refetch);
    }
    setActiveSubscription(null);
  };

  const config = {
    columns: [
      { key: 'plan.title', label: 'Plan', mobileFriendly: true },
      { key: 'client.title', label: 'Client', mobileFriendly: true },
      { key: 'transaction.oppTransactionId', label: 'OPP Transaction ID', mobileFriendly: true },
      { key: 'startDate', label: 'Start Date', mobileFriendly: true },
      { key: 'endDate', label: 'End Date', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
    ],
    hideButton: true,
  };

  return (
        <View title="Subscriptions" onAddNew={onCreate}>
            <DataTable
                fetchFunction={fetchSubscriptions}
                data={subscriptions?.data || []}
                isLoading={isLoading}
                paging={subscriptions?.paging || []}
                config={config}
                dependencies={refetch}
            />
            <Modal show={toggle} title="Archive Subscription" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
                Are you sure you want to delete this subscription?
            </Modal>
        </View>
  );
};

export default SubscriptionDataTable;
