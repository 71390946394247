import {
  FETCH_STATUS_CODE_FAILURE,
  FETCH_STATUS_CODE_REQUEST,
  FETCH_STATUS_CODE_RESPONSE,
  FETCH_STATUS_CODES_FAILURE,
  FETCH_STATUS_CODES_REQUEST,
  FETCH_STATUS_CODES_RESPONSE,
  StatusCode,
  StatusCodeActionTypes,
  StatusCodeModel,
} from '../types/StatusCode';
import { DataTableParamsModel } from '../types/Common';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store/configureStore';
import { Action } from 'redux';
import { getStatusCode, getStatusCodes } from '../api/StatusCode';

export function fetchStatusCodesRequest(): StatusCodeActionTypes {
  return {
    type: FETCH_STATUS_CODES_REQUEST,
    payload: null,
  };
}

export function fetchStatusCodesResponse(
  statusCodes: StatusCodeModel,
): StatusCodeActionTypes {
  return {
    type: FETCH_STATUS_CODES_RESPONSE,
    payload: statusCodes,
  };
}

export function fetchStatusCodesFailure(): StatusCodeActionTypes {
  return {
    type: FETCH_STATUS_CODES_FAILURE,
    payload: null,
  };
}

export function fetchStatusCodeRequest(): StatusCodeActionTypes {
  return {
    type: FETCH_STATUS_CODE_REQUEST,
    payload: null,
  };
}

export function fetchStatusCodeResponse(
  statusCode: StatusCode,
): StatusCodeActionTypes {
  return {
    type: FETCH_STATUS_CODE_RESPONSE,
    payload: statusCode,
  };
}

export function fetchStatusCodeFailure(): StatusCodeActionTypes {
  return {
    type: FETCH_STATUS_CODE_FAILURE,
    payload: null,
  };
}

export const fetchActiveStatusCode = (id: string, dataTableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchStatusCodeRequest());
    const asyncResp: any = await getStatusCode(id, dataTableParams);
    if (asyncResp?.success) {
      dispatch(fetchStatusCodeResponse(asyncResp.data));
    } else {
      dispatch(fetchStatusCodeFailure());
    }
  };

export const fetchStatusCodes = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchStatusCodesRequest());
    const asyncResp: any = await getStatusCodes(datatableParams);
    if (asyncResp?.success) {
      dispatch(fetchStatusCodesResponse(asyncResp));
    } else {
      dispatch(fetchStatusCodesFailure());
    }
  };