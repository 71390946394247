import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import HeadlessView from '../../../components/HeadlessView';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../../actions/User';
import { isPostingUser, postingUserError, postingUserFailed, postingUserValidationErrors, isFetchingUsers } from '../../../selectors/User';

import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import CheckBox from '../../../components/CheckBox';
import { getActiveConfiguration } from '../../../selectors/Configuration';
import { formatUrl } from '../../../helpers/functions';
import useShortcodes from '../../../hooks/ShortcodeHook';
import LeadRegistrationForm from '../Lead/LeadRegistrationForm';

const UserForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingUser);
  const isFetching = useSelector(isFetchingUsers);
  const postingFailed = useSelector(postingUserFailed);
  const postingError = useSelector(postingUserError);
  const postingValidationErrors = useSelector(postingUserValidationErrors);
  const activeConfiguration = useSelector(getActiveConfiguration);
  const {
    groupShortcode,
    clientShortcode,
    isUsingShortcodes,
  } = useShortcodes(c.APP_ROUTES.USER_REGISTER_SHORTCODE, c.APP_ROUTES.USER_REGISTER);

  const formConfig = [
    { field: 'email', validators: [isRequired] },
    { field: 'password', validators: [isRequired] },
    { field: 'confirmPassword', validators: [isRequired] },
    { field: 'username', validators: [isRequired] },
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.USER_PENDING_VERIFICATION);
    }
  }, [isPosting]);

  useEffect(() => {
    if (password && confirmPassword && password === confirmPassword){
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [password, confirmPassword]);

  const onSubmit = () => {
    if (password === confirmPassword) {
      if (triggerValidation() && !groupShortcode || !clientShortcode) {
        setHasSubmitted(true);
        dispatch(registerUser('', email, password, username, firstName, lastName, false, false));
      } else if ((triggerValidation() && groupShortcode && clientShortcode)) {
        setHasSubmitted(true);
        dispatch(registerUser(activeConfiguration?.defaultRole || null, email, password, username, firstName, lastName, false, false));
      }
    }
  };

  const toLogin = () => {
    if ((groupShortcode && clientShortcode)) {
      history.push(formatUrl(c.APP_ROUTES.USER_LOGIN_SHORTCODE, groupShortcode, clientShortcode));
    } else {
      history.push(c.APP_ROUTES.USER_LOGIN);
    }
  };

  return (
      <>
        {isUsingShortcodes ? <HeadlessView title="Register to EXCESS MATERIALS Exchange" clientLogo={isUsingShortcodes ? activeConfiguration?.primaryLogo : undefined}>
          {postingError && (
              <Alert className="mt-3" variant="danger">
                {postingError}
              </Alert>
          )}
          <form>
            <Input
                v={v}
                err={err}
                name="email"
                id="email"
                label="Email"
                required
                value={email}
                onChange={setEmail}
                disabled={isPosting || isFetching}
                onBlur={setEmail}
                placeholder="Insert an email"
            />
            <Input
                v={v}
                err={err}
                required
                name="password"
                id="password"
                type="password"
                label="Password"
                value={password}
                disabled={isPosting || isFetching}
                onChange={setPassword}
                onBlur={setPassword}
                placeholder="Insert a password"
            />
            <Input
                v={v}
                err={err}
                name="confirmPassword"
                id="confirmPassword"
                label="Confirm Password"
                required
                type="password"
                value={confirmPassword}
                onChange={setConfirmPassword}
                disabled={isPosting || isFetching}
                onBlur={setConfirmPassword}
                placeholder=""
            />
            {(!passwordsMatch && password && confirmPassword) && (
                <Alert className="mt-3" variant="danger">
                  Passwords do not match
                </Alert>
            )}
            <Input
                v={v}
                err={err}
                required
                name="username"
                id="username"
                label="Username"
                value={username}
                disabled={isPosting || isFetching}
                onChange={setUsername}
                onBlur={setUsername}
                placeholder="Insert a username"
            />
            <Input
                v={v}
                err={err}
                required
                name="firstName"
                id="firstName"
                label="First Name"
                value={firstName}
                disabled={isPosting || isFetching}
                onChange={setFirstName}
                onBlur={setFirstName}
                placeholder="Insert a first name"
            />
            <Input
                v={v}
                err={err}
                required
                name="lastName"
                id="lastName"
                label="Last Name"
                value={lastName}
                disabled={isPosting || isFetching}
                onChange={setLastName}
                onBlur={setLastName}
                placeholder="Insert a last name"
            />
            <div className="my-2">
              <div className="d-flex flex-row gap-3">
                <CheckBox
                    type={'checkbox'}
                    checked={termsChecked}
                    onChange={() => setTermsChecked(!termsChecked)}
                />
                <div>
                  By using the EME platform you consent to our{' '}
                  <a target="_blank" href="/ExcessMaterialsExchange-Terms-Conditions.pdf">
                    Terms & Conditions
                  </a>
                  .
                </div>
              </div>
              <div className="d-flex flex-row gap-3">
                Already have an account?{' '}
                <div className="userform__link" onClick={toLogin}>
                  Login here
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-md-4">
                <Button
                    disabled={!isFormValid() || !termsChecked || !passwordsMatch}
                    isLoading={isPosting || isFetching}
                    onClick={onSubmit}
                    title={'CREATE'}
                />
              </div>
            </div>
          </form>
        </HeadlessView> :
          <LeadRegistrationForm/>}
      </>

  );
};

export default UserForm;
