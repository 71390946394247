import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLoggedInUser, getLoggedInRole } from '../../selectors/Auth';
import DropDown from '../../components/DropDown';
import './ClientSelector.scss';


interface ClientSelectorProps {

  onClientSelect: (onClientId: string) => void;
  onGroupSelect: (onGroupId: string) => void;
}

const ClientSelector : React.FC<ClientSelectorProps> =  ({ onClientSelect, onGroupSelect }) => {
  const loggedInUser = useSelector(getLoggedInUser);
  const loggedInRole = useSelector(getLoggedInRole);
  const [clientId, setClientId] = useState(loggedInUser?.clients ? loggedInUser.clients[0].clientId : '');
  const [groupId, setGroupId] = useState(loggedInUser?.groups ? loggedInUser.groups[0].groupId : '');
  const [itemsClients, setItemsClients] = useState(loggedInUser?.clients.map(r => ({ label: r.clientTitle, value: r.clientId })) || []);


  const itemsGroups = loggedInUser?.groups.map(group => ({
    label: group.groupTitle,
    value: group.groupId,
  })) || [];


  useEffect(()=>{
    if (loggedInUser && loggedInUser.clients) {
      setClientId(loggedInUser.clients[0].clientId);
      setGroupId(loggedInUser.groups[0].groupId);
      setItemsClients(loggedInUser?.clients?.filter(data => data.clientGroupId.includes(loggedInUser.groups[0].groupId)).map(r => ({ label: r.clientTitle, value: r.clientId })) || []);
      onClientSelect(loggedInUser.clients[0].clientId);
      onGroupSelect(loggedInUser.groups[0].groupId);
    }
  }, [loggedInUser?.clients[0].clientId]);

  const handleClientSelect = (selectedClientId : string) => {
    setClientId(selectedClientId);
    onClientSelect(selectedClientId);
  };


  const handleGroupSelect = (selectedGroupId : string) => {
    setGroupId(selectedGroupId);
    setItemsClients(loggedInUser?.clients?.filter(data => data.clientGroupId.includes(selectedGroupId)).map(r => ({ label: r.clientTitle, value: r.clientId })) || []);
    onGroupSelect(selectedGroupId);
  };

  return (
        <div>
            {loggedInUser && loggedInRole && loggedInUser?.clients.length > 1 && loggedInRole.accessLevel <= 1000 && (
                <div className="client-selector">
                  <div className="client-selector__label">Choose a Group and a Client</div>
                  {loggedInUser?.groups.length > 1 && <DropDown
                    id="groupId"
                    placeholder="Please select a Group"
                    required
                    label="Group"
                    items={itemsGroups}
                    value={groupId}
                    onSelect={(e)=>handleGroupSelect(e.value.toString())}
                    type="default"
                />}
                <DropDown
                    id="clientId"
                    placeholder="Please select a Client"
                    required
                    label="Client"
                    items={itemsClients}
                    value={clientId}
                    onSelect={(e)=>handleClientSelect(e.value.toString())}
                    type="default"
                />
                </div>
            )}
        </div>
  );
};

export default ClientSelector;
