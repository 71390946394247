import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createLead } from '../../../actions/Lead';
import {
  isPostingLead,
  postingLeadError,
  postingLeadFailed,
  postingLeadValidationErrors,
  isFetchingLeads,
} from '../../../selectors/Lead';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import HeadlessView from '../../../components/HeadlessView';
import './LeadRegistrationForm.scss';

const LeadForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingLead);
  const isFetching = useSelector(isFetchingLeads);
  const postingFailed = useSelector(postingLeadFailed);
  const postingError = useSelector(postingLeadError);
  const postingValidationErrors = useSelector(postingLeadValidationErrors);

  const formConfig = [
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
    { field: 'email', validators: [isRequired] },
    { field: 'phoneNumber', validators: [isRequired] },
    { field: 'companyName', validators: [isRequired] },
    { field: 'companyCommercialNumber', validators: [isRequired] },
    { field: 'companyPhone', validators: [isRequired] },
    { field: 'companyEmail', validators: [isRequired] },
    { field: 'companyWebsite', validators: [isRequired] },
    { field: 'roleInCompany', validators: [isRequired] },
    { field: 'city', validators: [isRequired] },
    { field: 'country', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyCommercialNumber, setCompanyCommercialNumber] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [roleInCompany, setRoleInCompany] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [termsAccepted] = useState(true);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.USER_PENDING_VERIFICATION);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createLead(firstName, lastName, email, phoneNumber, companyName, companyCommercialNumber, companyPhone, companyEmail, companyWebsite, roleInCompany,
        city, country, termsAccepted, undefined, undefined, undefined, undefined));
    }
  };


  return (
      <HeadlessView title="Register to EXCESS MATERIALS Exchange" marginTop={true}>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <div>
                {v}
                {err}
            </div>
            <form>
                <div className="row">
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="firstName"
                               id="firstName"
                               label="First Name"
                               required
                               value={firstName}
                               onChange={setFirstName}
                               disabled={isPosting || isFetching}
                               onBlur={setFirstName}
                        />
                    </div>
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="lastName"
                               id="lastName"
                               label="Last Name"
                               required
                               value={lastName}
                               onChange={setLastName}
                               disabled={isPosting || isFetching}
                               onBlur={setLastName}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="email"
                               id="email"
                               label="Email"
                               value={email}
                               onChange={setEmail}
                               disabled={isPosting || isFetching}
                               onBlur={setEmail}
                               required
                        />
                    </div>
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="phoneNumber"
                               id="phoneNumber"
                               label="Phone Number"
                               type="tel"
                               value={phoneNumber}
                               onChange={setPhoneNumber}
                               disabled={isPosting || isFetching}
                               onBlur={setPhoneNumber}
                               required
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="companyName"
                               id="companyName"
                               label="Company Name"
                               value={companyName}
                               onChange={setCompanyName}
                               disabled={isPosting || isFetching}
                               onBlur={setCompanyName}
                               required
                        />
                    </div>
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="companyCommercialNumber"
                               id="companyCommercialNumber"
                               label="Company Number"
                               type="number"
                               value={companyCommercialNumber}
                               onChange={setCompanyCommercialNumber}
                               disabled={isPosting || isFetching}
                               onBlur={setCompanyCommercialNumber}
                               placeholder="or CoC No."
                               required
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="companyPhone"
                               id="companyPhone"
                               label="Company Phone"
                               type="tel"
                               value={companyPhone}
                               onChange={setCompanyPhone}
                               disabled={isPosting || isFetching}
                               onBlur={setCompanyPhone}
                               required
                        />
                    </div>
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="companyEmail"
                               id="companyEmail"
                               label="Company Email"
                               value={companyEmail}
                               onChange={setCompanyEmail}
                               disabled={isPosting || isFetching}
                               onBlur={setCompanyEmail}
                               required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="companyWebsite"
                               id="companyWebsite"
                               label="Company Website"
                               value={companyWebsite}
                               onChange={setCompanyWebsite}
                               disabled={isPosting || isFetching}
                               onBlur={setCompanyWebsite}
                               required
                        />
                    </div>
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="roleInCompany"
                               id="roleInCompany"
                               label="Role In Company"
                               value={roleInCompany}
                               onChange={setRoleInCompany}
                               disabled={isPosting || isFetching}
                               onBlur={setRoleInCompany}
                               placeholder="Your role in the company"
                               required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="Country"
                               id="country"
                               label="Country"
                               value={country}
                               onChange={setCountry}
                               disabled={isPosting || isFetching}
                               onBlur={setCountry}
                               placeholder="Please type your country"
                               required
                        />
                    </div>
                    <div className="col-md-6">
                        <Input v={v} err={err}
                               name="City"
                               id="city"
                               label="City"
                               value={city}
                               onChange={setCity}
                               disabled={isPosting || isFetching}
                               onBlur={setCity}
                               placeholder="Please type your city"
                               required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <Button disabled={!isFormValid() || !termsAccepted} isLoading={isPosting || isFetching}
                                onClick={() => onSubmit()}
                                title={'Sign up'}/>
                    </div>
                </div>
            </form>
        </HeadlessView>
  );

};

export default LeadForm;
