import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createEntryTag, fetchActiveEntryTag, fetchEntryTags, updateEntryTag } from '../../../actions/EntryTag';
import { getGroups } from '../../../selectors/Group';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import { fetchEntries } from '../../../actions/Entry';
import { fetchTags } from '../../../actions/Tag';

import {
  getActiveEntryTag,
  isPostingEntryTag,
  postingEntryTagError,
  postingEntryTagFailed,
  postingEntryTagValidationErrors,
  isFetchingEntryTags,
} from '../../../selectors/EntryTag';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { getClients } from '../../../selectors/Client';
import { getEntries } from '../../../selectors/Entry';
import { getTags } from '../../../selectors/Tag';


const EntryTagForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeEntryTag = useSelector(getActiveEntryTag);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const entries = useSelector(getEntries);
  const tags = useSelector(getTags);

  const isPosting = useSelector(isPostingEntryTag);
  const isFetching = useSelector(isFetchingEntryTags);
  const postingFailed = useSelector(postingEntryTagFailed);
  const postingError = useSelector(postingEntryTagError);
  const postingValidationErrors = useSelector(postingEntryTagValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.ENTRY_TAG_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'entryId', validators: [isRequired] },
    { field: 'tagId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [entryId, setEntryId] = useState('');
  const [tagId, setTagId] = useState('');
  const [active, setActive] = useState(true);
  const [disabledClient, setDisabledClients] = useState(true);
  const [disabledEntries, setDisabledEntries] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [itemsEntries, setItemsEntries] = useState(entries?.data.map(r => ({ label: r.title, value: r.id })) || []);


  useEffect(() => {
    dispatch(fetchEntryTags());
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchEntries());
    dispatch(fetchTags());
    if (editMode) {
      dispatch(fetchActiveEntryTag(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeEntryTag && editMode) {
      setGroupId(activeEntryTag?.groupId);
      setClientId(activeEntryTag?.clientId);
      setEntryId(activeEntryTag?.entryId);
      setTagId(activeEntryTag?.tagId);
      // @ts-ignore
      setActive(activeEntryTag?.active === 1 || activeEntryTag?.active === true);
    }
  }, [activeEntryTag]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
    setItemsEntries(entries?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients, entries]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ENTRY_TAG);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);

      dispatch(createEntryTag(groupId, clientId, entryId, tagId, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateEntryTag(activeId, groupId, clientId, entryId, tagId, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsTags = tags?.data.map(r => ({ label: r.value, value: r.id })) || [];


  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };
  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
    setItemsEntries(entries?.data.filter(data => data.clientId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledEntries(false);
  };
  const onEntrySelect = (obj: any) => {
    setEntryId(obj.value);
  };
  const onTagSelect = (obj: any) => {
    setTagId(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} EntryTag</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown v={v} err={err}
                          id="groupId"
                          placeholder="Please select a Group"
                          required label="Group"
                          items={itemsGroups}
                          value={groupId}
                          disabled={isPosting || isFetching}
                          onSelect={onGroupSelect}
                          type="default"
                />
              <DropDown v={v} err={err}
                        id="clientId"
                        placeholder="Please select a Client"
                        required label="Client"
                        items={itemsClients}
                        value={clientId}
                        disabled={isPosting || isFetching || disabledClient}
                        onSelect={onClientSelect}
                        type="default"
              />
              <DropDown v={v} err={err}
                        id="entryId"
                        placeholder="Please select an Entry"
                        required label="Entry"
                        items={itemsEntries}
                        value={entryId}
                        disabled={isPosting || isFetching || disabledEntries}
                        onSelect={onEntrySelect}
                        type="default"
              />
              <DropDown v={v} err={err}
                        id="tagId"
                        placeholder="Please select a Tag"
                        required label="Tag"
                        items={itemsTags}
                        value={tagId}
                        disabled={isPosting || isFetching}
                        onSelect={onTagSelect}
                        type="default"
              />

                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default EntryTagForm;
