import { ApiDataModel } from './Common';

export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_RESPONSE = 'FETCH_REPORT_RESPONSE';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';

export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_RESPONSE = 'FETCH_REPORTS_RESPONSE';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';

export interface Report {
  material: string;
  total_CO2: string;
}

export interface ReportItemDataModel extends Omit<ApiDataModel, 'data'> {
  data: Report[];
}

export interface ReportItemState {
  reports: ReportItemDataModel | null,
  isFetchingReports: boolean;
  fetchingReportsFailed: boolean;
}

interface FetchReportsResponseAction {
  type: typeof FETCH_REPORTS_RESPONSE;
  payload: ReportItemDataModel;
}

interface FetchReportsFailureAction {
  type: typeof FETCH_REPORTS_FAILURE;
  payload: null;
}

interface FetchReportsRequestAction {
  type: typeof FETCH_REPORTS_REQUEST;
  payload: null;
}

interface FetchReportRequestAction {
  type: typeof FETCH_REPORT_REQUEST;
  payload: null;
}

interface FetchReportResponseAction {
  type: typeof FETCH_REPORT_RESPONSE;
  payload: Report;
}

interface FetchReportFailureAction {
  type: typeof FETCH_REPORT_FAILURE;
  payload: null;
}

export type ReportActionTypes =
    | FetchReportsResponseAction
    | FetchReportsRequestAction
    | FetchReportsFailureAction
    | FetchReportRequestAction
    | FetchReportResponseAction
    | FetchReportFailureAction;