import {
  FETCH_STATUS_CODES_FAILURE,
  FETCH_STATUS_CODES_REQUEST,
  FETCH_STATUS_CODES_RESPONSE,
  FETCH_STATUS_CODE_FAILURE,
  FETCH_STATUS_CODE_REQUEST,
  FETCH_STATUS_CODE_RESPONSE,
  StatusCodeActionTypes,
  StatusCodeState,
} from '../types/StatusCode';

const initialState: StatusCodeState = {
  statusCodes: null,
  activeStatusCode: null,
  isFetchingStatusCodes: false,
  fetchingStatusCodesFailed: false,
  isPostingStatusCode: false,
  postingStatusCodeFailed: false,
  postingStatusCodeError: null,
  postingStatusCodeValidationErrors: null,  
};

const statusCodesReducer = (
  state = initialState,
  action: StatusCodeActionTypes,
): StatusCodeState => {
  switch (action.type) {
    case FETCH_STATUS_CODES_REQUEST:
      return {
        ...state,
        statusCodes: null,
        isFetchingStatusCodes: true,
        fetchingStatusCodesFailed: false,
      };
    case FETCH_STATUS_CODES_RESPONSE: 
      return {
        ...state,
        statusCodes: action.payload || [],
        isFetchingStatusCodes: false,
        fetchingStatusCodesFailed: false,  
      };
    case FETCH_STATUS_CODES_FAILURE: 
      return {
        ...state,
        statusCodes: null,
        isFetchingStatusCodes: false,
        fetchingStatusCodesFailed: true,
      };
    case FETCH_STATUS_CODE_REQUEST:
      return {
        ...state,
        statusCodes: null,
        isFetchingStatusCodes: false,
        fetchingStatusCodesFailed: false,
      };
    case FETCH_STATUS_CODE_RESPONSE:
      return {
        ...state,
        activeStatusCode: action.payload,
        isFetchingStatusCodes: false,
        fetchingStatusCodesFailed: false,
      };
    case FETCH_STATUS_CODE_FAILURE:
      return {
        ...state,
        statusCodes: null,
        isFetchingStatusCodes: false,
        fetchingStatusCodesFailed: true,
      };
    default:
      return state;
  }
};

export default statusCodesReducer;