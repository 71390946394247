import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createEntry, fetchActiveEntry, updateEntry } from '../../../actions/Entry';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import {
  getActiveEntry,
  isPostingEntry,
  postingEntryError,
  postingEntryFailed,
  postingEntryValidationErrors,
  isFetchingEntries,
} from '../../../selectors/Entry';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { fetchClients } from '../../../actions/Client';
import { getForms } from '../../../selectors/Form';
import { fetchForms } from '../../../actions/Form';
import { getAssets } from '../../../selectors/Asset';

const EntryForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeEntry = useSelector(getActiveEntry);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const forms = useSelector(getForms);
  const assets = useSelector(getAssets);
  const isPosting = useSelector(isPostingEntry);
  const isFetching = useSelector(isFetchingEntries);
  const postingFailed = useSelector(postingEntryFailed);
  const postingError = useSelector(postingEntryError);
  const postingValidationErrors = useSelector(postingEntryValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.ENTRY_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'formId', validators: [isRequired] },
    { field: 'assetId', validators: [] },
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [formId, setFormId] = useState('');
  const [assetId, setAssetId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchForms());
    if (editMode) {
      dispatch(fetchActiveEntry(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeEntry && editMode) {
      setGroupId(activeEntry?.groupId);
      setClientId(activeEntry?.clientId);
      setFormId(activeEntry?.formId);
      setAssetId(activeEntry?.assetId);
      setTitle(activeEntry?.title);
      setDescription(activeEntry?.description);
      // @ts-ignore
      setActive(activeEntry?.active === 1 || activeEntry?.active === true);
    }
  }, [activeEntry]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ENTRY);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createEntry(groupId, clientId, formId, assetId, title, description, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateEntry(activeId, groupId, clientId, formId, assetId, title, description, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsForms = forms?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsAssets = assets?.data.map(r => ({ label: r.title || r.path, value: r.id })) || [];


  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  const onFormSelect = (obj: any) => {
    setFormId(obj.value);
  };

  const onAssetSelect = (obj: any) => {
    setAssetId(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Entry</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown v={v} err={err}
                          id="groupId"
                          placeholder="Please select a Group"
                          required label="Group"
                          items={itemsGroups}
                          value={groupId}
                          disabled={isPosting || isFetching}
                          onSelect={onGroupSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="clientId"
                          placeholder="Please select a Client"
                          required
                          label="Client"
                          items={itemsClients}
                          disabled={isPosting || isFetching || disabledClient}
                          value={clientId}
                          onSelect={onClientSelect}
                          type="default"
                />
              <DropDown v={v} err={err}
                        id="formId"
                        placeholder="Please select a Form"
                        required
                        label="Form"
                        items={itemsForms}
                        disabled={isPosting || isFetching}
                        value={formId}
                        onSelect={onFormSelect}
                        type="default"
              />
              <DropDown v={v} err={err}
                        id="assetId"
                        placeholder="Please select an Asset"
                        label="Asset"
                        items={itemsAssets}
                        value={assetId}
                        disabled={isPosting || isFetching}
                        onSelect={onAssetSelect}
                        type="default"
              />
                <Input v={v} err={err}
                       name="title"
                       id="title"
                       label="Title"
                       required
                       value={title}
                       onChange={setTitle}
                       disabled={isPosting || isFetching}
                       onBlur={setTitle}
                       placeholder="Insert a title"
                />
                <Input v={v} err={err}
                       required
                       name="description"
                       id="description"
                       label="Description"
                       value={description}
                       disabled={isPosting || isFetching}
                       onChange={setDescription}
                       onBlur={setDescription}
                       placeholder="Insert a description"
                />
                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default EntryForm;
