import { ApiDataModel, FormError } from './Common';
import { EntryStatus } from '../helpers/constants';

export const FETCH_ENTRIES_REQUEST = 'FETCH_ENTRIES_REQUEST';
export const FETCH_ENTRIES_RESPONSE = 'FETCH_ENTRIES_RESPONSE';
export const FETCH_ENTRIES_FAILURE = 'FETCH_ENTRIES_FAILURE';

export const FETCH_RECENTLY_ADDED_ENTRIES_REQUEST = 'FETCH_RECENTLY_ADDED_ENTRIES_REQUEST';
export const FETCH_RECENTLY_ADDED_ENTRIES_RESPONSE = 'FETCH_RECENTLY_ADDED_ENTRIES_RESPONSE';
export const FETCH_RECENTLY_ADDED_ENTRIES_FAILURE = 'FETCH_RECENTLY_ADDED_ENTRIES_FAILURE';

export const FETCH_RECENTLY_REUSED_ENTRIES_REQUEST = 'FETCH_RECENTLY_REUSED_ENTRIES_REQUEST';
export const FETCH_RECENTLY_REUSED_ENTRIES_RESPONSE = 'FETCH_RECENTLY_REUSED_ENTRIES_RESPONSE';
export const FETCH_RECENTLY_REUSED_ENTRIES_FAILURE = 'FETCH_RECENTLY_REUSED_ENTRIES_FAILURE';

export const FETCH_POPULAR_ENTRIES_REQUEST = 'FETCH_POPULAR_ENTRIES_REQUEST';
export const FETCH_POPULAR_ENTRIES_RESPONSE = 'FETCH_POPULAR_ENTRIES_RESPONSE';
export const FETCH_POPULAR_ENTRIES_FAILURE = 'FETCH_POPULAR_ENTRIES_FAILURE';

export const FETCH_ENTRY_REQUEST = 'FETCH_ENTRY_REQUEST';
export const FETCH_ENTRY_RESPONSE = 'FETCH_ENTRY_RESPONSE';
export const FETCH_ENTRY_MATCHES_RESPONSE = 'FETCH_ENTRY_MATCHES_RESPONSE';
export const FETCH_ENTRY_FAILURE = 'FETCH_ENTRY_FAILURE';

export const POST_ENTRY_REQUEST = 'POST_ENTRY_REQUEST';
export const POST_ENTRY_RESPONSE = 'POST_ENTRY_RESPONSE';
export const POST_ENTRY_FAILURE = 'POST_ENTRY_FAILURE';

export const FETCH_REUSE_ENTRY_RESPONSE = 'FETCH_REUSE_ENTRY_RESPONSE';

export const CLEAR_ENTRIES = 'CLEAR_ENTRIES';


export interface Entry {
  id: string;
  groupId: string;
  groupShortcode: string;
  clientId: string;
  clientShortcode: string;
  formId: string;
  assetId: string;
  viewId: string;
  title: string;
  description: string;
  active: boolean;
  entryLocation: string;
  status: EntryStatus;
  viewTitle: string;
  quantity: number;
  minRate: number;
  maxRate: number;
  created: Date;
  createdBy: string;
  updated: Date;
  updatedBy: string;
  archived: Date;
  archivedBy: string;
}

export interface EntryMatch {
  id: string;
  solutionId: string;
  solutionTitle: string;
  solutionDescription: string;
  tagId: string;
  tagValue: string;
  entryTitle: string;
}

export interface EntryDataModel extends Omit<ApiDataModel, 'data'> {
  data: Entry[];
}

export interface EntryMatchDataModel extends Omit<ApiDataModel, 'data'> {
  data: EntryMatch[];
}

export interface EntryState {
  entries: EntryDataModel | null,
  activeEntry: Entry | null,
  recentlyAddedEntries: EntryDataModel | null,
  recentlyReusedEntries: EntryDataModel | null,
  popularEntries: EntryDataModel | null,
  matches: EntryMatchDataModel | null,
  isFetchingEntries: boolean;
  isFetchingRecentlyAddedEntries: boolean;
  isFetchingRecentlyReusedEntries: boolean;
  isFetchingPopularEntries: boolean;
  fetchingEntriesFailed: boolean;
  postingEntryFailed?: boolean;
  isPostingEntry: boolean;
  postingEntryValidationErrors: FormError[] | null;
  postingEntryError: string | null;
}

interface FetchEntriesRequestAction {
  type: typeof FETCH_ENTRIES_REQUEST;
  payload: null;
}

interface FetchEntriesResponseAction {
  type: typeof FETCH_ENTRIES_RESPONSE;
  payload: EntryDataModel;
}

interface FetchEntriesFailureAction {
  type: typeof FETCH_ENTRIES_FAILURE;
  payload: null;
}

interface FetchRecentlyAddedEntriesRequestAction {
  type: typeof FETCH_RECENTLY_ADDED_ENTRIES_REQUEST;
  payload: null;
}

interface FetchRecentlyAddedEntriesResponseAction {
  type: typeof FETCH_RECENTLY_ADDED_ENTRIES_RESPONSE;
  payload: EntryDataModel;
}

interface FetchRecentlyAddedEntriesFailureAction {
  type: typeof FETCH_RECENTLY_ADDED_ENTRIES_FAILURE;
  payload: null;
}

interface FetchRecentlyReusedEntriesRequestAction {
  type: typeof FETCH_RECENTLY_REUSED_ENTRIES_REQUEST;
  payload: null;
}

interface FetchRecentlyReusedEntriesResponseAction {
  type: typeof FETCH_RECENTLY_REUSED_ENTRIES_RESPONSE;
  payload: EntryDataModel;
}

interface FetchRecentlyReusedEntriesFailureAction {
  type: typeof FETCH_RECENTLY_REUSED_ENTRIES_FAILURE;
  payload: null;
}

interface FetchPopularEntriesRequestAction {
  type: typeof FETCH_POPULAR_ENTRIES_REQUEST;
  payload: null;
}

interface FetchPopularEntriesResponseAction {
  type: typeof FETCH_POPULAR_ENTRIES_RESPONSE;
  payload: EntryDataModel;
}

interface FetchPopularEntriesFailureAction {
  type: typeof FETCH_POPULAR_ENTRIES_FAILURE;
  payload: null;
}

interface FetchEntryRequestAction {
  type: typeof FETCH_ENTRY_REQUEST;
  payload: null;
}

interface FetchEntryResponseAction {
  type: typeof FETCH_ENTRY_RESPONSE;
  payload: Entry;
}

interface FetchEntryMatchesAction {
  type: typeof FETCH_ENTRY_MATCHES_RESPONSE;
  payload: EntryMatchDataModel;
}

interface FetchReuseEntryAction {
  type: typeof FETCH_REUSE_ENTRY_RESPONSE;
  payload: EntryDataModel;
}

interface FetchEntryFailureAction {
  type: typeof FETCH_ENTRY_FAILURE;
  payload: null;
}

interface PostEntryRequestAction {
  type: typeof POST_ENTRY_REQUEST;
  payload: null;
}

interface PostEntryResponseAction {
  type: typeof POST_ENTRY_RESPONSE;
  payload: null;
}

interface PostEntryFailureAction {
  type: typeof POST_ENTRY_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

interface ClearEntryAction {
  type: typeof CLEAR_ENTRIES;
  payload: null;
}

export type EntryActionTypes =
    | FetchEntriesRequestAction
    | FetchEntriesResponseAction
    | FetchEntriesFailureAction
    | FetchRecentlyAddedEntriesRequestAction
    | FetchRecentlyAddedEntriesResponseAction
    | FetchRecentlyAddedEntriesFailureAction
    | FetchRecentlyReusedEntriesRequestAction
    | FetchRecentlyReusedEntriesResponseAction
    | FetchRecentlyReusedEntriesFailureAction
    | FetchPopularEntriesRequestAction
    | FetchPopularEntriesResponseAction
    | FetchPopularEntriesFailureAction
    | PostEntryRequestAction
    | PostEntryResponseAction
    | PostEntryFailureAction
    | FetchEntryRequestAction
    | FetchEntryResponseAction
    | FetchEntryFailureAction
    | FetchEntryMatchesAction
    | FetchReuseEntryAction
    | ClearEntryAction;
