import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/formRenderer.scss';
import c, { StatusCodesEnum } from '../../helpers/constants';

import { getActiveOffer, isPostingOffer, postingOfferFailed } from '../../selectors/Offer';

import { fetchActiveOffer } from '../../actions/Offer';

import { getActiveOfferItem, isPostingOfferItem, postingOfferItemFailed } from '../../selectors/OfferItem';
import HeadlessView from '../../components/HeadlessView';
import CheckBox from '../../components/CheckBox';
import { useEditMode } from '../../hooks/EditModeHook';
import { fetchActiveOfferItem } from '../../actions/OfferItem';
import Button from '../../components/Button';
import TickMark from '../../assets/images/svgs/tick_mark.svg';
import { updateOfferItemStatusCodeByOfferItemId } from '../../actions/OfferItemStatusCode';
import { isPostingOfferItemStatusCode, postingOfferItemStatusCodeFailed } from '../../selectors/OfferItemStatusCode';

function CreateOffer() {
  const dispatch = useDispatch();
  const [isExact, activeId] = useEditMode(c.APP_ROUTES.SHIPMENT_JOURNEY);

  const activeOfferItem = useSelector(getActiveOfferItem);
  const activeOffer = useSelector(getActiveOffer);

  const isPosting = useSelector(isPostingOffer) || useSelector(isPostingOfferItem) || useSelector(isPostingOfferItemStatusCode);
  const postingFailed = useSelector(postingOfferItemFailed) || useSelector(postingOfferFailed) || useSelector(postingOfferItemStatusCodeFailed);
  const [toggle, setToggle] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isInTransit, setIsInTransit] = useState(false);
  const [isDelivered, setIsDelivered] = useState(false);

  const latestOfferItemStatusCode = activeOfferItem?.statuses[0];
  const latestOfferStatusCode = activeOffer?.statusCodes[0];

  const shipmentStatuses = [StatusCodesEnum.READY_TO_SHIP, StatusCodesEnum.IN_TRANSIT];


  useEffect(() => {
    if (isExact) {
      dispatch(fetchActiveOfferItem(activeId));
    }
  }, [dispatch]);
  
  useEffect(() => {
    if (activeOfferItem && activeOfferItem.offerId){
      dispatch(fetchActiveOffer(activeOfferItem.offerId));
    }
  }, [activeOfferItem]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      if (latestOfferItemStatusCode === 'Preparing For Shipment'){
        setIsInTransit(true);
      } else if (latestOfferItemStatusCode === 'In Transit'){
        setIsDelivered(true);
      }
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (isExact) {
      if (latestOfferStatusCode && shipmentStatuses.includes(latestOfferStatusCode?.value) && activeOffer?.id){
        let nextStatus = '';
        if (latestOfferItemStatusCode === 'Preparing For Shipment') {
          nextStatus = 'In Transit';
        } else if (latestOfferItemStatusCode === 'In Transit') {
          nextStatus = 'Delivered';
        }

        if (nextStatus) {
          dispatch(updateOfferItemStatusCodeByOfferItemId(activeId, nextStatus, ''));
          setHasSubmitted(true);
        }      
      }
    }
  };


  return (
      <>
        {latestOfferItemStatusCode === 'Preparing For Shipment' && <HeadlessView title={'TRANSIT CONFIRMATION'}>
          <div className="shipment__container">
            <div>Please toggle the button and submit the form to signify that the QR code you just scanned will go into
              transit.
            </div>
            <CheckBox checked={toggle} onChange={setToggle}/>
            <Button title="Submit" disabled={!toggle} onClick={onSubmit}/>
          </div>
        </HeadlessView>
        }

        {isInTransit && <HeadlessView title={'TRANSIT CONFIRMATION'}>
            <div className="row p-4 justify-content-center align-items-center">
          <div className="row  justify-content-center align-items-center auction-wizard__box">
            <div className="row justify-content-center align-items-center  p-md-5 p-sm-0">
              <div className="row mt-5 justify-content-center align-items-center">
                <div className="col-12 text-center"><img className="auction-wizard__circle" src={TickMark}
                                                         alt="checkmark"/></div>
              </div>
              <div className="row my-1">
                <div className="col-12  auction-wizard__success-title text-center">Transit Initiated</div>
              </div>
              <div className="row my-1">
                <div className="col-12  text-center">Item is now in transit. Safe journey!</div>
              </div>
            </div>
          </div>
        </div>
        </HeadlessView>}

        {latestOfferItemStatusCode === 'In Transit' && <HeadlessView title={'DELIVERY CONFIRMATION'}>
          <div className="shipment__container">
            <div>To proceed, please toggle the button and submit the form to signify that the delivery has been completed.
            </div>
            <CheckBox checked={toggle} onChange={setToggle}/>
            <Button title="Submit" disabled={!toggle} onClick={onSubmit}/>
          </div>
        </HeadlessView>
        }

        {isDelivered && <HeadlessView title={'ITEM DELIVERED'}>
          <div className="row p-4 justify-content-center align-items-center">
            <div className="row  justify-content-center align-items-center auction-wizard__box">
              <div className="row justify-content-center align-items-center  p-md-5 p-sm-0">
                <div className="row mt-5 justify-content-center align-items-center">
                  <div className="col-12 text-center"><img className="auction-wizard__circle" src={TickMark}
                                                           alt="checkmark"/></div>
                </div>
                <div className="row my-1">
                  <div className="col-12  auction-wizard__success-title text-center">Delivery Completed</div>
                </div>
                <div className="row my-1">
                  <div className="col-12  text-center">This item is now marked as delivered.</div>
                </div>
              </div>
            </div>
          </div>
        </HeadlessView>}
      </>


  );
}


export default CreateOffer;
