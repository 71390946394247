import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createMaterialsFunction, fetchActiveMaterialsFunction, fetchMaterialsFunctions, updateMaterialsFunction } from '../../../actions/MaterialsFunction';
import CheckBox from '../../../components/CheckBox';
import {
  getActiveMaterialsFunction,
  isPostingMaterialsFunction,
  postingMaterialsFunctionError,
  postingMaterialsFunctionFailed,
  postingMaterialsFunctionValidationErrors,
  isFetchingMaterialsFunctions,
} from '../../../selectors/MaterialsFunction';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';


const MaterialsFunctionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeMaterialsFunction = useSelector(getActiveMaterialsFunction);
  const isPosting = useSelector(isPostingMaterialsFunction);
  const isFetching = useSelector(isFetchingMaterialsFunctions);
  const postingFailed = useSelector(postingMaterialsFunctionFailed);
  const postingError = useSelector(postingMaterialsFunctionError);
  const postingValidationErrors = useSelector(postingMaterialsFunctionValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.MATERIALSFUNCTION_UPDATE);

  const formConfig = [
    { field: 'value', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [value, setValue] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(fetchMaterialsFunctions());
    if (editMode) {
      dispatch(fetchActiveMaterialsFunction(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeMaterialsFunction && editMode) {
      setValue(activeMaterialsFunction?.value);
      // @ts-ignore
      setActive(activeMaterialsFunction?.active === 1 || activeMaterialsFunction?.active === true);
    }
  }, [activeMaterialsFunction]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.MATERIALSFUNCTION);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);

      dispatch(createMaterialsFunction(value, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateMaterialsFunction(activeId, value, active));
    }
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} MaterialsFunction</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <Input v={v} err={err}
                       name="value"
                       id="value"
                       label="Value"
                       required
                       value={value}
                       onChange={setValue}
                       disabled={isPosting || isFetching}
                       onBlur={setValue}
                       placeholder="Insert a value"
                />

                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default MaterialsFunctionForm;
