import { RootState } from '../store/configureStore';

export const getOfferItemStatusCodes = (state: RootState) => state?.offerItemStatusCode?.offerItemStatusCodes;

export const getActiveOfferItemStatusCode = (state: RootState) => state?.offerItemStatusCode?.activeOfferItemStatusCode;

export const isFetchingOfferItemStatusCodes = (state: RootState) => state?.offerItemStatusCode?.isFetchingOfferItemStatusCodes;

export const isPostingOfferItemStatusCode = (state: RootState) => state?.offerItemStatusCode?.isPostingOfferItemStatusCode;
export const postingOfferItemStatusCodeFailed = (state: RootState) => state?.offerItemStatusCode?.postingOfferItemStatusCodeFailed;
export const postingOfferItemStatusCodeError = (state: RootState) => state?.offerItemStatusCode?.postingOfferItemStatusCodeError;
export const postingOfferItemStatusCodeValidationErrors = (state: RootState) => state?.offerItemStatusCode?.postingOfferItemStatusCodeValidationErrors;
export const fetchingOfferItemStatusCodesFailed = (state: RootState) => state?.offerItemStatusCode?.fetchingOfferItemStatusCodesFailed;