import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createOfferItem, fetchActiveOfferItem, updateOfferItem } from '../../../actions/OfferItem';
import {
  getActiveOfferItem,
  isPostingOfferItem,
  postingOfferItemError,
  postingOfferItemFailed,
  postingOfferItemValidationErrors,
  isFetchingOfferItems,
} from '../../../selectors/OfferItem';
import { fetchOffers } from '../../../actions/Offer';
import { fetchEntries } from '../../../actions/Entry';
import { getOffers } from '../../../selectors/Offer';
import { getEntries } from '../../../selectors/Entry';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { Alert } from 'react-bootstrap';
import TextArea from '../../../components/TextArea';

const OfferItemForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeOfferItem = useSelector(getActiveOfferItem);
  const isPosting = useSelector(isPostingOfferItem);
  const isFetching = useSelector(isFetchingOfferItems);
  const postingFailed = useSelector(postingOfferItemFailed);
  const postingError = useSelector(postingOfferItemError);
  const postingValidationErrors = useSelector(postingOfferItemValidationErrors);

  const offers = useSelector(getOffers);
  const entries = useSelector(getEntries);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.OFFER_ITEM_UPDATE);

  const [offerId, setOfferId] = useState('');
  const [entryId, setEntryId] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [minRate, setMinRate] = useState(0);
  const [maxRate, setMaxRate] = useState(0);
  const [actualRate, setActualRate] = useState(0);
  const [details, setDetails] = useState('');

  const formConfig = [
    { field: 'offerId', validators: [isRequired] },
    { field: 'entryId', validators: [isRequired] },
    { field: 'quantity', validators: [isRequired] },
    { field: 'minRate', validators: [isRequired] },
    { field: 'maxRate', validators: [isRequired] },
    { field: 'actualRate', validators: [] },
    { field: 'details', validators: [] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    dispatch(fetchOffers());
    dispatch(fetchEntries());
    if (editMode) {
      dispatch(fetchActiveOfferItem(activeId));
    }
  }, [dispatch, editMode, activeId]);

  useEffect(() => {
    if (activeOfferItem && editMode) {
      setOfferId(activeOfferItem.offerId);
      setEntryId(activeOfferItem.entryId);
      setQuantity(activeOfferItem.quantity);
      setMinRate(activeOfferItem.minRate);
      setMaxRate(activeOfferItem.maxRate);
      setActualRate(activeOfferItem.actualRate);
      if (activeOfferItem.details){
        setDetails(activeOfferItem.details);
      }
    }
  }, [activeOfferItem, editMode]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.OFFER_ITEM);
    }
  }, [isPosting, postingFailed, hasSubmitted, history]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createOfferItem(offerId, entryId, quantity, minRate, maxRate, actualRate, details));
    }
  };

  const onSave = async () => {
    setHasSubmitted(true);
    dispatch(updateOfferItem(activeId, offerId, entryId, quantity, minRate, maxRate, actualRate, details));
  };

  const itemsOffers = offers?.data.map(r => ({ label: r.subject, value: r.id })) || [];
  const itemsEntries = entries?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onOfferSelect = (obj: any) => {
    setOfferId(obj.value);
  };

  const onEntrySelect = (obj: any) => {
    setEntryId(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Offer Item</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown
                    v={v}
                    err={err}
                    name="offerId"
                    id="offerId"
                    label="Offer"
                    required
                    items={itemsOffers}
                    value={offerId}
                    onSelect={onOfferSelect}
                    disabled={isPosting || isFetching}
                />
                <DropDown
                    v={v}
                    err={err}
                    name="entryId"
                    id="entryId"
                    label="Entry"
                    required
                    items={itemsEntries}
                    value={entryId}
                    onSelect={onEntrySelect}
                    disabled={isPosting || isFetching}
                />
                <Input
                    v={v}
                    err={err}
                    name="quantity"
                    id="quantity"
                    label="Quantity"
                    type="number"
                    required
                    value={quantity}
                    onChange={setQuantity}
                    disabled={isPosting || isFetching}
                    onBlur={setQuantity}
                    placeholder="Insert quantity"
                />
                <Input
                    v={v}
                    err={err}
                    name="minRate"
                    id="minRate"
                    label="Min Rate"
                    type="number"
                    required
                    value={minRate}
                    onChange={setMinRate}
                    disabled={isPosting || isFetching}
                    onBlur={setMinRate}
                    placeholder="Insert min rate"
                />
                <Input
                    v={v}
                    err={err}
                    name="maxRate"
                    id="maxRate"
                    label="Max Rate"
                    type="number"
                    required
                    value={maxRate}
                    onChange={setMaxRate}
                    disabled={isPosting || isFetching}
                    onBlur={setMaxRate}
                    placeholder="Insert max rate"
                />
                <Input
                    v={v}
                    err={err}
                    name="actualRate"
                    id="actualRate"
                    label="Actual Rate"
                    type="number"
                    value={actualRate}
                    onChange={setActualRate}
                    disabled={isPosting || isFetching}
                    onBlur={setActualRate}
                    placeholder="Insert actual rate"
                />
                <TextArea
                    v={v}
                    err={err}
                    name="details"
                    id="details"
                    label="Details"
                    value={details}
                    onChange={setDetails}
                    disabled={isPosting || isFetching}
                    onBlur={setDetails}
                    placeholder="Insert details"
                />
                <div className="row">
                    <div className="col-md-4">
                        <Button
                            disabled={!isFormValid()}
                            isLoading={isPosting || isFetching}
                            onClick={() => (editMode ? onSave() : onSubmit())}
                            title={editMode ? 'SAVE' : 'CREATE'}
                        />
                    </div>
                </div>
            </form>
        </View>
  );
};

export default OfferItemForm;
