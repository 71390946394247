import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createComposition, fetchActiveComposition, updateComposition } from '../../../actions/Composition';
import {
  getActiveComposition,
  isPostingComposition,
  postingCompositionError,
  postingCompositionFailed,
  postingCompositionValidationErrors,
  isFetchingCompositions,
} from '../../../selectors/Composition';
import CheckBox from '../../../components/CheckBox';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { Alert } from 'react-bootstrap';

const CompositionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeComposition = useSelector(getActiveComposition);
  const isPosting = useSelector(isPostingComposition);
  const isFetching = useSelector(isFetchingCompositions);
  const postingFailed = useSelector(postingCompositionFailed);
  const postingError = useSelector(postingCompositionError);
  const postingValidationErrors = useSelector(postingCompositionValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.COMPOSITION_UPDATE);

  const formConfig = [
    { field: 'material', validators: [isRequired] },
    { field: 'variation', validators: [isRequired] },
    { field: 'CO2', validators: [isRequired] },
    { field: 'unitOfMeasure', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [material, setMaterial] = useState('');
  const [variation, setVariation] = useState('');
  const [CO2, setCO2] = useState('');
  const [unitOfMeasure, setUnitOfMeasure] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (editMode) {
      dispatch(fetchActiveComposition(activeId));
    }
  }, [dispatch, editMode, activeId]);

  useEffect(() => {
    if (activeComposition && editMode) {
      setMaterial(activeComposition.material);
      setVariation(activeComposition.variation);
      setCO2(activeComposition.CO2.toString());
      setUnitOfMeasure(activeComposition.unitOfMeasure);
    }
  }, [activeComposition, editMode]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.COMPOSITION);
    }
  }, [isPosting, postingFailed, hasSubmitted, history]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createComposition(material, variation, parseFloat(CO2), unitOfMeasure));
    }
  };

  const onSave = async () => {
    dispatch(updateComposition(activeId, material, variation, parseFloat(CO2), unitOfMeasure));
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Composition</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <Input
                    v={v}
                    err={err}
                    name="material"
                    id="material"
                    label="Material"
                    required
                    value={material}
                    onChange={setMaterial}
                    disabled={isPosting || isFetching}
                    onBlur={setMaterial}
                    placeholder="Insert material"
                />
                <Input
                    v={v}
                    err={err}
                    name="variation"
                    id="variation"
                    label="Variation"
                    required
                    value={variation}
                    onChange={setVariation}
                    disabled={isPosting || isFetching}
                    onBlur={setVariation}
                    placeholder="Insert variation"
                />
                <Input
                    v={v}
                    err={err}
                    name="CO2"
                    id="CO2"
                    label="CO2 Emissions"
                    required
                    value={CO2}
                    onChange={setCO2}
                    disabled={isPosting || isFetching}
                    onBlur={setCO2}
                    placeholder="Insert CO2 emissions"
                />
                <Input
                    v={v}
                    err={err}
                    name="unitOfMeasure"
                    id="unitOfMeasure"
                    label="Unit of Measure"
                    required
                    value={unitOfMeasure}
                    onChange={setUnitOfMeasure}
                    disabled={isPosting || isFetching}
                    onBlur={setUnitOfMeasure}
                    placeholder="Insert unit of measure"
                />
                <CheckBox label="Is Active?" onChange={setActive} checked={active} />
                <div className="row">
                    <div className="col-md-4">
                        <Button
                            disabled={!isFormValid()}
                            isLoading={isPosting || isFetching}
                            onClick={() => (editMode ? onSave() : onSubmit())}
                            title={editMode ? 'SAVE' : 'CREATE'}
                        />
                    </div>
                </div>
            </form>
        </View>
  );
};

export default CompositionForm;
