// EntryCompositionDataTable.tsx

import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector, useDispatch } from 'react-redux';
import { getEntryCompositions, isFetchingEntryCompositions } from '../../../selectors/EntryComposition';
import { fetchEntryCompositions, archiveEntryComposition } from '../../../actions/EntryComposition';
import { EntryComposition } from '../../../types/EntryComposition';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';
import { formatUrl } from '../../../helpers/functions';

const EntryCompositionDataTable = () => {
  const dispatch = useDispatch();
  const entryCompositions = useSelector(getEntryCompositions);
  const isLoading = useSelector(isFetchingEntryCompositions);
  const [toggle, setToggle] = useState(false);
  const [entryComposition, setActiveEntryComposition] = useState<EntryComposition | null>(null);
  const [refetch, setRefetch] = useState(false);
  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ENTRY_COMPOSITION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ENTRY_COMPOSITION_UPDATE, row.id));
  };

  const onArchive = (row: EntryComposition) => {
    setActiveEntryComposition(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (entryComposition?.id) {
      await dispatch(archiveEntryComposition(entryComposition.id));
      setRefetch(!refetch);
    }
    setActiveEntryComposition(null);
  };

  const config = {
    columns: [
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'compositionMaterial', label: 'Composition Material', mobileFriendly: true },
      { key: 'compositionVariation', label: 'Composition Variation', mobileFriendly: true },
      { key: 'quantity', label: 'Quantity', mobileFriendly: false },
      { key: 'unitOfMeasure', label: 'Unit of Measure', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: 'Edit', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
    ],
    hideButton: true,
  };

  return (
        <View title="Entry Compositions" onAddNew={onCreate}>
            <DataTable
                fetchFunction={fetchEntryCompositions}
                data={entryCompositions?.data || []}
                isLoading={isLoading}
                paging={entryCompositions?.paging}
                config={config}
                dependencies={refetch}
            />
            <Modal
                show={toggle}
                title="Archive Entry Composition"
                cta="Archive"
                onCta={onConfirmArchive}
                onHide={onDismiss}
            >
                Are you sure you want to archive this entry composition?
            </Modal>
        </View>
  );
};

export default EntryCompositionDataTable;
