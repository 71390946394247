import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createEntryField, fetchActiveEntryField, updateEntryField } from '../../../actions/EntryField';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import { getEntries } from '../../../selectors/Entry';
import { getFields } from '../../../selectors/Field';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import {
  getActiveEntryField,
  isPostingEntryField,
  postingEntryFieldError,
  postingEntryFieldFailed,
  postingEntryFieldValidationErrors,
  isFetchingEntryFields,
} from '../../../selectors/EntryField';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { fetchClients } from '../../../actions/Client';
import { fetchEntries } from '../../../actions/Entry';
import { fetchFields } from '../../../actions/Field';


const EntryFieldForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeEntryField = useSelector(getActiveEntryField);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const entries = useSelector(getEntries);
  const fields = useSelector(getFields);
  const isPosting = useSelector(isPostingEntryField);
  const isFetching = useSelector(isFetchingEntryFields);
  const postingFailed = useSelector(postingEntryFieldFailed);
  const postingError = useSelector(postingEntryFieldError);
  const postingValidationErrors = useSelector(postingEntryFieldValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.ENTRY_FIELD_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'entryId', validators: [isRequired] },
    { field: 'fieldId', validators: [isRequired] },
    { field: 'order', validators: [] },
    { field: 'value', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [entryId, setEntryId] = useState('');
  const [fieldId, setFieldId] = useState('');
  const [order, setOrder] = useState(0);
  const [value, setValue] = useState('');
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchEntries());
    dispatch(fetchFields());
    if (editMode) {
      dispatch(fetchActiveEntryField(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeEntryField && editMode) {
      setGroupId(activeEntryField?.groupId);
      setClientId(activeEntryField?.clientId);
      setEntryId(activeEntryField?.entryId);
      setFieldId(activeEntryField?.fieldId);
      setOrder(activeEntryField?.order);
      setValue(activeEntryField?.value);
      // @ts-ignore
      setActive(activeEntryField?.active === 1 || activeEntryField?.active === true);
    }
  }, [activeEntryField]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ENTRY_FIELD);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createEntryField(groupId, clientId, entryId, fieldId, order, value, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateEntryField(activeId, groupId, clientId, entryId, fieldId, order, value, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsEntries = entries?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsFields = fields?.data.map(r => ({ label: r.title, value: r.id })) || [];


  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  const onEntrySelect = (obj: any) => {
    setEntryId(obj.value);
  };

  const onFieldSelect = (obj: any) => {
    setFieldId(obj.value);
  };

  return (
      <View>
        <h4>{editMode ? 'Edit' : 'Create'} EntryField</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
              {postingError}
            </Alert>
        )}
        <form>
          <DropDown v={v} err={err}
                    id="groupId"
                    placeholder="Please select a Group"
                    required label="Group"
                    items={itemsGroups}
                    value={groupId}
                    disabled={isPosting || isFetching}
                    onSelect={onGroupSelect}
                    type="default"
          />
          <DropDown v={v} err={err}
                    id="clientId"
                    placeholder="Please select a Client"
                    required
                    label="Client"
                    items={itemsClients}
                    disabled={isPosting || isFetching || disabledClient}
                    value={clientId}
                    onSelect={onClientSelect}
                    type="default"
          />
          <DropDown v={v} err={err}
                    id="entryId"
                    placeholder="Please select a Entry"
                    required
                    label="Entry"
                    items={itemsEntries}
                    disabled={isPosting || isFetching}
                    value={entryId}
                    onSelect={onEntrySelect}
                    type="default"
          />
          <DropDown v={v} err={err}
                    id="fieldId"
                    placeholder="Please select a Field"
                    required
                    label="Field"
                    items={itemsFields}
                    disabled={isPosting || isFetching}
                    value={fieldId}
                    onSelect={onFieldSelect}
                    type="default"
          />
          <Input v={v} err={err}
                 name="order"
                 id="order"
                 label="Order"
                 required
                 value={order}
                 onChange={setOrder}
                 disabled={isPosting || isFetching}
                 onBlur={setOrder}
                 placeholder="Insert an order value"
          />
          <Input v={v} err={err}
                 name="value"
                 id="value"
                 label="Value"
                 required
                 value={value}
                 onChange={setValue}
                 disabled={isPosting || isFetching}
                 onBlur={setValue}
                 placeholder="Insert a value"
          />
          <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
          <div className="row">
            <div className="col-md-4">
              <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                      onClick={() => editMode ? onSave() : onSubmit()}
                      title={editMode ? 'SAVE' : 'CREATE'}/>
            </div>
          </div>
        </form>
      </View>
  );

};

export default EntryFieldForm;
