import {
  FETCH_OFFER_ITEM_FAILURE,
  FETCH_OFFER_ITEM_REQUEST,
  FETCH_OFFER_ITEM_RESPONSE,
  FETCH_OFFER_ITEMS_FAILURE,
  FETCH_OFFER_ITEMS_REQUEST,
  FETCH_OFFER_ITEMS_RESPONSE,
  POST_OFFER_ITEM_FAILURE,
  POST_OFFER_ITEM_RESPONSE,
  OfferItemActionTypes,
  OfferItemState,
  POST_OFFER_ITEM_REQUEST,
} from '../types/OfferItem';
import { parseValidationErrors } from '../helpers/functions';

const initialState: OfferItemState = {
  offerItems: null,
  activeOfferItem: null,
  isFetchingOfferItems: false,
  fetchingOfferItemsFailed: false,
  isPostingOfferItem: false,
  postingOfferItemFailed: false,
  postingOfferItemError: null,
  postingOfferItemValidationErrors: null,
};

const offerItemsReducer = (
  state = initialState,
  action: OfferItemActionTypes,
): OfferItemState => {
  switch (action.type) {
    case FETCH_OFFER_ITEMS_REQUEST:
      return {
        ...state,
        offerItems: null,
        isFetchingOfferItems: true,
        fetchingOfferItemsFailed: false,
      };
    case FETCH_OFFER_ITEMS_RESPONSE:
      return {
        ...state,
        offerItems: action.payload || [],
        isFetchingOfferItems: false,
        fetchingOfferItemsFailed: false,
      };
    case FETCH_OFFER_ITEMS_FAILURE:
      return {
        ...state,
        offerItems: null,
        isFetchingOfferItems: false,
        fetchingOfferItemsFailed: true,
      };
    case FETCH_OFFER_ITEM_REQUEST:
      return {
        ...state,
        activeOfferItem: null,
        isFetchingOfferItems: true,
        fetchingOfferItemsFailed: false,
      };
    case FETCH_OFFER_ITEM_RESPONSE:
      return {
        ...state,
        activeOfferItem: action.payload,
        isFetchingOfferItems: false,
        fetchingOfferItemsFailed: false,
      };
    case FETCH_OFFER_ITEM_FAILURE:
      return {
        ...state,
        activeOfferItem: null,
        isFetchingOfferItems: false,
        fetchingOfferItemsFailed: true,
      };
    case POST_OFFER_ITEM_REQUEST:
      return {
        ...state,
        isPostingOfferItem: true,
        postingOfferItemError: null,
        postingOfferItemValidationErrors: null,
        postingOfferItemFailed: false,
      };
    case POST_OFFER_ITEM_RESPONSE:
      return {
        ...state,
        isPostingOfferItem: false,
        postingOfferItemError: null,
        postingOfferItemValidationErrors: null,
        postingOfferItemFailed: false,
      };
    case POST_OFFER_ITEM_FAILURE:
      return {
        ...state,
        isPostingOfferItem: false,
        postingOfferItemError: action.payload.error || 'An error occurred',
        postingOfferItemValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingOfferItemFailed: true,
      };
    default:
      return state;
  }
};

export default offerItemsReducer;
