import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_OFFER_ITEM_STATUS_CODES_REQUEST,
  FETCH_OFFER_ITEM_STATUS_CODES_RESPONSE,
  FETCH_OFFER_ITEM_STATUS_CODES_FAILURE,
  FETCH_OFFER_ITEM_STATUS_CODE_REQUEST,
  FETCH_OFFER_ITEM_STATUS_CODE_RESPONSE,
  FETCH_OFFER_ITEM_STATUS_CODE_FAILURE,
  POST_OFFER_ITEM_STATUS_CODE_FAILURE,
  POST_OFFER_ITEM_STATUS_CODE_REQUEST,
  POST_OFFER_ITEM_STATUS_CODE_RESPONSE,
  OfferItemStatusCodeActionTypes, OfferItemStatusCodeDataModel, OfferItemStatusCode,
} from '../types/OfferItemStatusCode';

import { RootState } from '../store/configureStore';

import {
  getOfferItemStatusCode,
  getOfferItemStatusCodes,
  postOfferItemStatusCode,
  putOfferItemStatusCode,
  archiveOfferItemStatusCode,
  putOfferItemStatusCodeByItemId,
} from '../api/OfferItemStatusCode';
import { DataTableParamsModel } from '../types/Common';

export function fetchOfferItemStatusCodesRequest(): OfferItemStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_ITEM_STATUS_CODES_REQUEST,
    payload: null,
  };
}

export function fetchOfferItemStatusCodesResponse(
  offerItemStatusCodes: OfferItemStatusCodeDataModel,
): OfferItemStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_ITEM_STATUS_CODES_RESPONSE,
    payload: offerItemStatusCodes,
  };
}

export function fetchOfferItemStatusCodesFailure(): OfferItemStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_ITEM_STATUS_CODES_FAILURE,
    payload: null,
  };
}

export function fetchOfferItemStatusCodeRequest(): OfferItemStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_ITEM_STATUS_CODE_REQUEST,
    payload: null,
  };
}

export function fetchOfferItemStatusCodeResponse(
  offerItemStatusCode: OfferItemStatusCode,
): OfferItemStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_ITEM_STATUS_CODE_RESPONSE,
    payload: offerItemStatusCode,
  };
}

export function fetchOfferItemStatusCodeFailure(): OfferItemStatusCodeActionTypes {
  return {
    type: FETCH_OFFER_ITEM_STATUS_CODE_FAILURE,
    payload: null,
  };
}

export const fetchActiveOfferItemStatusCode = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOfferItemStatusCodeRequest());
    const asyncResp: any = await getOfferItemStatusCode(id);
    if (asyncResp?.success) {
      await dispatch(fetchOfferItemStatusCodeResponse(asyncResp.data));
    } else {
      await dispatch(fetchOfferItemStatusCodeFailure());
    }
  };

export function postOfferItemStatusCodeRequest(): OfferItemStatusCodeActionTypes {
  return {
    type: POST_OFFER_ITEM_STATUS_CODE_REQUEST,
    payload: null,
  };
}

export function postOfferItemStatusCodeResponse(): OfferItemStatusCodeActionTypes {
  return {
    type: POST_OFFER_ITEM_STATUS_CODE_RESPONSE,
    payload: null,
  };
}

export function postOfferItemStatusCodeFailure(error: string, validationErrors: any): OfferItemStatusCodeActionTypes {
  return {
    type: POST_OFFER_ITEM_STATUS_CODE_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchOfferItemStatusCodes = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOfferItemStatusCodesRequest());
    const asyncResp: any = await getOfferItemStatusCodes(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchOfferItemStatusCodesResponse(asyncResp));
    } else {
      await dispatch(fetchOfferItemStatusCodesFailure());
    }
  };

export const createOfferItemStatusCode = (
  offerItemId: string, status: string, notes: string | null,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOfferItemStatusCodeRequest());
    const asyncResp: any = await postOfferItemStatusCode(offerItemId, status, notes);
    if (asyncResp?.success) {
      await dispatch(postOfferItemStatusCodeResponse());
    } else {
      await dispatch(postOfferItemStatusCodeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateOfferItemStatusCode = (
  id: string,
  offerItemId: string, status: string, notes: string | null,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOfferItemStatusCodeRequest());
    const asyncResp: any = await putOfferItemStatusCode(id, offerItemId, status, notes);
    if (asyncResp?.success) {
      await dispatch(postOfferItemStatusCodeResponse());
    } else {
      await dispatch(postOfferItemStatusCodeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateOfferItemStatusCodeByOfferItemId = (
  id: string, status: string, notes: string | null,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOfferItemStatusCodeRequest());
    const asyncResp: any = await putOfferItemStatusCodeByItemId(id, status, notes);
    if (asyncResp?.success) {
      await dispatch(postOfferItemStatusCodeResponse());
    } else {
      await dispatch(postOfferItemStatusCodeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const storeOfferItemStatusCode = async (id: string) => {
  try {
    await archiveOfferItemStatusCode(id);
  } catch (error) {
    throw error;
  }
};
