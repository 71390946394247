import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getEntryCompositions(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY_COMPOSITION,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntryComposition(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_COMPOSITION}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postEntryComposition(entryId: string, compositionId: string, quantity: number, unitOfMeasure: string) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY_COMPOSITION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        entryId,
        compositionId,
        quantity,
        unitOfMeasure,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putEntryComposition(
  id: string,
  entryId: string, compositionId: string, quantity: number, unitOfMeasure: string,
) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_COMPOSITION}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        entryId,
        compositionId,
        quantity,
        unitOfMeasure,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function storeEntryComposition(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_COMPOSITION}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
