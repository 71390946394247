import React from 'react';
import View from '../../../components/View';
import { useSelector } from 'react-redux';
import { getStatusCodes, isFetchingStatusCodes } from '../../../selectors/StatusCode';
import { fetchStatusCodes } from '../../../actions/StatusCode';
import DataTable  from '../../../components/DataTable';

const StatusCodeDataTable = () => {
    
  const statusCodes = useSelector(getStatusCodes);
  const isLoading = useSelector(isFetchingStatusCodes);

  const config = {
    columns: [
      { key: 'code', label: 'Code', mobileFriendly: true },
      { key: 'value', label: 'Value', mobileFriendly: true },
    ],
    actions: [

    ],
  };

  return (
        <View title={'Status Codes'}>
            <DataTable fetchFunction={fetchStatusCodes} paging={statusCodes?.paging || []}
                       isLoading={isLoading} data={statusCodes?.data || []} config={config} />
        </View>
  );
};

export default StatusCodeDataTable;