import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createFormField, fetchActiveFormField, updateFormField } from '../../../actions/FormField';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import { getForms } from '../../../selectors/Form';
import { getFields } from '../../../selectors/Field';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import {
  getActiveFormField,
  isPostingFormField,
  postingFormFieldError,
  postingFormFieldFailed,
  postingFormFieldValidationErrors,
  isFetchingFormFields,
} from '../../../selectors/FormField';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { fetchClients } from '../../../actions/Client';
import { fetchForms } from '../../../actions/Form';
import { fetchFields } from '../../../actions/Field';
import Input from '../../../components/Input';


const FormFieldForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeFormField = useSelector(getActiveFormField);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const forms = useSelector(getForms);
  const fields = useSelector(getFields);
  const isPosting = useSelector(isPostingFormField);
  const isFetching = useSelector(isFetchingFormFields);
  const postingFailed = useSelector(postingFormFieldFailed);
  const postingError = useSelector(postingFormFieldError);
  const postingValidationErrors = useSelector(postingFormFieldValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.FORM_FIELD_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'formId', validators: [isRequired] },
    { field: 'fieldId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [formId, setFormId] = useState('');
  const [fieldId, setFieldId] = useState('');
  const [order, setOrder] = useState(0);
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchForms());
    dispatch(fetchFields({ colKey: 'created', sortDirection:  'desc', paging: '1000' }));
    if (editMode) {
      dispatch(fetchActiveFormField(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeFormField && editMode) {
      setGroupId(activeFormField?.groupId);
      setClientId(activeFormField?.clientId);
      setFormId(activeFormField?.formId);
      setFieldId(activeFormField?.fieldId);
      setOrder(activeFormField?.order);
      // @ts-ignore
      setActive(activeFormField?.active === 1 || activeFormField?.active === true);
    }
  }, [activeFormField]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.FORM_FIELD);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createFormField(groupId, clientId, formId, fieldId, order, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateFormField(activeId, groupId, clientId, formId, fieldId, order, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsForms = forms?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsFields = fields?.data.map(r => ({ label: r.title, value: r.id })) || [];


  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  const onFormSelect = (obj: any) => {
    setFormId(obj.value);
  };

  const onFieldSelect = (obj: any) => {
    setFieldId(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} FormField</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown v={v} err={err}
                          id="groupId"
                          placeholder="Please select a Group"
                          required label="Group"
                          items={itemsGroups}
                          value={groupId}
                          disabled={isPosting || isFetching}
                          onSelect={onGroupSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="clientId"
                          placeholder="Please select a Client"
                          required
                          label="Client"
                          items={itemsClients}
                          disabled={isPosting || isFetching || disabledClient}
                          value={clientId}
                          onSelect={onClientSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="formId"
                          placeholder="Please select a Form"
                          required
                          label="Form"
                          items={itemsForms}
                          disabled={isPosting || isFetching}
                          value={formId}
                          onSelect={onFormSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="fieldId"
                          placeholder="Please select a Field"
                          required
                          label="Field"
                          items={itemsFields}
                          disabled={isPosting || isFetching}
                          value={fieldId}
                          onSelect={onFieldSelect}
                          type="default"
                />
              <Input v={v} err={err}
                     name="order"
                     id="order"
                     label="Order"
                     required
                     value={order}
                     onChange={setOrder}
                     disabled={isPosting || isFetching}
                     onBlur={setOrder}
                     placeholder="Insert an order value"
              />
                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default FormFieldForm;
