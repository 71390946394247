import { RootState } from '../store/configureStore';

export const getTransactions = (state: RootState) => state?.transaction?.transactions;

export const getActiveTransaction = (state: RootState) => state?.transaction?.activeTransaction;

export const isFetchingTransactions = (state: RootState) => state?.transaction?.isFetchingTransactions;

export const isPostingTransaction = (state: RootState) => state?.transaction?.isPostingTransaction;

export const postingTransactionFailed = (state: RootState) => state?.transaction?.postingTransactionFailed;

export const postingTransactionError = (state: RootState) => state?.transaction?.postingTransactionError;

export const postingTransactionValidationErrors = (state: RootState) => state?.transaction?.postingTransactionValidationErrors;

export const fetchingTransactionsFailed = (state: RootState) => state?.transaction?.fetchingTransactionsFailed;
