import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import '../../styles/formRenderer.scss';
import { useHistory } from 'react-router-dom';
import c from '../../helpers/constants';

import Button from '../../components/Button';
import { useValidation } from '../../hooks/ValidationHook';

import { Alert } from 'react-bootstrap';
import DropDown from '../../components/DropDown';

import {
  getActiveOffer,
  isFetchingOffers,
  isPostingOffer,
  postingOfferError,
  postingOfferFailed, postingOfferValidationErrors,
} from '../../selectors/Offer';
import { getContacts, isPostingContact } from '../../selectors/Contact';
import { getSolutions } from '../../selectors/Solution';
import { useEditMode } from '../../hooks/EditModeHook';
import { fetchContacts } from '../../actions/Contact';
import { fetchSolutions } from '../../actions/Solution';
import { fetchActiveOffer, updateOffer } from '../../actions/Offer';
import Modal from '../../components/Modal';
import ContactForm from './CreateContact';
import ItemsTable from '../../components/Offer/ItemsTable/ItemsTable';
import { getOfferItems } from '../../selectors/OfferItem';
import { DataTableParamsModel, Row } from '../../types/Common';
import { fetchOfferItems } from '../../actions/OfferItem';

function CreateOffer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeOffer = useSelector(getActiveOffer);
  const contacts = useSelector(getContacts);
  const solutions = useSelector(getSolutions);
  const [editMode, activeId] = useEditMode(c.APP_ROUTES.CREATE_ESTIMATION);

  const offerItems = useSelector(getOfferItems);
  const isPosting = useSelector(isPostingOffer);
  const isPostingContacts = useSelector(isPostingContact);
  const isFetching = useSelector(isFetchingOffers);
  const postingFailed = useSelector(postingOfferFailed);
  const postingError = useSelector(postingOfferError);
  const postingValidationErrors = useSelector(postingOfferValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [shipmentContactId, setShipmentContactId] = useState('');
  const [solutionId, setSolutionId] = useState('');
  const [solutionTitle, setSolutionTitle] = useState('');

  const { v, triggerValidation, isFormValid, err, resetFields } = useValidation([], postingValidationErrors);

  useEffect(() => {
    const updatedFormConfig = [
      // { field: 'solutionId', validators: [isRequired] },
      // { field: 'contactId', validators: [conditionalValidator((shipmentContactId != 'selfShipping'), isRequired)] },
      { field: 'solutionId', validators: [] },
      { field: 'contactId', validators: [] },
    ];

    resetFields(updatedFormConfig);
  }, [shipmentContactId]);

  const [itemsContacts, setItemsContacts] = useState<{ label: string; value: string; }[]>([]);
  const [itemsSolutions, setItemsSolutions] = useState<{ label: string; value: string; }[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [creatingNewContact, setCreatingNewContact] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [createdOfferItems, setCreatedOfferItems] = useState<Row[]>([]);

  const statusCodes = activeOffer?.statusCodes[0];
  const latestStatusCode = statusCodes?.value ?? '';
  const latestStatusCodeNotes = statusCodes?.notes ?? '';

  useEffect(() => {
    dispatch(fetchContacts());
    dispatch(fetchSolutions());
    const dataTableParams1: DataTableParamsModel = {
      sortDirection: 'desc',
      paging: '100',
      searchField: 'offerId',
      searchWord: activeId,
    };
    dispatch(fetchOfferItems(dataTableParams1));
    if (editMode) {
      dispatch(fetchActiveOffer(activeId));
    }
  }, [dispatch, editMode, activeId]);


  useEffect(() => {
    if (contacts) {
      setItemsContacts(contacts.data.map(contact => ({ label: contact.email, value: contact.id })));
    }
  }, [contacts]);

  useEffect(() => {
    if (solutions) {
      setItemsSolutions(solutions.data.map(solution => ({ label: solution.title, value: solution.id })));
    }
  }, [solutions]);

  useEffect(() => {
    if (activeOffer && editMode) {
      setShipmentContactId(activeOffer?.shipmentContactId);
      setSolutionId(activeOffer?.solutionId);
    }
  }, [activeOffer, editMode]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.DEAL_HUB);
    }
  }, [isPosting]);

  const onConfirmSubmit = () => {
    if (triggerValidation()) {
      let newStatus = 'Pending Verification';

      if (editMode && activeOffer) {
        switch (latestStatusCode) {
          case 'Pending Verification':
            newStatus = 'Verification Completed';
            break;
          case 'Pending Estimation':
            newStatus = 'Estimation Completed';
            break;
          case 'Changes Requested':
            newStatus = 'Pending Offer';
            break;
          default:
            newStatus = latestStatusCode; // If the status doesn't need to change
        }
      }

      if (editMode) {
        setHasSubmitted(true);
        dispatch(updateOffer(
          activeId,
          activeOffer?.donorClientId ?? '',
          activeOffer?.projectManagerUserId ?? '',
          activeOffer?.salesRepUserId ?? '',
          shipmentContactId ?? activeOffer?.shipmentContactId,
          solutionId ?? activeOffer?.solutionId,
          activeOffer?.subject ?? '',
          activeOffer?.details ?? '',
          activeOffer?.date ?? '',
          activeOffer?.clientNumber ?? '',
          activeOffer?.currency ?? '',
          activeOffer?.pickupLocation ?? '',
          activeOffer?.collectionDate ?? '',
          activeOffer?.notesToEME ?? '',
          activeOffer?.notesToShippingProvider ?? '',
          newStatus,
          '',
          createdOfferItems,
        ));
        setShowModal(false);
      }
    }
  };

  const onDraft = async () => {
    let draftStatus = 'Draft';

    if (editMode && activeOffer) {
      draftStatus = latestStatusCode; // Maintain current status for draft
    }

    if (editMode) {
      setHasSubmitted(true);
      dispatch(updateOffer(
        activeId,
        activeOffer?.donorClientId ?? '',
        activeOffer?.projectManagerUserId ?? '',
        activeOffer?.salesRepUserId ?? '',
        shipmentContactId ?? activeOffer?.shipmentContactId,
        solutionId ?? activeOffer?.solutionId,
        activeOffer?.subject ?? '',
        activeOffer?.details ?? '',
        activeOffer?.date ?? '',
        activeOffer?.clientNumber ?? '',
        activeOffer?.currency ?? '',
        activeOffer?.pickupLocation ?? '',
        activeOffer?.collectionDate ?? '',
        activeOffer?.notesToEME ?? '',
        activeOffer?.notesToShippingProvider ?? '',
        draftStatus,
        '',
        createdOfferItems,
      ));
    }
  };

  const onCancel = async () => {
    history.push(c.APP_ROUTES.DEAL_HUB);
  };

  const onDismissModal = () => {
    setShowModal(false);
    setCreatingNewContact(false);
    setConfirmSubmit(false);
  };

  const onSubmit = () => {
    if (triggerValidation()){
      setShowModal(true);
      setConfirmSubmit(true);
    } else {
      window.scrollTo({ top: 0, behavior: 'auto' });
    }
  };


  useEffect(() => {
    if (!isPostingContacts) {
      onDismissModal();
      dispatch(fetchContacts());
    }
  }, [isPostingContacts]);

  const onContactSelect = (obj: any) => {
    setShipmentContactId(obj.value);
  };

  const onSolutionSelect = (obj: any) => {
    setSolutionId(obj.value);
    setSolutionTitle(obj.label);
  };

  const onCreateNewContact = () => {
    setShowModal(true);
    setCreatingNewContact(true);
  };

  const handleRowsUpdate = (updatedRows: any) => {
    setCreatedOfferItems(updatedRows);
  };

  return (
        <View title={
            !isFetching
              ? (latestStatusCode === 'Changes Requested' ? 'MODIFY ESTIMATION' :
                latestStatusCode === 'Pending Estimation' ? 'CREATE ESTIMATION' : 'ADD NEW OFFER')
              : ''
        }
              isLoading={isFetching || isPosting || !activeOffer}>
            <div className="page">
                {postingError && (
                    <Alert className="mt-3" variant="danger">
                        {postingError}
                    </Alert>
                )}
                <form>
                    <div className="category__label">
                        Client Details
                    </div>
                    <div className="col-md-12 category__container pt-3">
                        <div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className={'estimation-label'}>From:</div>
                                    <div className={'estimation-value'}>{activeOffer?.clientTitle}</div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className={'estimation-label'}>To:</div>
                                    <div className={'estimation-value'}>{activeOffer?.solutionTitle ?? solutionTitle}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="category__label">
                        Sales Rep Details
                    </div>
                    <div className="col-md-12 category__container pt-3">
                        <div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className={'estimation-label'}>First Name:</div>
                                    <div className={'estimation-value'}>{activeOffer?.salesRepUserFirstName}</div>
                                    <div className={'estimation-label'}>Last Name:</div>
                                    <div className={'estimation-value'}>{activeOffer?.salesRepUserLastName}</div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className={'estimation-label'}>Email:</div>
                                    <div className={'estimation-value'}>{activeOffer?.salesRepUserEmail}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="category__label">
                            Project Manager Details
                        </div>
                        <div className="col-md-12 category__container pt-3" style={{ marginBottom: '0px !important' }}>
                            <div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className={'estimation-label'}>First Name:</div>
                                        <div className={'estimation-value'}>{activeOffer?.projectManagerFirstName}</div>
                                        <div className={'estimation-label'}>Last Name:</div>
                                        <div className={'estimation-value'}>{activeOffer?.projectManagerLastName}</div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className={'estimation-label'}>Email:</div>
                                        <div className={'estimation-value'}>{activeOffer?.projectManagerEmail}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="category__label">
                            Offer Details
                        </div>
                        <div className="col-md-12 category__container pt-3" style={{ marginBottom: '0px !important' }}>
                            <div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className={'estimation-label'}>Subject:</div>
                                        <div className={'estimation-value'}>{activeOffer?.subject}</div>
                                        <div className={'estimation-label'}>Date:</div>
                                        <div className={'estimation-value'}>{activeOffer?.date}</div>
                                        <div className={'estimation-label'}>Offer Number:</div>
                                        <div className={'estimation-value'}>{activeOffer?.clientNumber}</div>
                                        <div className={'estimation-label'}>Collection Date:</div>
                                        <div className={'estimation-value'}>{activeOffer?.collectionDate}</div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className={'estimation-label'}>Offer Details:</div>
                                        <div className={'estimation-value'}>{activeOffer?.details}</div>
                                        <div className={'estimation-label'}>Pickup Location:</div>
                                        <div className={'estimation-value'}>{activeOffer?.pickupLocation?.split('~').pop() ?? activeOffer?.pickupLocation}</div>
                                        </div>
                                  <div className="col-md-12 pt-3">
                                    <ItemsTable tableTitle={'Item Table'} rowsData={offerItems?.data} columnCount={5} editable={false}   />
                                  </div>
                                </div>

                            </div>
                        </div>
                        <div className="category__label">
                            Solution Offer
                        </div>
                        <div className="col-md-12 category__container pt-3">
                            <DropDown v={v} err={err}
                                      id="solutionId"
                                      name="solutionId"
                                      placeholder="Please select a solution"
                                      required label="Solution"
                                      items={itemsSolutions}
                                      value={solutionId}
                                      disabled={isPosting || isFetching}
                                      onSelect={onSolutionSelect}
                                      type="default"
                            />
                            <ItemsTable rowsData={offerItems?.data} onRowsUpdate={handleRowsUpdate} columnCount={4} editable={true} type={'EME'}/>

                        </div>
                            <div className="category__label">
                                Shipment Provider Contact Information
                            </div>
                            <div className="col-md-12 category__container pt-3">
                                {shipmentContactId != 'selfShipping' ?
                                    <>
                                    <DropDown v={v} err={err}
                                          id="contactId"
                                          name="contactId"
                                          placeholder="Please select a Shipment Contact"
                                          required label="Shipment Contact"
                                          items={itemsContacts}
                                          value={shipmentContactId}
                                          disabled={isPosting || isFetching}
                                          onSelect={onContactSelect}
                                          type="default"
                                />
                                    <span className="action-link" onClick={onCreateNewContact}>New Shipment Contact</span>
                                    </>
                                  :
                                    <div className={'estimation-value'}>Client has agreed to handle shipping.</div>
                                }
                            </div>
                        {(latestStatusCode === 'Changes Requested' && latestStatusCodeNotes) && <div className="reEstimation-container col-12 col-md-3 col-sm-6">
                            <div className="col-md-12">
                                <div className={'estimation-label estimation-label-strong'}>RE-ESTIMATION REQUEST MESSAGE</div>
                                <div className={'estimation-label'}>{latestStatusCodeNotes}</div>
                            </div>
                        </div>}
                        <Modal show={showModal} title={creatingNewContact ? undefined : 'SUBMIT ESTIMATION'} onHide={onDismissModal}>
                            {creatingNewContact && <ContactForm/>}
                            {confirmSubmit && <>
                                <label>
                                    Are you sure you want to submit this estimation?
                                    You will not be able to change any of the offer information after this.
                                </label>
                                <div className="mt-3 form-renderer-button-container">
                                    <Button title="Cancel" disabled={hasSubmitted} onClick={() => onDismissModal()}/>
                                    <Button title="Confirm" disabled={!isFormValid()} onClick={onConfirmSubmit}/>
                                </div>
                            </>
                            }
                        </Modal>
                        <div className="offer-button-container">
                            <Button title="Cancel" disabled={hasSubmitted} onClick={onCancel}/>
                            <div className="offer-right-buttons">
                                <Button title="Save And Exit" onClick={onDraft}/>
                                <Button title="Submit" onClick={onSubmit}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </View>

  );
}


export default CreateOffer;
