import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createViewRole  } from '../../../actions/ViewRole';
import { getViews } from '../../../selectors/View';
import { fetchViews } from '../../../actions/View';
import { getRoles } from '../../../selectors/Role';
import { fetchRoles } from '../../../actions/Role';



import {
  isPostingViewRole,
  postingViewRoleError,
  postingViewRoleFailed,
  postingViewRoleValidationErrors,
  isFetchingViewRoles,
} from '../../../selectors/ViewRole';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';



const ViewRoleForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const views = useSelector(getViews);
  const roles = useSelector(getRoles);
  const isPosting = useSelector(isPostingViewRole);
  const isFetching = useSelector(isFetchingViewRoles);
  const postingFailed = useSelector(postingViewRoleFailed);
  const postingError = useSelector(postingViewRoleError);
  const postingValidationErrors = useSelector(postingViewRoleValidationErrors);



  const formConfig = [
    { field: 'viewId', validators: [isRequired] },
    { field: 'roleId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [viewId, setViewId] = useState('');
  const [roleId, setRoleId] = useState('');

  useEffect(() => {
    dispatch(fetchViews());
    dispatch(fetchRoles());
  }, [dispatch]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.VIEW_ROLE);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createViewRole(viewId, roleId ));
    }
  };



  const itemsViews = views?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsRoles = roles?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onRoleSelect = (obj: any) => {
    setRoleId(obj.value);
  };
  const onViewSelect = (obj: any) => {
    setViewId(obj.value);
  };

  return <View>
        <h4>Create ViewRole</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="viewId"
                      placeholder="Please select a View"
                      required label="View"
                      items={itemsViews}
                      value={viewId}
                      disabled={isPosting || isFetching}
                      onSelect={onViewSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="roleId"
                      placeholder="Please select a Role"
                      required label="Role"
                      items={itemsRoles}
                      value={roleId}
                      disabled={isPosting || isFetching}
                      onSelect={onRoleSelect}
                      type="default"
            />
            
            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default ViewRoleForm;
