import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createSolutionFunction, fetchActiveSolutionFunction, fetchSolutionFunctions, updateSolutionFunction } from '../../../actions/SolutionFunction';
import { getMaterialsFunctions } from '../../../selectors/MaterialsFunction';
import CheckBox from '../../../components/CheckBox';
import { fetchMaterialsFunctions } from '../../../actions/MaterialsFunction';
import {
  getActiveSolutionFunction,
  isPostingSolutionFunction,
  postingSolutionFunctionError,
  postingSolutionFunctionFailed,
  postingSolutionFunctionValidationErrors,
  isFetchingSolutionFunctions,
} from '../../../selectors/SolutionFunction';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';


const SolutionFunctionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeSolutionFunction = useSelector(getActiveSolutionFunction);
  const functions = useSelector(getMaterialsFunctions);
  const isPosting = useSelector(isPostingSolutionFunction);
  const isFetching = useSelector(isFetchingSolutionFunctions);
  const postingFailed = useSelector(postingSolutionFunctionFailed);
  const postingError = useSelector(postingSolutionFunctionError);
  const postingValidationErrors = useSelector(postingSolutionFunctionValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.SOLUTION_FUNCTION_UPDATE);

  const formConfig = [
    { field: 'functionId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [functionId, setFunctionId] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(fetchMaterialsFunctions());
    dispatch(fetchSolutionFunctions());
    if (editMode) {
      dispatch(fetchActiveSolutionFunction(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeSolutionFunction && editMode) {
      setFunctionId(activeSolutionFunction?.functionId);
      // @ts-ignore
      setActive(activeSolutionFunction?.active === 1 || activeSolutionFunction?.active === true);
    }
  }, [activeSolutionFunction]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.SOLUTION_FUNCTION);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);

      dispatch(createSolutionFunction(functionId, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateSolutionFunction(activeId, functionId, active));
    }
  };

  const itemsFunctions = functions?.data.map(r => ({ label: r.value, value: r.id })) || [];

  const onFunctionSelect = (obj: any) => {
    setFunctionId(obj.value);
  };

  return (
      <View>
        <h4>{editMode ? 'Edit' : 'Create'} SolutionFunction</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
              {postingError}
            </Alert>
        )}
        <form>
          <DropDown v={v} err={err}
                    id="functionId"
                    placeholder="Please select a Function"
                    required label="Function"
                    items={itemsFunctions}
                    value={functionId}
                    disabled={isPosting || isFetching}
                    onSelect={onFunctionSelect}
                    type="default"
          />
          <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
          <div className="row">
            <div className="col-md-4">
              <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                      onClick={() => editMode ? onSave() : onSubmit()}
                      title={editMode ? 'SAVE' : 'CREATE'}/>
            </div>
          </div>
        </form>
      </View>
  );

};

export default SolutionFunctionForm;
