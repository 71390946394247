import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import './CheckBox.scss';

type Props = {
  label?: string | React.ReactFragment;
  id?: string;
  name?: string;
  type?: 'checkbox' | 'radio' | 'switch';
  value?: string | number | readonly string[] | undefined;
  checked?: boolean;
  onChange?: (val: boolean) => void;
  inverted?: boolean;
};

const CheckBox = (props: Props) => {
  const { label, type = 'switch', value, checked = false, id = '', name = '', onChange = () => {}, inverted = false } = props;

  const [toggle, setToggle] = useState(checked);

  useEffect(()=> {
    setToggle(checked);
  }, [checked]);

  const onChangeInternal = () => {
    onChange(!toggle);
    setToggle(!toggle);
  };

  return (
      <div className={type === 'switch' ? 'switch' : type === 'checkbox' ? 'checkbox' : 'radio'}>
        {inverted ? (
            <>
              <label className={'form-check-label inverted'}>{label}</label>
              <Form.Check
                  onChange={onChangeInternal}
                  id={id}
                  name={name}
                  checked={toggle}
                  type={type}
                  value={value}
                  style={{ display: 'inline-block', marginLeft: '10px' }}
              />
            </>
        ) : (
            <Form.Check
                onChange={onChangeInternal}
                id={id}
                name={name}
                checked={toggle}
                type={type}
                value={value}
                label={label}
            />
        )}
      </div>
  );
};

export default CheckBox;
