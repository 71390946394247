import { ApiDataModel, FormError } from './Common';

export const FETCH_OFFER_STATUS_CODES_REQUEST = 'FETCH_OFFER_STATUS_CODES_REQUEST';
export const FETCH_OFFER_STATUS_CODES_RESPONSE = 'FETCH_OFFER_STATUS_CODES_RESPONSE';
export const FETCH_OFFER_STATUS_CODES_FAILURE = 'FETCH_OFFER_STATUS_CODES_FAILURE';

export const FETCH_OFFER_STATUS_CODE_REQUEST = 'FETCH_OFFER_STATUS_CODE_REQUEST';
export const FETCH_OFFER_STATUS_CODE_RESPONSE = 'FETCH_OFFER_STATUS_CODE_RESPONSE';
export const FETCH_OFFER_STATUS_CODE_FAILURE = 'FETCH_OFFER_STATUS_CODE_FAILURE';

export const POST_OFFER_STATUS_CODE_REQUEST = 'POST_OFFER_STATUS_CODE_REQUEST';
export const POST_OFFER_STATUS_CODE_RESPONSE = 'POST_OFFER_STATUS_CODE_RESPONSE';
export const POST_OFFER_STATUS_CODE_FAILURE = 'POST_OFFER_STATUS_CODE_FAILURE';

export interface OfferStatusCode {
  id: string;
  offerId: string;
  offerCodeId: string;
  notes: string | null;
}

export interface OfferStatusCodeDataModel extends Omit<ApiDataModel, 'data'> {
  data: OfferStatusCode[];
}

export interface OfferStatusCodeState {
  offerStatusCodes: OfferStatusCodeDataModel | null,
  activeOfferStatusCode: OfferStatusCode | null,
  isFetchingOfferStatusCodes: boolean;
  fetchingOfferStatusCodesFailed: boolean;
  postingOfferStatusCodeFailed?: boolean;
  isPostingOfferStatusCode: boolean;
  postingOfferStatusCodeValidationErrors: FormError[] | null;
  postingOfferStatusCodeError: string | null;
}

interface FetchOfferStatusCodesRequestAction {
  type: typeof FETCH_OFFER_STATUS_CODES_REQUEST;
  payload: null;
}

interface FetchOfferStatusCodesResponseAction {
  type: typeof FETCH_OFFER_STATUS_CODES_RESPONSE;
  payload: OfferStatusCodeDataModel;
}

interface FetchOfferStatusCodesFailureAction {
  type: typeof FETCH_OFFER_STATUS_CODES_FAILURE;
  payload: null;
}

interface FetchOfferStatusCodeRequestAction {
  type: typeof FETCH_OFFER_STATUS_CODE_REQUEST;
  payload: null;
}

interface FetchOfferStatusCodeResponseAction {
  type: typeof FETCH_OFFER_STATUS_CODE_RESPONSE;
  payload: OfferStatusCode;
}

interface FetchOfferStatusCodeFailureAction {
  type: typeof FETCH_OFFER_STATUS_CODE_FAILURE;
  payload: null;
}

interface PostOfferStatusCodeRequestAction {
  type: typeof POST_OFFER_STATUS_CODE_REQUEST;
  payload: null;
}

interface PostOfferStatusCodeResponseAction {
  type: typeof POST_OFFER_STATUS_CODE_RESPONSE;
  payload: null;
}

interface PostOfferStatusCodeFailureAction {
  type: typeof POST_OFFER_STATUS_CODE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type OfferStatusCodeActionTypes =
    | FetchOfferStatusCodesRequestAction
    | FetchOfferStatusCodesResponseAction
    | FetchOfferStatusCodesFailureAction
    | PostOfferStatusCodeRequestAction
    | PostOfferStatusCodeResponseAction
    | PostOfferStatusCodeFailureAction
    | FetchOfferStatusCodeRequestAction
    | FetchOfferStatusCodeResponseAction
    | FetchOfferStatusCodeFailureAction;
