import React, { useEffect, useState } from 'react';
import './Return.scss';
import Loading from '../../components/Loading/Loading';
import { fetchActiveTransaction } from '../../actions/Transaction';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTransaction } from '../../selectors/Transaction';
import ClientSelector from '../../components/ClientSelector/ClientSelector';
import { createSubscription } from '../../actions/Subscription';
import { formatISO } from 'date-fns';
import { fetchPlans } from '../../actions/Plan';
import { getPlans } from '../../selectors/Plan';


const PaymentStatusPage = () => {
  const activeTransaction = useSelector(getActiveTransaction);
  const dispatch = useDispatch();
  const plans = useSelector(getPlans);
  const [loading, setLoading] = useState(true); // To manage the loader
  const [selectedClientId, setSelectedClientId] = useState<string | undefined>(undefined);
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(undefined);
  console.log(selectedClientId);
  const startDate = new Date();
  const endDate = new Date();
  console.log(selectedGroupId);
  console.log(plans?.data, 'plans');

  const handleClientSelect = (clientId: string) => {
    setSelectedClientId(clientId);
    console.log('Selected Client ID:', clientId);
  };

  const handleGroupSelect = (groupId: string) => {
    setSelectedGroupId(groupId);
    console.log('Selected Group ID:', groupId);
  };

  function selectPlan(variable: string) {
    let selectedPlanId = 'planId';
    if (plans && plans.data) {

      if (variable === '3500.00') {
        const plan = plans.data.find(planT => planT.title === 'Gold');
        selectedPlanId = plan ? plan.id : 'planId';
      } else if (variable === '7000.00') {
        const plan = plans.data.find(planT => planT.title === 'Platinum');
        selectedPlanId = plan ? plan.id : 'planId';
      } else  {
        const plan = plans.data.find(planT => planT.title === 'Free');
        selectedPlanId = plan ? plan.id : 'planId';
      }
    }

    return selectedPlanId;
  }

  // Get the transaction ID from the query params (from return_url)
  const transactionId = new URLSearchParams(window.location.search).get('transaction_id');

  useEffect(() => {
    dispatch(fetchPlans());
  }, [loading]);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('Timeout after 5 seconds');
      if (loading) {
        try {
          dispatch(fetchActiveTransaction(transactionId ?? ''));
          setLoading(false);
        } catch (error) {
          console.error('Error fetching payment status:', error);
          setLoading(false);
        }
      }
    }, 5000);
    
    return () => clearTimeout(timer);
  }, [loading]);

  useEffect(() => {
    if (activeTransaction?.status === 'completed' && activeTransaction && activeTransaction.amount){
      dispatch(createSubscription(selectPlan( (activeTransaction?.amount / 100).toFixed(2) ), selectedClientId ?? '', transactionId ?? '', formatISO(startDate), formatISO(endDate), true));
    }
  }, [activeTransaction]);

  return (
        <div>
          <ClientSelector onClientSelect={handleClientSelect} onGroupSelect={handleGroupSelect}/>
            {(loading || !activeTransaction?.status) ? (
                <div className="loading-container">
                <Loading text={'Your payment is being processed, please wait!'}/>
                </div>
            ) : (
                <div className="return-container">
                    {activeTransaction?.status === 'completed' ? (
                        <div>
                            <h1>Payment Successful!</h1>
                            {transactionId && (
                                <div>
                                    <h2>Receipt</h2>
                                    <p>Transaction ID: {transactionId}</p>
                                    <p>Amount: {(activeTransaction?.amount / 100).toFixed(2)}</p>
                                    <p>Status: {activeTransaction?.status}</p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <h1>Payment {activeTransaction?.status}</h1>
                            <p>Sorry, there was an issue with your payment. Contact an administrator.</p>
                        </div>
                    )}
                </div>
            )}
        </div>
  );
};

export default PaymentStatusPage;
