import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View as ViewType } from '../../../types/View';
import { useHistory } from 'react-router-dom';
import c, { cardTypes, EntryStatus, tulipsAdminCardTypes } from '../../../helpers/constants';
import { createDuplicateView, transferEntries } from '../../../actions/View';
import { fetchEntries, updateEntryStatus } from '../../../actions/Entry';
import { getEntries } from '../../../selectors/Entry';
import { formatUrl, latLngCalculation } from '../../../helpers/functions';
import Button from '../../../components/Button';
import { getAllEntryFields } from '../../../selectors/EntryField';
import { getLoggedInRole, getLoggedInUser } from '../../../selectors/Auth';
import MaterialMap from '../../../components/MaterialMap';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import DropDown from '../../../components/DropDown';
import Card from '../../../components/Card/Card';
import useShortcodes from '../../../hooks/ShortcodeHook';
import DataTable from '../../../components/DataTable';
import { fetchAllEntryFields } from '../../../actions/EntryField';
import { getDuplicateViews } from '../../../selectors/View';
import { toNumber } from 'lodash';
import ButtonToggler from '../../../components/ButtonToggler';
import assetGallery from '../../../components/AssetGallery';
import { Entry } from '../../../types/Entry';
import { Alert } from 'react-bootstrap';
import { getActiveConfiguration } from '../../../selectors/Configuration';

type Props = {
  view: ViewType;
  isLoadingDatatable: boolean;
};

function DynamicView(props: Props) {
  const dispatch = useDispatch();
  const { view, isLoadingDatatable } = props;
  const entries = useSelector(getEntries);
  const entryFields = useSelector(getAllEntryFields);
  const activeConfiguration = useSelector(getActiveConfiguration);
  const userRole = useSelector(getLoggedInRole);
  const loggedInUser = useSelector(getLoggedInUser);
  const duplicateViews = useSelector(getDuplicateViews);
  const [gridView, setGridView] = useState(true);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [belongsToTulips, setBelongsToTulips] = useState(false);
  const [geolocation, setGeolocation] = useState('');
  const [privateZoom, setPrivateZoom] = useState(5);
  const [publicZoom, setPublicZoom] = useState(5);
  const [privateLat, setPrivateLat] = useState(52.1312863);
  const [publicLat, setPublicLat] = useState(52.1312863);
  const [privateLng, setPrivateLng] = useState(-0.0544277);
  const [publicLng, setPublicLng] = useState(-0.0544277);
  const [transferModal, setTransferModal] = useState(false);
  const [toggleSelectable, setToggleSelectable] = useState(false);
  const [toggleCreateView, setToggleCreateView] = useState(false);
  const [selectedEntryIds, setSelectedEntryIds] = useState<string[]>([]);
  const [newFormTitle, setNewFormTitle] = useState('');
  const [newFormDescription, setNewFormDescription] = useState('');
  const [toViewId, setToViewId] = useState('');
  const [accessLevelModal, setAccessLevel] = useState(2000);
  const [statusModal, setStatus] = useState('Private');
  const [isMadeByMeModal, setIsMadeByMe] = useState(false);
  const [entryIdModal, setEntryId] = useState('');
  const [formIdModal, setFormId] = useState('');
  const [showCopied, setShowCopied] = useState(false);

  //Map related functionalities
  const publicStatuses = [
    EntryStatus.PUBLIC,
    EntryStatus.REUSED,
    EntryStatus.EXPIRED,
    EntryStatus.SOLD,
  ];

  const mapPublicItems = entryFields?.data.filter(x => (x.fieldType == 'map' && (publicStatuses.includes(x.entryStatus as EntryStatus) || x.createdBy == loggedInUser?.email) && x.archived == null && x.entryFormId == view?.formId)).map(e => ({
    'geolocation': e.value,
    'id': e.entryId,
    'title': e.entryTitle,
    'description': e.entryDescription,
    'assetId': e.entryAssetId,
  }));

  const mapPrivateItems = entryFields?.data.filter(x => x.fieldType == 'map' && x.archived == null && x.entryFormId == view?.formId).map(e => ({
    'geolocation': e.value,
    'id': e.entryId,
    'title': e.entryTitle,
    'description': e.entryDescription,
    'assetId': e.entryAssetId,
  }));

  const {
    groupShortcode,
    clientShortcode,
    isUsingShortcodes,
  } = useShortcodes(c.APP_ROUTES.VIEW_RENDERER_SHORTCODE, c.APP_ROUTES.VIEW_RENDERER);


  // useEffect(() => {
  //   if (routeId !== view.id) {
  //     if (!isUsingShortcodes) {
  //     } else {
  //       dispatch(fetchEntriesByShortcodes(groupShortcode, clientShortcode, view?.formId));
  //     }
  //   }
  // }, [dispatch, routeId]);

  useEffect(() => {
    if (isUsingShortcodes && groupShortcode && clientShortcode){
      dispatch(fetchAllEntryFields({ fetchParams: { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
    } else {
      dispatch(fetchAllEntryFields());
    }
  }, [dispatch, entries]);

  // Set default lat lng and zoom based on the first card, also reset this value when the user is clicking in another view
  useEffect(() => {
    if (mapPublicItems && mapPrivateItems) {

      const mapCalc = latLngCalculation(mapPrivateItems, mapPublicItems);
      setPublicLat(mapCalc.publicLat);
      setPrivateLat(mapCalc.privateLat);
      setPublicLng(mapCalc.publicLng);
      setPrivateLng(mapCalc.privateLng);
    }
    setPublicZoom(5);
    setPrivateZoom(5);
  }, [gridView, view]);

  useEffect(() => {
    // @ts-ignore
    //setMapActiveCardsId(entryFields?.data.filter(x => x.value == geolocation).map(e => ({ 'id': e.entryId })));

  }, [geolocation]);


  const history = useHistory();

  const onNavigate = (entryId: string) => {
    if (!groupShortcode && !clientShortcode) {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER, entryId));
    } else if (groupShortcode && clientShortcode) {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, groupShortcode, clientShortcode, entryId));
    }
  };


  //Function that retrieves active locations on marker click
  const activeCardLocation = (geo: any) => {
    setGeolocation(geo);
    if (geo) {
      setPrivateLat(parseFloat(geo.split('~')[0]));
      // @ts-ignore
      setPrivateLng(parseFloat(geo.split('~')[1]));
      // @ts-ignore
      setPublicLat(parseFloat(geo.split('~')[0]));
      // @ts-ignore
      setPublicLng(parseFloat(geo.split('~')[1]));
      setPrivateZoom(8);
      setPublicZoom(8);
    }

  };


  const toggleTransferEntries = () => {
    setToggleSelectable(!toggleSelectable);
  };

  const toggleTransferModal = () => {
    setTransferModal(true);
  };

  const toggleCreateViewModal = () => {
    setToggleCreateView(true);
  };

  const onDismiss = () => {
    setTransferModal(false);
  };
  const onDismissStatusModal = () => {
    setOpenStatusModal(false);
  };

  const onDismissViewModal = () => {
    setToggleCreateView(false);
  };

  const createViewAndForm = () => {
    if (view) {
      dispatch(createDuplicateView(view?.groupId, view?.clientId, view?.formId, newFormTitle, newFormDescription));
      setToggleCreateView(false);
    }
  };

  const onStatusSelect = (obj: any, entryId: string, formId: string) => {
    dispatch(updateEntryStatus(entryId, obj, formId));
    setOpenStatusModal(false);
  };

  const finalizeEntryTransfer = () => {
    if (view && selectedEntryIds && selectedEntryIds.length > 0) {
      dispatch(transferEntries(selectedEntryIds, toViewId));
    }
  };


  const setStatusModal = (accessLevel: number, status: string, isMadeByMe: boolean, entryId: string, formId: string, belongsToTulipsClient: boolean ) => {
    setAccessLevel(accessLevel);
    setStatus(status);
    setIsMadeByMe(isMadeByMe);
    setEntryId(entryId);
    setFormId(formId);
    setOpenStatusModal(true);
    setBelongsToTulips(belongsToTulipsClient);
  };
  const itemsStatus = cardTypes.map(r => ({ label: r.label, value: r.value }));
  const itemsStatusTulipsAdmin = tulipsAdminCardTypes.map(r => ({ label: r.label, value: r.value }));

  const handleCardSelect = (entryId: string) => {
    if (selectedEntryIds.includes(entryId)) {
      setSelectedEntryIds(selectedEntryIds.filter(id => id !== entryId));
    } else {
      setSelectedEntryIds([...selectedEntryIds, entryId]);
    }
  };

  const onViewSelect = (obj: any) => {
    setToViewId(obj.value);
  };

  const itemsViews = duplicateViews?.data.map(r => ({ label: r.title, value: r.formId })) || [];

  const toEntryEdit = (entryId: string) => {
    history.push(formatUrl(c.APP_ROUTES.FORM_RENDERER_EDIT, entryId));
  };

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [8, 10, 20, 30],
  };

  const onShare = (row: Entry) => {
    const rootUrl = window.location.origin;
    const relativeUrl = formatUrl(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, row.groupShortcode, row.clientShortcode, row.id);
    const fullUrl = `${rootUrl}${relativeUrl}`;

    navigator.clipboard.writeText(fullUrl);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 4000);
  };


  return (
      <>
        {showCopied && <Alert className="mt-3" variant="success">
          Guest link copied to clipboard successfully!
        </Alert>}
      <div className="row">
        {gridView &&
          <div className='col-12'>
            {userRole?.accessLevel === 0 &&
                (window.origin === 'http://localhost:3000' || window.origin === 'https://dev-app.excessmaterialsexchange.com') && <>
              <Button title={'Select'} onClick={toggleTransferEntries}/>
              {'   '}
              <Button title={'Transfer'} onClick={toggleTransferModal}/>
            </>}
            <DataTable
                paging={entries?.paging}
                isLoading={isLoadingDatatable}
                data={entries?.data}
                config={config}
                fetchFunction={fetchEntries}
                fetchParams={isUsingShortcodes && { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode }}
                baseSearch={{ 'formId': view?.formId }}
                isTable={false}
                gridView={gridView}
                togglerFunc={() => setGridView((prev) => !prev)}
                lineItemTemplate={(row: any) => (
                    <div className={'col-lg-3 col-md-6 col-sm-12 mb-4'}>
                      <Card
                          onClick={() => onNavigate(row.id)}
                          onShare={() => onShare(row)}
                          cardId={row.index}
                          title={row.title}
                          description={row.description}
                          status={userRole && userRole?.accessLevel <= 1000 ? row.status : activeConfiguration?.reservedStatus  ? row.status : null}
                          itemId={row.id}
                          onEdit={!isUsingShortcodes ? () => toEntryEdit(row.id) : undefined}
                          onStatusClick={() => userRole && userRole?.accessLevel <= 1000 ? setStatusModal(toNumber(userRole?.accessLevel), row.status, row.createdBy == loggedInUser?.id, row.id, view?.formId, activeConfiguration?.reservedStatus ?? false ) : null}
                          imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                          selectable={toggleSelectable}
                          onSelect={handleCardSelect}
                      />
                    </div>
                )}
            />
          </div>
        }
        {!gridView &&
              <div className="col-12">
                <div className="view_map">
                  <div className="d-flex flex-column align-items-end mb-3">
                    <div className='view__map-grid--size'>
                      <ButtonToggler grid={gridView} title1={'Grid View'} title2={'Map View'} img1={gridView ? assetGallery.gridViewActive : assetGallery.gridView} img2={gridView ? assetGallery.mapView : assetGallery.mapViewActive} onClick={()=>setGridView(!gridView)}/>
                    </div>
                  </div>
                  <MaterialMap
                      lat={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? publicLat : privateLat}
                      lng={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? publicLng : privateLng}
                      view={'viewRenderer'}
                      zoom={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? publicZoom : privateZoom}
                      mapList={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? mapPublicItems : mapPrivateItems}
                      data ={entries?.data}
                      enableInfoWindow={true}
                      activeCardLocation={activeCardLocation}
                  />
                </div>
              </div>
          }
      </div>
        <Modal show={transferModal}
                   title={toggleCreateView ? 'Create New Form & View' : 'Select View To Transfer To'}
                   onHide={onDismiss}>
                {!toggleCreateView && <DropDown id="viewId"
                                                placeholder="Select the view to transfer selected entries to"
                                                items={itemsViews}
                                                value={toViewId}
                                                onSelect={onViewSelect}
                                                type="default"
                />}
                {!toggleCreateView && <>
                    <Button title="Create New Form & View" onClick={toggleCreateViewModal}/>
                    {' '}
                    <Button title="Complete Transfer" disabled={!toViewId} onClick={finalizeEntryTransfer}/>
                </>}
                {toggleCreateView && <>
                    <Input name="title"
                           id="title"
                           label="Title"
                           required
                           value={newFormTitle}
                           onChange={setNewFormTitle}
                           onBlur={setNewFormTitle}
                           placeholder="Insert the title of the new view"
                    />
                    <Input name="description"
                           id="description"
                           label="Description"
                           required
                           value={newFormDescription}
                           onChange={setNewFormDescription}
                           onBlur={setNewFormDescription}
                           placeholder="Insert a description for the new view"
                    />
                    <Button title="Create View" disabled={!newFormTitle || !newFormDescription}
                            onClick={createViewAndForm}/>
                    {' '}
                    <Button title="Cancel" onClick={onDismissViewModal}/>
                </>}
            </Modal>
        <Modal show={openStatusModal} title={'Choose Status'} onHide={onDismissStatusModal}>
          {
          ((accessLevelModal < 2000 || isMadeByMeModal) && !belongsToTulips) &&
          <div><DropDown placeholder="Badge" type="default" items={itemsStatus}
                      value={statusModal} onSelect={(e) => onStatusSelect( e.value, entryIdModal, formIdModal)}/>
          </div>
        }
          {
              belongsToTulips ?
              <div><DropDown placeholder="Badge" type="default" items={ itemsStatusTulipsAdmin }
                             value={statusModal} onSelect={(e) => onStatusSelect( e.value, entryIdModal, formIdModal)}/>
              </div> : null
          }

          </Modal>
        </>
  );
}

export default DynamicView;
