import { ApiDataModel, FormError } from './Common';

export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_RESPONSE = 'FETCH_CONTACTS_RESPONSE';
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE';

export const FETCH_CONTACT_REQUEST = 'FETCH_CONTACT_REQUEST';
export const FETCH_CONTACT_RESPONSE = 'FETCH_CONTACT_RESPONSE';
export const FETCH_CONTACT_FAILURE = 'FETCH_CONTACT_FAILURE';

export const POST_CONTACT_REQUEST = 'POST_CONTACT_REQUEST';
export const POST_CONTACT_RESPONSE = 'POST_CONTACT_RESPONSE';
export const POST_CONTACT_FAILURE = 'POST_CONTACT_FAILURE';

export interface Contact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface ContactDataModel extends Omit<ApiDataModel, 'data'> {
  data: Contact[];
}

export interface ContactState {
  contacts: ContactDataModel | null,
  activeContact: Contact | null,
  isFetchingContacts: boolean;
  fetchingContactsFailed: boolean;
  postingContactFailed?: boolean;
  isPostingContact: boolean;
  postingContactValidationErrors: FormError[] | null;
  postingContactError: string | null;
}

interface FetchContactsRequestAction {
  type: typeof FETCH_CONTACTS_REQUEST;
  payload: null;
}

interface FetchContactsResponseAction {
  type: typeof FETCH_CONTACTS_RESPONSE;
  payload: ContactDataModel;
}

interface FetchContactsFailureAction {
  type: typeof FETCH_CONTACTS_FAILURE;
  payload: null;
}

interface FetchContactRequestAction {
  type: typeof FETCH_CONTACT_REQUEST;
  payload: null;
}

interface FetchContactResponseAction {
  type: typeof FETCH_CONTACT_RESPONSE;
  payload: Contact;
}

interface FetchContactFailureAction {
  type: typeof FETCH_CONTACT_FAILURE;
  payload: null;
}

interface PostContactRequestAction {
  type: typeof POST_CONTACT_REQUEST;
  payload: null;
}

interface PostContactResponseAction {
  type: typeof POST_CONTACT_RESPONSE;
  payload: null;
}

interface PostContactFailureAction {
  type: typeof POST_CONTACT_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type ContactActionTypes =
    | FetchContactsRequestAction
    | FetchContactsResponseAction
    | FetchContactsFailureAction
    | PostContactRequestAction
    | PostContactResponseAction
    | PostContactFailureAction
    | FetchContactRequestAction
    | FetchContactResponseAction
    | FetchContactFailureAction;
