import React from 'react';
import './ProposalCard.scss';
import AssetGallery from '../../AssetGallery';
import { StatusCodesEnum } from '../../../helpers/constants';
import { useHistory } from 'react-router-dom';
import GreenReport from '../../../views/admin/GreenReport/GreenReport';

type Props = {
  title: string;
  description?: string;
  minRate?: number;
  info?: string;
  maxRate?: number;
  actualRate?: number;
  currency?: string;
  proposalURL?: string;
  showGreenReport?: boolean;
  greenDownloadReportURL?: string;
  reviewURL?: string;
  icon?: string;
  statusCode: StatusCodesEnum;
  offerId: string;
  offerDate: string;
  pickupLocation: string;
  solutionLocation: string;
};

const ProposalCard = (props: Props) => {
  const {
    title,
    description,
    info,
    minRate,
    maxRate,
    actualRate,
    proposalURL,
    showGreenReport = false,
    statusCode,
    icon,
    reviewURL,
    greenDownloadReportURL,
    offerId,
    offerDate,
    pickupLocation,
    solutionLocation,
  } = props;

  const history = useHistory();

  const [showModal, setShowModal] = React.useState(false);
  const [triggerDownload, setTriggerDownload] = React.useState(false);

  const getColor = (code: string) => {
    switch (code) {
      case StatusCodesEnum.DRAFT:
        return '#8d8d8d';

      case StatusCodesEnum.CHANGES_REQUESTED:
        return '#dd7e12';

      case StatusCodesEnum.COMPLETED:
        return '#13c730';

      default:
        return '#2C2C2C';
    }
  };

  const onHide = () => {
    setShowModal(false);
    setTriggerDownload(false); // Reset after hiding
  };

  const downloadReportAsPDF = () => {
    setShowModal(true);
    setTriggerDownload(true);  // Trigger the download inside GreenReport
  };

  const reviewStatuses = [
    StatusCodesEnum.PENDING_ESTIMATION,
    StatusCodesEnum.PENDING_VERIFICATION,
    StatusCodesEnum.PENDING_OFFER,
    StatusCodesEnum.CHANGES_REQUESTED,
    StatusCodesEnum.DRAFT,
  ];

  const handleNavigation = (url?: string) => {
    if (url) {
      history.push(url);
    }
  };
  
  const showModalFunc = () => {
    setShowModal(true);
  };

  const hasLinks = proposalURL || showGreenReport;

  return (
        <div className="proposal-card">
            <div className="proposal-card-icon">
                <img src={icon ? icon : AssetGallery.iconProposal} alt={'Proposal Icon'}/>

            </div>
            <div className={`proposal-card-content ${!hasLinks ? 'no-links' : ''}`}>
                <>
                </>
                <h5 className="proposal-card-title proposal-card__info">
                    <div className="proposal-card__info info-box">
                      {info}
                    </div>
                  {title ?? 'Subject Pending'}
                    {actualRate !== undefined && actualRate !== null && actualRate != 0.00 && (
                        <>
                          {' | '}
                      <span>
                          {actualRate} {' '}
                        </span>
                            {props.currency}
                        </>
                    )}
                    {minRate !== undefined && maxRate !== undefined && actualRate == 0.00 && minRate !== maxRate && (
                        <>
                            {' '}
                          {' | '}
                            <span>{minRate}</span> to{' '}
                            <span>{maxRate}</span> {props.currency}
                        </>
                    )}
                    {minRate !== undefined && maxRate !== undefined && actualRate == 0.00 && minRate === maxRate && (
                        <>
                            {' '}
                            {' | '}
                            <span>{maxRate}</span> {props.currency}
                        </>
                    )}
                  {actualRate == null && minRate == null && maxRate == null && (
                      <>
                        {' '}
                        {' | '}
                        <span>Price Pending</span>
                      </>
                  )}
                </h5>
                <p className="proposal-card-description">{description}</p>
                {hasLinks && (
                    <div className="proposal-card-links">
                        {proposalURL && (
                            <button className="details-link" onClick={() => handleNavigation(proposalURL)}>Proposal
                                Details</button>
                        )}
                        {showGreenReport && (
                            <button className="green-report-link" onClick={() => showModalFunc()}>Green
                                Report</button>
                        )}
                    </div>
                )}
            </div>
            <div className="proposal-card-button">
                {(reviewURL && reviewStatuses.includes(statusCode)) && (
                    <button className="proposal-card-review-button"
                            onClick={() => handleNavigation(reviewURL)}>Review</button>
                )}
                {(greenDownloadReportURL && statusCode == StatusCodesEnum.COMPLETED) && (
                    <img src={AssetGallery.greenReportDownload} className="proposal-card-download-button"
                         onClick={downloadReportAsPDF}  // Trigger the download PDF function
                         alt={'green'}/>
                )}
            </div>
            <div className="proposal-card-badge" style={{ color: getColor(statusCode) }}>{statusCode}</div>
            <GreenReport
                showModal={showModal}
                onHide={onHide}
                offerId={offerId}
                proposalTitle={title}
                proposalDate={offerDate}
                pickupLocation={pickupLocation}
                solutionLocation={solutionLocation}
                triggerDownload={triggerDownload}
            />
        </div>
  );
};

export default ProposalCard;