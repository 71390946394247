import {
  FETCH_COMPOSITION_FAILURE,
  FETCH_COMPOSITION_REQUEST,
  FETCH_COMPOSITION_RESPONSE,
  FETCH_COMPOSITIONS_FAILURE,
  FETCH_COMPOSITIONS_REQUEST,
  FETCH_COMPOSITIONS_RESPONSE,
  POST_COMPOSITION_FAILURE,
  POST_COMPOSITION_RESPONSE,
  CompositionActionTypes,
  CompositionState,
  POST_COMPOSITION_REQUEST,
} from '../types/Composition';
import { parseValidationErrors } from '../helpers/functions';

const initialState: CompositionState = {
  compositions: null,
  activeComposition: null,
  isFetchingCompositions: false,
  fetchingCompositionsFailed: false,
  isPostingComposition: false,
  postingCompositionFailed: false,
  postingCompositionError: null,
  postingCompositionValidationErrors: null,
};

const compositionsReducer = (
  state = initialState,
  action: CompositionActionTypes,
): CompositionState => {
  switch (action.type) {
    case FETCH_COMPOSITIONS_REQUEST:
      return {
        ...state,
        compositions: null,
        isFetchingCompositions: true,
        fetchingCompositionsFailed: false,
      };
    case FETCH_COMPOSITIONS_RESPONSE:
      return {
        ...state,
        compositions: action.payload || [],
        isFetchingCompositions: false,
        fetchingCompositionsFailed: false,
      };
    case FETCH_COMPOSITIONS_FAILURE:
      return {
        ...state,
        compositions: null,
        isFetchingCompositions: false,
        fetchingCompositionsFailed: true,
      };
    case FETCH_COMPOSITION_REQUEST:
      return {
        ...state,
        compositions: null,
        isFetchingCompositions: true,
        fetchingCompositionsFailed: false,
      };
    case FETCH_COMPOSITION_RESPONSE:
      return {
        ...state,
        activeComposition: action.payload,
        isFetchingCompositions: false,
        fetchingCompositionsFailed: false,
      };
    case FETCH_COMPOSITION_FAILURE:
      return {
        ...state,
        compositions: null,
        isFetchingCompositions: false,
        fetchingCompositionsFailed: true,
      };
    case POST_COMPOSITION_REQUEST:
      return {
        ...state,
        isPostingComposition: true,
        postingCompositionError: null,
        postingCompositionValidationErrors: null,
        postingCompositionFailed: false,
      };
    case POST_COMPOSITION_RESPONSE:
      return {
        ...state,
        isPostingComposition: false,
        postingCompositionError: null,
        postingCompositionValidationErrors: null,
        postingCompositionFailed: false,
      };
    case POST_COMPOSITION_FAILURE:
      return {
        ...state,
        isPostingComposition: false,
        postingCompositionError: action.payload.error || 'An error occurred',
        postingCompositionValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingCompositionFailed: true,
      };
    default:
      return state;
  }
};

export default compositionsReducer;
