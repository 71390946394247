import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createFieldProperty, fetchActiveFieldProperty, updateFieldProperty } from '../../../actions/FieldProperty';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import { getFieldTypes } from '../../../selectors/FieldType';
import { getFields } from '../../../selectors/Field';
import { getFieldTypeOptions } from '../../../selectors/FieldTypeOption';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import {
  getActiveFieldProperty,
  isPostingFieldProperty,
  postingFieldPropertyError,
  postingFieldPropertyFailed,
  postingFieldPropertyValidationErrors,
  isFetchingFieldProperties,
} from '../../../selectors/FieldProperty';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { fetchClients } from '../../../actions/Client';
import { fetchFieldTypes } from '../../../actions/FieldType';
import { fetchFields } from '../../../actions/Field';
import { fetchFieldTypeOptions } from '../../../actions/FieldTypeOption';

const FieldPropertyForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeFieldProperty = useSelector(getActiveFieldProperty);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const fieldTypes = useSelector(getFieldTypes);
  const fields = useSelector(getFields);
  const fieldTypeOptions = useSelector(getFieldTypeOptions);
  const isPosting = useSelector(isPostingFieldProperty);
  const isFetching = useSelector(isFetchingFieldProperties);
  const postingFailed = useSelector(postingFieldPropertyFailed);
  const postingError = useSelector(postingFieldPropertyError);
  const postingValidationErrors = useSelector(postingFieldPropertyValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.FIELD_PROPERTY_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'fieldTypeId', validators: [isRequired] },
    { field: 'fieldId', validators: [isRequired] },
    { field: 'fieldTypeOptionId', validators: [isRequired] },
    { field: 'value', validators: [isRequired] },
  ];

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsFieldTypes = fieldTypes?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsFields = fields?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsFieldTypeOptions = fieldTypeOptions?.data.map(r => ({ value: r.id, label: r.key })) || [];


  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [fieldTypeId, setFieldTypeId] = useState('');
  const [fieldId, setFieldId] = useState('');
  const [fieldTypeOptionId, setFieldTypeOptionId] = useState('');
  const [unfiltered, setUnfiltered] = useState(true);
  const [filteredFields, setFilteredFields] = useState(itemsFields);
  const [filteredFieldTypeOptions, setFilteredFieldTypeOptions] = useState(itemsFieldTypeOptions);
  const [value, setValue] = useState('');
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchFieldTypes());
    dispatch(fetchFields({ colKey: 'created', sortDirection:  'desc', paging: '1000' }));
    dispatch(fetchFieldTypeOptions());
    if (editMode) {
      dispatch(fetchActiveFieldProperty(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeFieldProperty && editMode) {
      setGroupId(activeFieldProperty?.groupId);
      setClientId(activeFieldProperty?.clientId);
      setFieldTypeId(activeFieldProperty?.fieldTypeId);
      setFieldId(activeFieldProperty?.fieldId);
      setFieldTypeOptionId(activeFieldProperty?.fieldTypeOptionId);
      setValue(activeFieldProperty?.value);
      // @ts-ignore
      setActive(activeFieldProperty?.active === 1 || activeFieldProperty?.active === true);
    }
  }, [activeFieldProperty]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.FIELD_PROPERTY);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createFieldProperty(groupId, clientId, fieldTypeId, fieldId, fieldTypeOptionId, value, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateFieldProperty(activeId, groupId, clientId, fieldTypeId, fieldId, fieldTypeOptionId, value, active));
    }
  };



  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };


  const onFieldTypeSelect = (obj: any) => {

    setFieldTypeId(obj.value);
    setFilteredFields(fields?.data.filter(data => data.fieldTypeId == obj.value ).map(r => ({ label: r.title, value: r.id })) || []);
    setFilteredFieldTypeOptions(fieldTypeOptions?.data.filter(data => data.fieldTypeId == obj.value ).map(r => ({ label: r.key, value: r.id })) || []);
    setUnfiltered(false);


  };

  const onFieldSelect = (obj: any) => {
    setFieldId(obj.value);
  };

  const onFieldTypeOptionSelect = (obj: any) => {
    setFieldTypeOptionId(obj.value);
  };



  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} FieldProperty</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown v={v} err={err}
                          id="groupId"
                          placeholder="Please select a Group"
                          required label="Group"
                          items={itemsGroups}
                          value={groupId}
                          disabled={isPosting || isFetching}
                          onSelect={onGroupSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="clientId"
                          placeholder="Please select a Client"
                          required
                          label="Client"
                          items={itemsClients}
                          disabled={isPosting || isFetching || disabledClient}
                          value={clientId}
                          onSelect={onClientSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="fieldTypeId"
                          placeholder="Please select a Field Type"
                          required
                          label="Field Type"
                          items={itemsFieldTypes}
                          disabled={isPosting || isFetching}
                          value={fieldTypeId}
                          onSelect={onFieldTypeSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="fieldId"
                          placeholder="Please select a Field"
                          required
                          label="Field"
                          items={filteredFields}
                          disabled={unfiltered || isPosting || isFetching}
                          value={fieldId}
                          onSelect={onFieldSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="fieldTypeOptionId"
                          placeholder="Please select a Field Type Option"
                          required
                          label="Field Type Option"
                          items={filteredFieldTypeOptions}
                          disabled={unfiltered || isPosting || isFetching}
                          value={fieldTypeOptionId}
                          onSelect={onFieldTypeOptionSelect}
                          type="default"
                />
                <Input v={v} err={err}
                       name="value"
                       id="value"
                       label="Value"
                       required
                       value={value}
                       onChange={setValue}
                       disabled={isPosting || isFetching}
                       onBlur={setValue}
                       placeholder="Insert a value"
                />
                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default FieldPropertyForm;
