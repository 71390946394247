import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getUsers, isFetchingUsers } from '../../../selectors/User';
import { storeUser, fetchUsers } from '../../../actions/User';
import { User } from '../../../types/User';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';
import { getLoggedInRole } from '../../../selectors/Auth';

const UserDataTable = () => {

  const users = useSelector(getUsers);
  const isLoading = useSelector(isFetchingUsers);
  const [toggle, setToggle] = useState(false);
  const [user, setActiveUser] = useState<User | null>();
  const [refetch, setRefetch] = useState(false);
  const loggedInRole = useSelector(getLoggedInRole);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.USER_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.USER_UPDATE, row.id));
  };

  const onArchive = (row: User) => {
    setActiveUser(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (user?.id) {
      await storeUser(user?.id);
      setRefetch(!refetch);
    }
    setActiveUser(null);
  };

  const config = {
    columns: [
      { key: 'roleTitle', label: 'Role', mobileFriendly: true },
      { key: 'email', label: 'Email', mobileFriendly: true },
      { key: 'username', label: 'Username', mobileFriendly: true },
      { key: 'firstName', label: 'First Name', mobileFriendly: true },
      { key: 'lastName', label: 'Last Name', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'verified', label: 'Verified', mobileFriendly: true },
      { key: 'created', label: 'Created At', mobileFriendly: true },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      {
        icon: 'edit',
        label: '',
        onClick: onUpdate,
        isVisible: (row: User) => (loggedInRole?.accessLevel ?? 2000) <= row.roleAccessLevel,  // Check visibility
      },
      { icon: 'bin', label: '', onClick: onArchive,
        isVisible: (row: User) => (loggedInRole?.accessLevel ?? 2000) <= row.roleAccessLevel,
      },
    ],
    hideButton: true,
  };

  return <View title="Users" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchUsers} paging={users?.paging || []}
                       isLoading={isLoading} data={users?.data || []} config={config} dependencies={refetch}/>
            <Modal show={toggle} title="Archive User" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
                Are you sure you want to delete this user?
            </Modal>
    </View>;
};

export default UserDataTable;
