import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createPlan, fetchActivePlan, fetchPlans, updatePlan } from '../../../actions/Plan';
import { fetchGroups } from '../../../actions/Group';
import {
  getActivePlan,
  isPostingPlan,
  postingPlanError,
  postingPlanFailed,
  postingPlanValidationErrors,
  isFetchingPlans,
} from '../../../selectors/Plan';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';


const PlanForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activePlan = useSelector(getActivePlan);
  const isPosting = useSelector(isPostingPlan);
  const isFetching = useSelector(isFetchingPlans);
  const postingFailed = useSelector(postingPlanFailed);
  const postingError = useSelector(postingPlanError);
  const postingValidationErrors = useSelector(postingPlanValidationErrors);


  const [editMode, activeId] = useEditMode(c.APP_ROUTES.PLAN_UPDATE);

  const formConfig = [
    { field: 'description', validators: [] },
    { field: 'title', validators: [isRequired] },
    { field: 'monthlyFee', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [monthlyFee, setMonthlyFee] = useState(0);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchPlans());
    if (editMode) {
      dispatch(fetchActivePlan(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activePlan && editMode) {
      setTitle(activePlan?.title);
      setDescription(activePlan?.description);
      setMonthlyFee(activePlan?.monthlyFee);
    }
  }, [activePlan]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.PLAN);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createPlan(title, description, monthlyFee));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updatePlan(activeId, title, description, monthlyFee));
    }
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Plan</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>

                <Input
                    v={v}
                    err={err}
                    name="title"
                    id="title"
                    label="Title"
                    required
                    value={title}
                    onChange={setTitle}
                    disabled={isPosting || isFetching}
                    onBlur={setTitle}
                    placeholder="Insert a title"
                />
                <Input
                    v={v}
                    err={err}
                    name="description"
                    id="description"
                    label="Description"
                    required
                    value={description}
                    onChange={setDescription}
                    disabled={isPosting || isFetching}
                    onBlur={setDescription}
                    placeholder="Insert a description"
                />
                <Input
                    v={v}
                    err={err}
                    name="monthlyFee"
                    id="monthlyFee"
                    label="Monthly Fee"
                    type="number"
                    value={monthlyFee}
                    onChange={setMonthlyFee}
                    disabled={isPosting || isFetching}
                    onBlur={setMonthlyFee}
                    placeholder="Insert the Monthly Fee"
                />

                <div className="row">
                    <div className="col-md-4">
                        <Button
                            disabled={!isFormValid()}
                            isLoading={isPosting || isFetching}
                            onClick={() => (editMode ? onSave() : onSubmit())}
                            title={editMode ? 'SAVE' : 'CREATE'}
                        />
                    </div>
                </div>
            </form>
        </View>
  );
};

export default PlanForm;
