import {
  FETCH_OFFER_STATUS_CODE_FAILURE,
  FETCH_OFFER_STATUS_CODE_REQUEST,
  FETCH_OFFER_STATUS_CODE_RESPONSE,
  FETCH_OFFER_STATUS_CODES_FAILURE,
  FETCH_OFFER_STATUS_CODES_REQUEST,
  FETCH_OFFER_STATUS_CODES_RESPONSE,
  POST_OFFER_STATUS_CODE_FAILURE,
  POST_OFFER_STATUS_CODE_RESPONSE,
  OfferStatusCodeActionTypes,
  OfferStatusCodeState,
  POST_OFFER_STATUS_CODE_REQUEST,
} from '../types/OfferStatusCode';
import { parseValidationErrors } from '../helpers/functions';

const initialState: OfferStatusCodeState = {
  offerStatusCodes: null,
  activeOfferStatusCode: null,
  isFetchingOfferStatusCodes: false,
  fetchingOfferStatusCodesFailed: false,
  isPostingOfferStatusCode: false,
  postingOfferStatusCodeFailed: false,
  postingOfferStatusCodeError: null,
  postingOfferStatusCodeValidationErrors: null,
};

const offerStatusCodesReducer = (
  state = initialState,
  action: OfferStatusCodeActionTypes,
): OfferStatusCodeState => {
  switch (action.type) {
    case FETCH_OFFER_STATUS_CODES_REQUEST:
      return {
        ...state,
        offerStatusCodes: null,
        isFetchingOfferStatusCodes: true,
        fetchingOfferStatusCodesFailed: false,
      };
    case FETCH_OFFER_STATUS_CODES_RESPONSE:
      return {
        ...state,
        offerStatusCodes: action.payload || [],
        isFetchingOfferStatusCodes: false,
        fetchingOfferStatusCodesFailed: false,
      };
    case FETCH_OFFER_STATUS_CODES_FAILURE:
      return {
        ...state,
        offerStatusCodes: null,
        isFetchingOfferStatusCodes: false,
        fetchingOfferStatusCodesFailed: true,
      };
    case FETCH_OFFER_STATUS_CODE_REQUEST:
      return {
        ...state,
        activeOfferStatusCode: null,
        isFetchingOfferStatusCodes: true,
        fetchingOfferStatusCodesFailed: false,
      };
    case FETCH_OFFER_STATUS_CODE_RESPONSE:
      return {
        ...state,
        activeOfferStatusCode: action.payload,
        isFetchingOfferStatusCodes: false,
        fetchingOfferStatusCodesFailed: false,
      };
    case FETCH_OFFER_STATUS_CODE_FAILURE:
      return {
        ...state,
        activeOfferStatusCode: null,
        isFetchingOfferStatusCodes: false,
        fetchingOfferStatusCodesFailed: true,
      };
    case POST_OFFER_STATUS_CODE_REQUEST:
      return {
        ...state,
        isPostingOfferStatusCode: true,
        postingOfferStatusCodeError: null,
        postingOfferStatusCodeValidationErrors: null,
        postingOfferStatusCodeFailed: false,
      };
    case POST_OFFER_STATUS_CODE_RESPONSE:
      return {
        ...state,
        isPostingOfferStatusCode: false,
        postingOfferStatusCodeError: null,
        postingOfferStatusCodeValidationErrors: null,
        postingOfferStatusCodeFailed: false,
      };
    case POST_OFFER_STATUS_CODE_FAILURE:
      return {
        ...state,
        isPostingOfferStatusCode: false,
        postingOfferStatusCodeError: action.payload.error || 'An error occurred',
        postingOfferStatusCodeValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingOfferStatusCodeFailed: true,
      };
    default:
      return state;
  }
};

export default offerStatusCodesReducer;
