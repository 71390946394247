import { ApiDataModel, FormError } from './Common';

export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_RESPONSE = 'FETCH_TRANSACTIONS_RESPONSE';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';

export const FETCH_TRANSACTION_REQUEST = 'FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_RESPONSE = 'FETCH_TRANSACTION_RESPONSE';
export const FETCH_TRANSACTION_FAILURE = 'FETCH_TRANSACTION_FAILURE';

export const POST_TRANSACTION_REQUEST = 'POST_TRANSACTION_REQUEST';
export const POST_TRANSACTION_RESPONSE = 'POST_TRANSACTION_RESPONSE';
export const POST_TRANSACTION_FAILURE = 'POST_TRANSACTION_FAILURE';

export interface Transaction {
  id: string;
  oppTransactionId: string,
  status: string,
  merchant_uid: string,
  profile_uid: string,
  amount: number,
  currency: string,
  payment_method: string,
  payment_flow: string,
  redirect_url: string,
  createdAt: Date;
  createdBy: Date;
  updatedAt: Date;
  updatedBy: Date;
}

export interface TransactionDataModel extends Omit<ApiDataModel, 'data'> {
  data: Transaction[];
}

export interface TransactionState {
  transactions: TransactionDataModel | null;
  activeTransaction: Transaction | null;
  isFetchingTransactions: boolean;
  fetchingTransactionsFailed: boolean;
  postingTransactionFailed?: boolean;
  isPostingTransaction: boolean;
  postingTransactionValidationErrors: FormError[] | null;
  postingTransactionError: string | null;
}

interface FetchTransactionsRequestAction {
  type: typeof FETCH_TRANSACTIONS_REQUEST;
  payload: null;
}

interface FetchTransactionsResponseAction {
  type: typeof FETCH_TRANSACTIONS_RESPONSE;
  payload: TransactionDataModel;
}

interface FetchTransactionsFailureAction {
  type: typeof FETCH_TRANSACTIONS_FAILURE;
  payload: null;
}

interface FetchTransactionRequestAction {
  type: typeof FETCH_TRANSACTION_REQUEST;
  payload: null;
}

interface FetchTransactionResponseAction {
  type: typeof FETCH_TRANSACTION_RESPONSE;
  payload: Transaction;
}

interface FetchTransactionFailureAction {
  type: typeof FETCH_TRANSACTION_FAILURE;
  payload: null;
}

interface PostTransactionRequestAction {
  type: typeof POST_TRANSACTION_REQUEST;
  payload: null;
}

interface PostTransactionResponseAction {
  type: typeof POST_TRANSACTION_RESPONSE;
  payload: null;
}

interface PostTransactionFailureAction {
  type: typeof POST_TRANSACTION_FAILURE;
  payload: {
    error?: string;
    validationErrors?: any;
  };
}

export type TransactionActionTypes =
    | FetchTransactionsRequestAction
    | FetchTransactionsResponseAction
    | FetchTransactionsFailureAction
    | PostTransactionRequestAction
    | PostTransactionResponseAction
    | PostTransactionFailureAction
    | FetchTransactionRequestAction
    | FetchTransactionResponseAction
    | FetchTransactionFailureAction;
