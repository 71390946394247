import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_RESPONSE,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_TRANSACTION_REQUEST,
  FETCH_TRANSACTION_RESPONSE,
  FETCH_TRANSACTION_FAILURE,
  POST_TRANSACTION_FAILURE,
  POST_TRANSACTION_REQUEST,
  POST_TRANSACTION_RESPONSE,
  TransactionActionTypes, TransactionDataModel, Transaction,
} from '../types/Transaction';

import { RootState } from '../store/configureStore';

import { archiveTransaction, getTransaction, getTransactions, postTransaction, putTransaction } from '../api/Transaction';
import { DataTableParamsModel } from '../types/Common';

export function fetchTransactionsRequest(): TransactionActionTypes {
  return {
    type: FETCH_TRANSACTIONS_REQUEST,
    payload: null,
  };
}

export function fetchTransactionsResponse(
  transactions: TransactionDataModel,
): TransactionActionTypes {
  return {
    type: FETCH_TRANSACTIONS_RESPONSE,
    payload: transactions,
  };
}

export function fetchTransactionsFailure(): TransactionActionTypes {
  return {
    type: FETCH_TRANSACTIONS_FAILURE,
    payload: null,
  };
}

export function fetchTransactionRequest(): TransactionActionTypes {
  return {
    type: FETCH_TRANSACTION_REQUEST,
    payload: null,
  };
}

export function fetchTransactionResponse(
  transaction: Transaction,
): TransactionActionTypes {
  return {
    type: FETCH_TRANSACTION_RESPONSE,
    payload: transaction,
  };
}

export function fetchTransactionFailure(): TransactionActionTypes {
  return {
    type: FETCH_TRANSACTION_FAILURE,
    payload: null,
  };
}

export function postTransactionRequest(): TransactionActionTypes {
  return {
    type: POST_TRANSACTION_REQUEST,
    payload: null,
  };
}

export function postTransactionResponse(): TransactionActionTypes {
  return {
    type: POST_TRANSACTION_RESPONSE,
    payload: null,
  };
}

export function postTransactionFailure(error: string, validationErrors: any): TransactionActionTypes {
  return {
    type: POST_TRANSACTION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveTransaction = (id: string, dataTableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchTransactionRequest());
    const asyncResp: any = await getTransaction(id, dataTableParams);
    if (asyncResp?.success) {
      await dispatch(fetchTransactionResponse(asyncResp.data));
    } else {
      await dispatch(fetchTransactionFailure());
    }
  };

export const fetchTransactions = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchTransactionsRequest());
    const asyncResp: any = await getTransactions(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchTransactionsResponse(asyncResp));
    } else {
      await dispatch(fetchTransactionsFailure());
    }
  };

export const storeTransaction = async (id: string) => {
  try {
    await archiveTransaction(id);
  } catch (error) {
    throw error;
  }
};

export const createTransaction = (
  oppTransactionId: string, status: string, merchant_uid: string, profile_uid: string,
  amount: number, currency: string, payment_method: string, payment_flow: string, redirect_url: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postTransactionRequest());
    const asyncResp: any = await postTransaction(oppTransactionId, status, merchant_uid, profile_uid, amount, currency, payment_method, payment_flow, redirect_url);
    if (asyncResp?.success) {
      await dispatch(postTransactionResponse());
    } else {
      await dispatch(postTransactionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


export const updateTransaction = (
  id: string,
  oppTransactionId: string, status: string, merchant_uid: string, profile_uid: string,
  amount: number, currency: string, payment_method: string, payment_flow: string, redirect_url: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postTransactionRequest());
    const asyncResp: any = await putTransaction(id, oppTransactionId, status, merchant_uid, profile_uid, amount, currency, payment_method, payment_flow, redirect_url);
    if (asyncResp?.success) {
      await dispatch(postTransactionResponse());
    } else {
      await dispatch(postTransactionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
