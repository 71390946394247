import { RootState } from '../store/configureStore';

export const getOffers = (state: RootState) => state?.offer?.offers;

export const getActiveOffer = (state: RootState) => state?.offer?.activeOffer;

export const isFetchingOffers = (state: RootState) => state?.offer?.isFetchingOffers;

export const isPostingOffer = (state: RootState) => state?.offer?.isPostingOffer;
export const postingOfferFailed = (state: RootState) => state?.offer?.postingOfferFailed;
export const postingOfferError = (state: RootState) => state?.offer?.postingOfferError;
export const postingOfferValidationErrors = (state: RootState) => state?.offer?.postingOfferValidationErrors;
export const fetchingOffersFailed = (state: RootState) => state?.offer?.fetchingOffersFailed;