import {
  FETCH_REPORTS_FAILURE,
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_RESPONSE,
  ReportActionTypes,
  ReportItemState,
} from '../types/GreenReport';

const initialState: ReportItemState = {
  reports: null,
  isFetchingReports: false,
  fetchingReportsFailed: false,
};

const greenReportReducer = (
  state = initialState,
  action: ReportActionTypes,
): ReportItemState => {
  switch (action.type) {
    case FETCH_REPORTS_REQUEST:
      return {
        ...state,
        reports: null,
        isFetchingReports: true,
        fetchingReportsFailed: false,
      };
    case FETCH_REPORTS_RESPONSE:
      return {
        ...state,
        reports: action.payload || [],
        isFetchingReports: false,
        fetchingReportsFailed: false,
      };
    case FETCH_REPORTS_FAILURE:
      return {
        ...state,
        reports: null,
        isFetchingReports: false,
        fetchingReportsFailed: true,
      };
    default:
      return state;
  }
};

export default greenReportReducer;
