import React, { useEffect, useState } from 'react';
import Button from '../Button';
import Input from '../Input';
import './PasswordEdit.scss';
import { isRequired } from '../../helpers/validators';
import { useValidation } from '../../hooks/ValidationHook';
import c from '../../helpers/constants';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  isFetchingUsers,
  isPostingUser,
  postingUserError,
  postingUserFailed,
  postingUserValidationErrors,
} from '../../selectors/User';
import { updatePassword } from '../../actions/Auth';
import { getLoggedInUser } from '../../selectors/Auth';




const PasswordEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingUser);
  const isFetching = useSelector(isFetchingUsers);
  const postingFailed = useSelector(postingUserFailed);
  const postingError = useSelector(postingUserError);
  const postingValidationErrors = useSelector(postingUserValidationErrors);
  const loggedInUser = useSelector(getLoggedInUser);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [toggle, setToggle] = useState(false);

  const formConfig = [
    { field: 'oldPassword', validators: [isRequired] },
    { field: 'newPassword', validators: [isRequired] },
    { field: 'confirmPassword', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  useEffect(() => {

    if (confirmPassword == newPassword && confirmPassword && newPassword){
      setIsConfirmed(true);
    }
    if (confirmPassword != newPassword && confirmPassword && newPassword){
      setIsConfirmed(false);
    }

  }, [dispatch, confirmPassword, newPassword]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.PROFILE);
      setToggle(true);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      if (loggedInUser){
        dispatch(updatePassword(loggedInUser.id, oldPassword, newPassword));
      }
    }
  };
  return (
        <div className="edit-container">
            <h4>Edit Password</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <div />
            <form>
              <div className={'row'}>
                <div className="col-md-3 col-12">
                <Input v={v} err={err}
                       name="oldPassword"
                       id="oldPassword"
                       label="Old Password"
                       type="password"
                       required
                       value={oldPassword}
                       onChange={setOldPassword}
                       disabled={isPosting || isFetching}
                       onBlur={setOldPassword}
                       placeholder="Insert Old Password"
                />
                </div>
                <div className="col-md-3 col-12">
                <Input v={v} err={err}
                       name="newPassword"
                       id="newPassword"
                       label="New Password"
                       type="password"
                       required
                       value={newPassword}
                       onChange={setNewPassword}
                       disabled={isPosting || isFetching}
                       onBlur={setNewPassword}
                       placeholder="Insert New Password"
                />
                </div>
                <div className="col-md-3 col-12">
                <Input v={v} err={err}
                       name="confirmPassword"
                       id="confirmPassword"
                       label="Confirm Password"
                       type="password"
                       required
                       value={confirmPassword}
                       onChange={setConfirmPassword}
                       disabled={isPosting || isFetching}
                       onBlur={setConfirmPassword}
                       placeholder="Confirm Password"
                />
                </div>
              </div>
                {!isConfirmed && confirmPassword && newPassword && <div className="col-auto error-container-pwd">Passwords are not matching</div>}
            <div>
                <Button disabled={!isFormValid() || !isConfirmed} isLoading={isPosting || isFetching}
                        onClick={() =>  onSubmit()}
                        title={'Submit'}/>
            </div>
                {toggle && <div className="col-auto valid-container-pwd">Password updated!</div>}
            </form>
        </div>
  );
};

export default PasswordEdit;

