import React, { useEffect, useState } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { createContact } from '../../actions/Contact';
import { isRequired } from '../../helpers/validators';
import { useValidation } from '../../hooks/ValidationHook';
import { Alert } from 'react-bootstrap';
import { getLoggedInUser } from '../../selectors/Auth';
import {
  isFetchingContacts,
  isPostingContact, postingContactError,
  postingContactFailed,
  postingContactValidationErrors,
} from '../../selectors/Contact';
import { XCircleFill } from 'react-bootstrap-icons';

const UserForm = () => {
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingContact);
  const isFetching = useSelector(isFetchingContacts);
  const postingFailed = useSelector(postingContactFailed);
  const postingError = useSelector(postingContactError);
  const postingValidationErrors = useSelector(postingContactValidationErrors);
  const loggedInUser = useSelector(getLoggedInUser);
  const isEstimationForm = location.pathname.startsWith('/admin/deal/estimation');

  const formConfig = [
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
    { field: 'email', validators: [isRequired] },
    { field: 'companyName', validators: [isRequired] },
    { field: 'phoneNumber', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err, clearErrors } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation() && loggedInUser) {
      setHasSubmitted(true);
      dispatch(createContact(firstName, lastName, email, phoneNumber, companyName));
    }
  };

  return <>
      {isEstimationForm ? <h4>Create Contact</h4> : <h4>Create Project Manager</h4>}
        {postingError && !isFormValid() && (
            <Alert className="mt-3" variant="danger">
                <div className="row justify-content-between">
                    <div className="col-4">{postingError}</div>
                    <div style = {{ cursor:'pointer' }} className="col-4 text-end" onClick={() => clearErrors()}><XCircleFill/></div>
                </div>
            </Alert>
        )}
        <form>
            <Input v={v} err={err}
                   required
                   name="firstName"
                   id="firstName"
                   label="First Name"
                   value={firstName}
                   disabled={isPosting || isFetching}
                   onChange={setFirstName}
                   onBlur={setFirstName}
                   placeholder="Insert a first name"
            />
            <Input v={v} err={err}
                   required
                   name="lastName"
                   id="lastName"
                   label="Last Name"
                   value={lastName}
                   disabled={isPosting || isFetching}
                   onChange={setLastName}
                   onBlur={setLastName}
                   placeholder="Insert a last name"
            />
            <Input v={v} err={err}
                   name="email"
                   id="email"
                   label="Email"
                   required
                   value={email}
                   onChange={setEmail}
                   disabled={isPosting || isFetching}
                   onBlur={setEmail}
                   placeholder="Insert a title"
            />
            <Input v={v} err={err}
                   required
                   name="companyName"
                   id="companyName"
                   label="Company Name"
                   value={companyName}
                   disabled={isPosting || isFetching}
                   onChange={setCompanyName}
                   onBlur={setCompanyName}
                   placeholder="Insert a Company Name"
            />
            <Input v={v} err={err}
                   required
                   type={'tel'}
                   name="phoneNumber"
                   id="phoneNumber"
                   label="Phone Number"
                   value={phoneNumber}
                   disabled={isPosting || isFetching}
                   onChange={setPhoneNumber}
                   onBlur={setPhoneNumber}
                   placeholder="Insert a Phone Number"
            />
            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={'CREATE'}/>
                </div>
            </div>
        </form>
    </>;
};

export default UserForm;




