import { X } from 'react-bootstrap-icons';
import Input from '../Input';
import Button from '../Button';
import React, { useEffect, useState } from 'react';
import { useValidation } from '../../hooks/ValidationHook';
import { isRequired } from '../../helpers/validators';
import noImage from '../../assets/images/no-preview.png';
import { isPostingAsset } from '../../selectors/Asset';
import { useSelector } from 'react-redux';
import { useMobile } from '../../helpers/functions';


type Props = {
  file: any;
  index: number;
  onSubmit: (file: any, title: string, index: number) => void;
  onDelete: (index: number) => void;
  isValid?: boolean;
};

const FileForm = (props: Props) => {
  const { file, index, onSubmit, onDelete, isValid = true } = props;
  const isMobile = useMobile();
  const [title, setTitle] = useState(() => {
    const parts = file.name.split('.');
    parts.pop(); // Remove the last element (file extension)
    return parts.join('.');
  });
  const isPosting = useSelector(isPostingAsset);
  const formConfig = [
    { field: 'title', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, []);

  const onInnerSubmit = (f: any, t: string) => {

    if (!isMobile ? (triggerValidation() && !isPosting) : !isPosting) {
      onSubmit(f, t, index);
    }
  };

  // Automatically submit form if title already exists
  useEffect(() => {
    if (title && file && isMobile ) {
      onInnerSubmit(file, title);
    }
  }, [title, file]);

  return (

        <div className="row " style={{ visibility: isMobile ? 'hidden' : 'visible', height: isMobile ? '1px' : '100%' }}>
            <div className="col-md-6">
                <div className="img-delete" onClick={() => onDelete(index)}>
                    <X />
                </div>
                <img
                    src={file.preview}
                    alt={file.path}
                    className={
                        isValid
                          ? 'img-preview img-thumbnail'
                          : 'img-preview img-thumbnail error'
                    }
                    onError={(event) => (event.currentTarget.src = noImage)}
                />
            </div>
            <div className="col-md-6">
                {isValid && (
                    <>
                        <Input
                            v={v}
                            err={err}
                            id="title"
                            label="Title"
                            required
                            onChange={setTitle}
                            onBlur={setTitle}
                            value={title}
                        />
                        <Button
                            title="Upload"
                            isLoading={isPosting}
                            disabled={!isFormValid() || !isValid}
                            onClick={() => onInnerSubmit(file, title)}
                        />
                    </>
                )}
            </div>
        </div>
  );
};

export default FileForm;
