import { RootState } from '../store/configureStore';

export const getCompositions = (state: RootState) => state?.composition?.compositions;

export const getActiveComposition = (state: RootState) => state?.composition?.activeComposition;

export const isFetchingCompositions = (state: RootState) => state?.composition?.isFetchingCompositions;

export const isPostingComposition = (state: RootState) => state?.composition?.isPostingComposition;
export const postingCompositionFailed = (state: RootState) => state?.composition?.postingCompositionFailed;
export const postingCompositionError = (state: RootState) => state?.composition?.postingCompositionError;
export const postingCompositionValidationErrors = (state: RootState) => state?.composition?.postingCompositionValidationErrors;
export const fetchingCompositionsFailed = (state: RootState) => state?.composition?.fetchingCompositionsFailed;