import {
  FETCH_CONTACT_FAILURE,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_RESPONSE,
  FETCH_CONTACTS_FAILURE,
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_RESPONSE,
  POST_CONTACT_FAILURE,
  POST_CONTACT_RESPONSE,
  ContactActionTypes,
  ContactState,
  POST_CONTACT_REQUEST,
} from '../types/Contact';
import { parseValidationErrors } from '../helpers/functions';

const initialState: ContactState = {
  contacts: null,
  activeContact: null,
  isFetchingContacts: false,
  fetchingContactsFailed: false,
  isPostingContact: false,
  postingContactFailed: false,
  postingContactError: null,
  postingContactValidationErrors: null,
};

const contactsReducer = (
  state = initialState,
  action: ContactActionTypes,
): ContactState => {
  switch (action.type) {
    case FETCH_CONTACTS_REQUEST:
      return {
        ...state,
        contacts: null,
        isFetchingContacts: true,
        fetchingContactsFailed: false,
      };
    case FETCH_CONTACTS_RESPONSE:
      return {
        ...state,
        contacts: action.payload || [],
        isFetchingContacts: false,
        fetchingContactsFailed: false,
      };
    case FETCH_CONTACTS_FAILURE:
      return {
        ...state,
        contacts: null,
        isFetchingContacts: false,
        fetchingContactsFailed: true,
      };
    case FETCH_CONTACT_REQUEST:
      return {
        ...state,
        contacts: null,
        isFetchingContacts: true,
        fetchingContactsFailed: false,
      };
    case FETCH_CONTACT_RESPONSE:
      return {
        ...state,
        activeContact: action.payload,
        isFetchingContacts: false,
        fetchingContactsFailed: false,
      };
    case FETCH_CONTACT_FAILURE:
      return {
        ...state,
        contacts: null,
        isFetchingContacts: false,
        fetchingContactsFailed: true,
      };
    case POST_CONTACT_REQUEST:
      return {
        ...state,
        isPostingContact: true,
        postingContactError: null,
        postingContactValidationErrors: null,
        postingContactFailed: false,
      };
    case POST_CONTACT_RESPONSE:
      return {
        ...state,
        isPostingContact: false,
        postingContactError: null,
        postingContactValidationErrors: null,
        postingContactFailed: false,
      };
    case POST_CONTACT_FAILURE:
      return {
        ...state,
        isPostingContact: false,
        postingContactError: action.payload.error || 'An error occurred',
        postingContactValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingContactFailed: true,
      };
    default:
      return state;
  }
};

export default contactsReducer;
