import React, { useState } from 'react';
import './Subscription.scss';
import Button from '../../components/Button';
import EMEModal from '../../components/Modal';
import { postSubscription } from '../../api/Auction';

interface TransactionResponse {
  redirect_url: string;
  amount: number;
  status: string;
}

const Subscription = () => {
  const [selectedTier, setSelectedTier] = useState('');
  const [transactionData, setTransactionData] = useState<TransactionResponse | null>(null); // To store transaction data
  const [showModal, setShowModal] = useState(false); // Modal state


  const handleSelectTier = async (tier: string) => {
    setSelectedTier(tier);
    let price = 0; // Default price

    // Switch to determine price based on selected tier
    switch (tier) {
      case 'Free':
        price = 1; // Set your price for basic tier
        break;
      case 'Gold':
        price = 350000; // Set your price for standard tier
        break;
      case 'Platinum':
        price = 750000; // Set your price for premium tier
        break;
      default:
        console.log('Invalid tier selected');
        return; // Exit early if an invalid tier is selected
    }

    // Create transaction with OPP
    const asyncResp = await postSubscription('group', 'client', tier, price);

    console.log(asyncResp, 'asyncresp');
    if (asyncResp?.success && asyncResp?.data) {
      setTransactionData(asyncResp?.data);
      setShowModal(true); // Open modal after transaction is created
    }
  };
  console.log(transactionData, 'transactiondata');

  const handleModalClose = () => setShowModal(false);
  const handleProceedToBank = () => {
    if (transactionData && transactionData?.redirect_url)
      window.location.href = transactionData?.redirect_url; // Redirect to OPP payment page
  };
  

  return (
        <div className="subscription-container">
            <h1>Select Your Subscription Tier</h1>
            <div className="subscription-container__card-container">
                <div className="subscription-container__card" onClick={() => handleSelectTier('Free')}>
                    <h2>Free</h2>
                    <p>Price: 0 EUR</p>
                </div>
                <div className="subscription-container__card" onClick={() => handleSelectTier('Gold')}>
                    <h2>Gold</h2>
                    <p>Price: 3.5K EUR</p>
                </div>
                <div className="subscription-container__card" onClick={() => handleSelectTier('Platinum')}>
                    <h2>Platinum</h2>
                    <p>Price: 7.5K EUR</p>
                </div>
            </div>

            {selectedTier && (
                <div className="selected-tier">
                    <h3>You selected the {selectedTier} Tier</h3>
                </div>
            )}
            <EMEModal
                show={showModal}
                onHide={handleModalClose}
                title="You will be redirected to a safe payments environment"
            >
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <Button onClick={handleModalClose} title="Cancel"></Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleProceedToBank} title="Proceed"></Button>
                    </div>
                </div>
            </EMEModal>
        </div>
  );
};

export default Subscription;
