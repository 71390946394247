import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c  from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getContacts, isFetchingContacts } from '../../../selectors/Contact';
import { fetchContacts, storeContact } from '../../../actions/Contact';
import { Contact } from '../../../types/Contact';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const ContactDataTable = () => {

  const contacts = useSelector(getContacts);
  const isLoading = useSelector(isFetchingContacts);
  const [toggle, setToggle] = useState(false);
  const [contact, setActiveContact] = useState<Contact | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.CONTACT_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.CONTACT_UPDATE, row.id));
  };

  const onArchive = (row: Contact) => {
    setActiveContact(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (contact?.id) {
      await storeContact(contact?.id);
      setRefetch(!refetch);
    }
    setActiveContact(null);
  };

  const config = {
    columns: [
      { key: 'firstName', label: 'First Name', mobileFriendly: false },
      { key: 'lastName', label: 'Last Name', mobileFriendly: true },
      { key: 'email', label: 'Email', mobileFriendly: true },
      { key: 'phoneNumber', label: 'Phone Number', mobileFriendly: false },
      { key: 'companyName', label: 'Company Name', mobileFriendly: true },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };


  return  <View title="Contacts" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchContacts} data={contacts?.data || []} isLoading={isLoading}
                   paging={contacts?.paging || []} config={config} dependencies={refetch} />
        <Modal show={toggle} title="Archive Contact" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
            Are you sure you want to archive this contact?
        </Modal>
    </View>;
};

export default ContactDataTable;
