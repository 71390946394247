import React, { useState, useEffect } from 'react';
import './GreenReport.scss';
import Chart from '../../../components/Chart';
import { Pie, Cell } from 'recharts';
import { useDispatch } from 'react-redux';
// import { getReports } from '../../../selectors/GreenReport';
import { fetchReports } from '../../../actions/GreenReport';
// import { ReportItemDataModel } from '../../../types/GreenReport';
import AssetGallery from '../../../components/AssetGallery';
import { TransportMethod } from '../../../helpers/constants';


type Props = {
  showModal: boolean,
  proposalTitle?: string,
  proposalDate?: string,
  onHide?: () => void,
  offerId: string,
  pickupLocation: string,
  solutionLocation: string,
  triggerDownload?: boolean;
};

const data = [
  { name: 'Group A', value: 80.2 },
  { name: 'Group B', value: 19.5 },
  { name: 'Group C', value: 0.3 },
];

const COLORS = ['#1488C7', '#809DAD', '#D2E8F4'];

const GreenReport = (props: Props) => {
  const {
    showModal,
    proposalTitle,
    proposalDate,
    onHide,
    offerId,
    pickupLocation,
    solutionLocation,
    triggerDownload,
  } = props;

  const dispatch = useDispatch();

  // const reports = useSelector(getReports);
  // const isFetching = useSelector(isFetchingReports);

  const [chartData, setChartData] = useState<{ name: string, value: number, offer_id: string, percentage: number, color: string }[] | null>(null);
  const [totalValue, setTotalValue] = useState<number | 0>(0);
  const [copper, setCopper] = useState<number | 0>(0);
  const [metal, setMetal] = useState<number | 0>(0);
  const [plastic, setPlastic] = useState<number | 0>(0);
  const [otherMaterials, setOtherMaterials] = useState<number | 0>(0);
  const [SF6, setSF6] = useState<number | 0>(0);
  const [allData, setAllData] = useState<{ name: string, value: number, color: string }[] | undefined>(undefined);
  const [distance, setDistance] = useState<any | 0>(0);
  const [CO2Tranport, setCO2Tranport] = useState<number | 0>(0);
  const [CO2Materials, setCO2Materials] = useState<number | 0>(0);
  const [CO2Saved, setCO2Saved] = useState<number | 0>(0);
  const [energyRecover, setEnergyRecover] = useState<number | 0>(0);
  const [disposal, setDisposal] = useState<number | 0>(0);
  const [totalEstimation, setTotalEstimation] = useState<number | 0>(0);
  const [energyRecoverPer, setEnergyRecoverPer] = useState<number | 0>(0);
  const [disposalPer, setDisposalPer] = useState<number | 0>(0);
  const [totalPer, setTotalPer] = useState<number | 0>(0);
  let formattedDate;

  const getRandomValue = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  // Parse the date string into a Date object
  if (proposalDate != null) {
    const date = new Date(Date.parse(proposalDate));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    formattedDate = `${day}-${month}-${year}`;
  }

  // const haversineDistance = (coords1: { lat: number, lng: number }, coords2: { lat: number, lng: number }) => {
  //   const toRadians = (degrees: any) => {
  //     if (typeof degrees !== 'number' || isNaN(degrees)) {
  //       throw new Error('Invalid input: Latitude and Longitude must be numbers.');
  //     }
  //     return degrees * (Math.PI / 180);
  //   };
  //
  //   const lat1 = coords1.lat;
  //   const lon1 = coords1.lng;
  //   const lat2 = coords2.lat;
  //   const lon2 = coords2.lng;
  //
  //   const R = 6371; // Radius of the Earth in kilometers
  //   const dLat = toRadians(lat2 - lat1);
  //   const dLon = toRadians(lon2 - lon1);
  //
  //   const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //       Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
  //       Math.sin(dLon / 2) * Math.sin(dLon / 2);
  //
  //   console.log('A value', a);
  //
  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //
  //   console.log('C value', c);
  //   return R * c;
  // };

  // const filterCoordinatesFromLocation = (location: string) => {
  //   if (location.trim().includes('~')) {
  //     const parts = location.split('~');
  //
  //     const lat = parseFloat(parts[0].replace(/[()]/g, ''));
  //     const lon = parseFloat(parts[1].replace(/[()]/g, ''));
  //     return { lat: lat, lng: lon };
  //   } else {
  //     return {
  //       lat: 51.5072,
  //       lng: 0.1276,
  //     };
  //   }
  // };

  // const getPoints = (r: ReportItemDataModel | null) => {
  const getPoints = () => {
    // if (r?.data) {
    //   const newChartData = r.data.map((rx) => ({
    //     name: rx.material,
    //     value: parseFloat(rx.total_CO2),
    //     offer_id: offerId,
    //   }));

    // let copperSum = 0;
    // let metalSum = 0;
    // let plasticSum = 0;
    // let sf6Sum = 0;
    // let otherMaterialsSum = 0;
    //
    // newChartData.forEach((item) => {
    //   if (item.name === 'Copper') {
    //     copperSum += item.value;
    //   } else if (['Steel', 'Iron', 'Aluminium', 'Other Metals'].includes(item.name)) {
    //     metalSum += item.value;
    //   } else if (item.name === 'Plastics') {
    //     plasticSum += item.value;
    //   } else if (item.name === 'SF6') {
    //     sf6Sum += item.value;
    //   } else {
    //     otherMaterialsSum += item.value;
    //   }
    // });

    console.log(solutionLocation);
    console.log(pickupLocation);

    const copperSum = getRandomValue(50, 150);
    const metalSum = getRandomValue(200, 300);
    const plasticSum = getRandomValue(10, 100);
    const sf6Sum = getRandomValue(5, 20);
    const otherMaterialsSum = getRandomValue(100, 200);

    const distanceValue = getRandomValue(50, 300); // Random distance value
    const CO2MaterialsValue = getRandomValue(100, 500); // Random CO2Materials value
    const CO2TransportValue = getRandomValue(50, 200); // Random CO2Transport value
    const CO2SavedValue = getRandomValue(50, 250);


    setDistance(distanceValue);
    setCopper(copperSum);
    setMetal(metalSum);
    setPlastic(plasticSum);
    setSF6(sf6Sum);
    setOtherMaterials(otherMaterialsSum);

    setCO2Tranport(distance * TransportMethod.TRUCK);


    const allMaterialData = [
      { name: 'COPPER', value: copperSum, offer_id: offerId, color: '#1488C7' },
      { name: 'METAL', value: metalSum, offer_id: offerId, color: '#809DAD' },
      { name: 'PLASTICS', value: plasticSum, offer_id: offerId, color: '#D2E8F4' },
      { name: 'OTHER MATERIALS', value: otherMaterialsSum, offer_id: offerId, color: '#D5D9DB' },
      { name: 'SF6', value: sf6Sum, offer_id: offerId, color: '#FFFFFF' },
    ];

    console.log('All Materials Data', allMaterialData);

    const graphData = [
      { name: 'COPPER', value: copperSum, color: '#1488C7' },
      { name: 'METAL', value: metalSum, color: '#809DAD' },
      { name: 'PLASTICS', value: plasticSum, color: '#D2E8F4' },
      { name: 'OTHER MATERIALS', value: otherMaterialsSum, color: '#D5D9DB' },
      { name: 'SF6', value: sf6Sum, color: '#FFFFFF' },
    ];

    setAllData(graphData);

    // const totalCo2Materials = newChartData.reduce((acc, curr) => acc + curr.value, 0);
    // setCO2Materials(totalCo2Materials);
    setCO2Materials(CO2MaterialsValue);
    setCO2Tranport(CO2TransportValue);
    setCO2Saved(CO2SavedValue);

    const totalV = copperSum + metalSum + plasticSum + otherMaterialsSum;
    const chartDataWithPercentages = allMaterialData.map((item) => ({
      ...item,
      percentage: totalV != 0 ? (item.value / totalV) * 100 : 0,
    }));

    const energyRecoverValue = getRandomValue(20, 100);
    const disposalValue = getRandomValue(20, 100);
    const totalEstimationValue = totalV + energyRecoverValue + disposalValue;
    const totalVPer = (totalV / totalEstimationValue) * 100;
    const energyRecoverPerValue = (energyRecoverValue / totalEstimationValue) * 100;
    const disposalPerValue = (disposalValue / totalEstimationValue) * 100;


    console.log(chartDataWithPercentages);

    setChartData(chartDataWithPercentages);
    setTotalValue(totalV);
    setEnergyRecover(energyRecoverValue);
    setDisposal(disposalValue);
    setTotalEstimation(totalEstimationValue);
    setTotalPer(totalVPer);
    setEnergyRecoverPer(energyRecoverPerValue);
    setDisposalPer(disposalPerValue);
  };


  // const getDistance = () => {
  //   console.log(solutionLocation);
  //   console.log(pickupLocation);
  //   if ((solutionLocation != undefined && pickupLocation != undefined) ) {
  //     let solutionCoordinates = filterCoordinatesFromLocation(solutionLocation);
  //     let pickupCoordinates = filterCoordinatesFromLocation(pickupLocation);
  //
  //     console.log(pickupCoordinates);
  //     console.log(solutionCoordinates);
  //
  //     const newDistance = haversineDistance(pickupCoordinates, solutionCoordinates).toFixed(2);
  //
  //     console.log(`New Distance: ${newDistance}`);
  //     setDistance(newDistance);
  //     return newDistance;
  //   } else {
  //     return 0;
  //   }
  // };

  //const filteredChartData = chartData?.filter(d => d.offer_id === offerId);
  useEffect(() => {
    if (showModal) {
      dispatch(fetchReports(offerId));
      // getDistance();
    } else {
      setChartData([]);
      setCO2Saved(0);
      setCO2Materials(0);
      setDistance(0);
    }
  }, [dispatch, offerId, showModal, CO2Materials, CO2Tranport, distance]);

  useEffect(() => {
    // getPoints(reports);
    getPoints();
  }, [showModal]);

  useEffect(() => {
    console.log(`Copper: ${copper}, Metals: ${metal}, Plastics: ${plastic}, Other: ${otherMaterials}, SF6: ${SF6}`);
  }, [copper, metal, plastic, otherMaterials, SF6]);

  // useEffect(() => {
  //   setCO2Saved(CO2Materials - CO2Tranport);
  // }, [CO2Materials, CO2Tranport]);

  const html2pdf = require('html2pdf.js');

  useEffect(() => {
    if (triggerDownload) {
      const element = document.getElementById('greenReport');
      if (element) {
        let sanitizedTitle = proposalTitle?.replace(/[^a-zA-Z0-9 ]+/g, '').replace(/\s+/g, '-') ?? 'offer';

        const maxLength = 40;
        if (sanitizedTitle.length > maxLength) {
          sanitizedTitle = sanitizedTitle.substring(0, maxLength);
        }

        // Generate PDF
        html2pdf()
          .from(element)
          .set({
            margin: 1,
            filename: `${sanitizedTitle}-green-report.pdf`,
            html2canvas: { scale: 1 }, // Higher scale for better quality
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
          })
          .save();
      }
    }
  }, [triggerDownload]);

  if (!showModal) return null;

  return (
      <div className="report-modal-overlay">
        <div className='report-modal-content'>
          <div className="report-modal-header">
            <div className="heading">Green Report</div>
            <div className="green-report__date">
              <div>
                <span>{formattedDate}</span>
                <button className="btn-close" onClick={onHide}></button>
              </div>
            </div>
          </div>
          <div className="report-modal-body">
            <div className="pdf-viewer">
              <div className="pdf-page" id={'greenReport'}>
                <div className="background">
                  <svg className="seed-icon" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 512 512">
                    <path
                        d="M64 96H0c0 123.7 100.3 224 224 224v144c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V320C288 196.3 187.7 96 64 96zm384-64c-84.2 0-157.4 46.5-195.7 115.2 27.7 30.2 48.2 66.9 59 107.6C424 243.1 512 147.9 512 32h-64z"/>
                  </svg>
                </div>
                <div className="green-report">
                  <div className="green-report__header">
                    <div className="green-report__title">
                      {proposalTitle}
                    </div>
                  </div>
                  <div className="green-report__info">
                    Take advantage of our special offer on Circuit Breaker 1! This essential component ensures
                    safety and
                    efficiency in your electrical grid by automatically interrupting electricity flow during
                    overloads or
                    short
                    circuits, protecting your system from damage.
                  </div>
                  <div className="green-report__chart-section">
                    <div className="green-report__chart-section title">
                      Estimation of avoided environmental impacts
                    </div>
                    <div className="green-report__chart-section container">
                      <div className="green-report__chart-section container-header">
                        Picked up Material
                      </div>
                      <div className="green-report__chart-section container-body">
                        <div className="green-report__chart-section container-left">
                          <div className="green-report__chart-section container-reading-section">
                            <div className="green-report__chart-section">
                              <div className="green-report__chart-section container-reading-section-body">
                                <div className="green-report__chart-section container-reading-content">
                                  <div className="reading-data">
                                    <div className="reading-number">{totalValue.toFixed(2)}KG</div>
                                    <div className="reading-percentage">{totalPer.toFixed(2)}%</div>
                                  </div>
                                </div>
                              </div>
                              <div className="material-text">RAW MATERIAL RECYCLED</div>
                            </div>
                            <div className="green-report__chart-section container-labels">
                              <div className="legend blue"></div>
                            </div>
                          </div>

                          <div className="green-report__chart-section container-reading-section">
                            <div className="green-report__chart-section">
                              <div className="green-report__chart-section container-reading-section-body">
                                <div className="green-report__chart-section container-reading-content">
                                  <div className="reading-data">
                                    <div className="reading-number">{energyRecover.toFixed(2)}KG</div>
                                    <div className="reading-percentage">{energyRecoverPer.toFixed(2)}%</div>
                                  </div>
                                </div>
                              </div>
                              <div className="material-text">ENERGY RECOVER</div>
                            </div>
                            <div className="green-report__chart-section container-labels">
                              <div className="legend grey"></div>
                            </div>
                          </div>

                          <div className="green-report__chart-section container-reading-section">
                            <div className="green-report__chart-section">
                              <div className="green-report__chart-section container-reading-section-body">
                                <div className="green-report__chart-section container-reading-content">
                                  <div className="reading-data">
                                    <div className="reading-number">{disposal.toFixed(2)}KG</div>
                                    <div className="reading-percentage">{disposalPer.toFixed(2)}%</div>
                                  </div>
                                </div>
                              </div>
                              <div className="material-text">DISPOSAL</div>
                            </div>
                            <div className="green-report__chart-section container-labels">
                              <div className="legend white"></div>
                            </div>
                          </div>

                          <div className="green-report__chart-section container-reading-section total">
                            <div className="green-report__chart-section">
                              <div className="green-report__chart-section container-reading-section-body">
                                <div className="green-report__chart-section container-reading-content">
                                  <div className="reading-data">
                                    <div className="reading-number">{totalEstimation.toFixed(2)}KG</div>
                                    <div className="reading-percentage">100%</div>
                                  </div>
                                </div>
                              </div>
                              <div className="material-text">TOTAL</div>
                            </div>
                          </div>

                        </div>

                        <div className="green-report__chart-section container-right">
                          <Chart width={270} height={270}>
                            <Pie
                                data={data}
                                cx={130}
                                cy={135}
                                innerRadius={40}
                                outerRadius={130}
                                fill="#8884d8"
                                paddingAngle={0}
                                blendStroke={true}
                                dataKey="value"
                            >
                              {data.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                              ))}
                            </Pie>
                          </Chart>
                        </div>
                      </div>
                    </div>

                    <div className="green-report__chart-section container">
                      <div className="green-report__chart-section container-header">
                        RAW MATERIAL RECYCLED WITH THE TREATMENT
                      </div>
                      <div className="green-report__chart-section container-body">
                        <div className="green-report__chart-section container-left">
                          {chartData && chartData.map((item, index) => {
                            return (
                                <div key={index} className="green-report__chart-section container-reading-section">
                                  <div className="green-report__chart-section">
                                    <div className="green-report__chart-section container-reading-section-body">
                                      <div className="green-report__chart-section container-reading-content">
                                        <div className="reading-data">
                                          <div className="reading-number">{item.value.toFixed(2)}KG</div>
                                          <div className="reading-percentage">{item.percentage.toFixed(2)}%</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="material-text">{item.name}</div>
                                  </div>
                                  <div className="green-report__chart-section container-labels">
                                    <div className="legend" style={{ backgroundColor: `${item.color}` }}></div>
                                  </div>
                                </div>
                            );
                          })}

                          <div className="green-report__chart-section container-reading-section total">
                            <div className="green-report__chart-section">
                              <div className="green-report__chart-section container-reading-section-body">
                                <div className="green-report__chart-section container-reading-content">
                                  <div className="reading-data">
                                    <div className="reading-number">{totalValue.toFixed(2)}KG</div>
                                    <div className="reading-percentage">100%</div>
                                  </div>
                                </div>
                              </div>
                              <div className="material-text">TOTAL</div>
                            </div>
                          </div>

                        </div>

                        <div className="green-report__chart-section container-right">
                          <Chart width={270} height={270}>
                            <Pie
                                data={allData}
                                cx={130}
                                cy={135}
                                innerRadius={40}
                                outerRadius={130}
                                fill="#8884d8"
                                paddingAngle={0}
                                blendStroke={true}
                                dataKey="value"
                            >
                              {allData && allData.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={entry.color}/>
                              ))}
                            </Pie>
                          </Chart>
                        </div>
                      </div>
                    </div>

                    {/* --- Back page --- */}
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="report-modal-footer">
            <div>{formattedDate}</div>
            <div>
              <img src={AssetGallery.abbLogo} width="53" height="25" alt="ABB Logo"/>
            </div>
            <div>Page 1/2</div>
          </div>
        </div>
        <div className='report-modal-content'>
          <div className="report-modal-header">
            <div className="heading">Green Report</div>
            <div className="green-report__date">
              <div>
                <span>{formattedDate}</span>
                <button className="btn-close" onClick={onHide}></button>
              </div>
            </div>
          </div>
          <div className="report-modal-body">
            <div className="pdf-viewer">
              <div className="pdf-page">
                <div className="green-report">
                  <div className="green-report__header">
                    <div className="green-report__title">
                      {proposalTitle}
                    </div>
                  </div>
                  <div className="green-report__chart-section">
                    <div className="green-report__chart-section container">
                      <div className="green-report__chart-section container-header">
                        ENVIRONMENTAL IMPACT OF THE PROPOSAL
                      </div>
                      <div className="green-report__chart-section environment-body">
                        <div className="reading-section bolt-icon">
                          <div className="reading-text">{getRandomValue(40, 100).toFixed(2)}KWH</div>
                          <div className="properties-text">ENERGY SAVED EQUIVALENT TO:</div>
                        </div>
                        <div className="reading-section cloud-icon">
                          <div className="reading-text">{CO2Saved != 0 ? CO2Saved.toFixed(2) : 0}KG CO2 EQ</div>
                          <div className="properties-text">CO2 AVOIDED EQUIVALENT TO:</div>
                        </div>
                      </div>
                      <div className="environment-list-section">
                        <div className="environment-list-body">
                          <div className="environment-icon-roof">
                            <img src={AssetGallery.peopleRoof} alt="roof" width={40} height={40} />
                            <div>Average consumption per year of 5 families</div>
                          </div>
                          <div className="environment-icon-car">
                            <img src={AssetGallery.car} alt="car" width={40} height={40}/>
                            <div>CO2 absorbed by {CO2Tranport != 0 ? CO2Tranport.toFixed() : 0} trees in 1 year</div>
                          </div>
                          <div className="environment-icon-tree">
                            <img src={AssetGallery.treeCity} alt="tree" width={40} height={40}/>
                            <div>CO2 absorbed by {CO2Tranport != 0 ? CO2Tranport.toFixed() : 0} trees in 1 year</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="environment-section">
                      <div>{"The Green Report, meticulously curated and issued by ABB, stands as a prestigious symbol of eco-consciousness and environmental stewardship. It signifies a steadfast dedication to sustainability and underscores a firm commitment to conserving our planet's precious resources. As a recognized certification, the Green Report embodies ABB's unwavering pledge to prioritize environmental responsibility and pave the way for a greener, more sustainable future."}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="report-modal-footer">
            <div>{formattedDate}</div>
            <div>
              <img src={AssetGallery.abbLogo} alt="ABB Logo"/>
            </div>
            <div>Page 2/2</div>
          </div>
        </div>
        <div className="end-section"></div>
      </div>

  );
};

export default GreenReport;