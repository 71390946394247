import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createContact, fetchActiveContact, updateContact } from '../../../actions/Contact';
import {
  getActiveContact,
  isPostingContact,
  postingContactError,
  postingContactFailed,
  postingContactValidationErrors,
  isFetchingContacts,
} from '../../../selectors/Contact';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { Alert } from 'react-bootstrap';



const ContactForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeContact = useSelector(getActiveContact);
  const isPosting = useSelector(isPostingContact);
  const isFetching = useSelector(isFetchingContacts);
  const postingFailed = useSelector(postingContactFailed);
  const postingError = useSelector(postingContactError);
  const postingValidationErrors = useSelector(postingContactValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.CONTACT_UPDATE);

  const formConfig = [
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
    { field: 'email', validators: [isRequired] },
    { field: 'phoneNumber', validators: [isRequired] },
    { field: 'companyName', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    if (editMode) {
      dispatch(fetchActiveContact(activeId));
    }
  }, [dispatch]);
  

  useEffect(() => {
    if (activeContact && editMode) {
      setFirstName(activeContact?.firstName);
      setLastName(activeContact?.lastName);
      setEmail(activeContact?.email);
      setPhoneNumber(activeContact?.phoneNumber);
      setCompanyName(activeContact?.companyName);
    }
  }, [activeContact]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.CONTACT);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createContact(firstName, lastName, email, phoneNumber, companyName));
    }
  };

  const onSave = async () => {
    setHasSubmitted(true);
    dispatch(updateContact(activeId, firstName, lastName, email, phoneNumber, companyName));
  };


  return <View>
        <h4>{editMode ? 'Edit' : 'Create'} Contact</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <Input v={v} err={err}
                   name="firstName"
                   id="firstName"
                   label="First Name"
                   required
                   value={firstName}
                   onChange={setFirstName}
                   disabled={isPosting || isFetching}
                   onBlur={setFirstName}
                   placeholder="Insert the contact's first name"
            />
            <Input v={v} err={err}
                   required
                   name="lastName"
                   id="lastName"
                   label="Last Name"
                   value={lastName}
                   disabled={isPosting || isFetching}
                   onChange={setLastName}
                   onBlur={setLastName}
                   placeholder="Insert the contact's last name"
            />
            <Input v={v} err={err}
                   required
                   name="email"
                   id="email"
                   label="Email"
                   value={email}
                   disabled={isPosting || isFetching}
                   onChange={setEmail}
                   onBlur={setEmail}
                   placeholder="Insert an email"
            />
            <Input v={v} err={err}
                   required
                   type="tel"
                   name="phoneNumber"
                   id="phoneNumber"
                   label="Phone Number"
                   value={phoneNumber}
                   disabled={isPosting || isFetching}
                   onChange={setPhoneNumber}
                   onBlur={setPhoneNumber}
                   placeholder="Insert a phone number"
            />
            <Input v={v} err={err}
                   required
                   name="companyName"
                   id="companyName"
                   label="Company Name"
                   value={companyName}
                   disabled={isPosting || isFetching}
                   onChange={setCompanyName}
                   onBlur={setCompanyName}
                   placeholder="Insert a company name"
            />
            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => editMode ? onSave() : onSubmit()}
                            title={editMode ? 'SAVE' : 'CREATE'} />
                </div>
            </div>
        </form>
    </View>;
};

export default ContactForm;
