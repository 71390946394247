import React, { useState, useRef } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import './ReadMoreLess.scss';

type Props = {
  text: string;
  limit: number;
};

const ReadMoreLess = ({ text, limit }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
    if (isExpanded) {
      setShowTooltip(false);
    }
  };

  const handleReadMoreClick = () => {
    toggleReadMore();
  };

  const handleToolTip = () => {
    setShowTooltip(!showTooltip);
  };

  const getDisplayText = () => {
    if (isExpanded) {
      return text;
    }
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  };

  return (
      <div className="read-more-less">
        <div className="read-more-text">{getDisplayText()}</div>
        {text.length > limit && text.length > 200 ?
          (
            <div
                ref={target}
                className="read-more"
                onClick={handleToolTip}
            >
              {showTooltip ? 'Read Less' : 'Read More'}
            </div>
          ) :
          ( text.length > limit && (
            <div
                ref={target}
                className="read-more"
                onClick={handleReadMoreClick}
            >
              {isExpanded ? 'Read Less' : 'Read More'}
            </div>
          )
          )}

        <Overlay
            target={target.current}
            show={showTooltip && !isExpanded}
            placement="top"
        >
          {(props) => (
              <Tooltip id="read-more-tooltip" {...props}>
                {text}
              </Tooltip>
          )}
        </Overlay>
      </div>
  );
};

export default ReadMoreLess;
