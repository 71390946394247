import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscription, fetchActiveSubscription, updateSubscription } from '../../../actions/Subscription';
import { getPlans } from '../../../selectors/Plan';
import { getClients } from '../../../selectors/Client';
import {
  getActiveSubscription, isFetchingSubscriptions,
  isPostingSubscription,
  postingSubscriptionError,
  postingSubscriptionFailed,
  postingSubscriptionValidationErrors,
} from '../../../selectors/Subscription';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { fetchClients } from '../../../actions/Client';
import { fetchPlans } from '../../../actions/Plan';
import { convertToLocal } from '../../../helpers/functions';
import DateSelect from '../../../components/DateSelect';
import { formatISO } from 'date-fns';
import { fetchTransactions } from '../../../actions/Transaction';
import { getTransactions } from '../../../selectors/Transaction';
import CheckBox from '../../../components/CheckBox';


const SubscriptionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeSubscription = useSelector(getActiveSubscription);
  const plans = useSelector(getPlans);
  const clients = useSelector(getClients);
  const transactions = useSelector(getTransactions);
  const isPosting = useSelector(isPostingSubscription);
  const isFetching = useSelector(isFetchingSubscriptions);
  const postingFailed = useSelector(postingSubscriptionFailed);
  const postingError = useSelector(postingSubscriptionError);
  const postingValidationErrors = useSelector(postingSubscriptionValidationErrors);
  const [isIndefinite, setIsIndefinite] = useState(false);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.SUBSCRIPTION_ADMIN_UPDATE);

  const formConfig = [
    { field: 'planId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'transactionId', validators: [isRequired] },
    { field: 'startDate', validators: [] },
    { field: 'endDate', validators: [] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [planId, setPlanId] = useState('');
  const [clientId, setClientId] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [startDate, setStartDateTime] = useState(new Date());
  const [endDate, setEndDateTime] = useState(new Date());
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(fetchPlans());
    dispatch(fetchClients());
    dispatch(fetchTransactions());
    if (editMode) {
      dispatch(fetchActiveSubscription(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeSubscription && editMode) {
      setPlanId(activeSubscription?.planId);
      setClientId(activeSubscription?.clientId);
      setTransactionId(activeSubscription?.transactionId);
      setStartDateTime(new Date(convertToLocal(activeSubscription?.startDate)));
      setEndDateTime(new Date(convertToLocal(activeSubscription?.endDate)));
      setActive(activeSubscription?.active);
    }
  }, [activeSubscription]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.SUBSCRIPTION_ADMIN);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createSubscription(planId, clientId, transactionId, formatISO(startDate), isIndefinite ? null : formatISO(endDate), active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateSubscription(activeId, planId, clientId, transactionId, formatISO(startDate), isIndefinite ? null : formatISO(endDate), active));
    }
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  const onPlanSelect = (obj: any) => {
    setPlanId(obj.value);
  };

  const onTransactionSelect = (obj: any) => {
    setTransactionId(obj.value);
  };

  const planItems = plans?.data.map(plan => ({ label: plan.title, value: plan.id })) || [];
  const clientItems = clients?.data.map(client => ({ label: client.title, value: client.id })) || [];
  const transactionItems = transactions?.data.map(transaction => ({ label: transaction.status, value: transaction.id })) || [];

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Subscription</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown
                    v={v}
                    err={err}
                    id="planId"
                    placeholder="Please select a Plan"
                    required
                    label="Plan"
                    items={planItems}
                    value={planId}
                    disabled={isPosting || isFetching}
                    onSelect={onPlanSelect}
                    type="default"
                />
                <DropDown
                    v={v}
                    err={err}
                    id="clientId"
                    placeholder="Please select a Client"
                    required
                    label="Client"
                    items={clientItems}
                    value={clientId}
                    disabled={isPosting || isFetching}
                    onSelect={onClientSelect}
                    type="default"
                />
                <DropDown v={v} err={err}
                          id="transactionId"
                          placeholder="Please select a Transaction"
                          required
                          label="Transaction"
                          items={transactionItems}
                          disabled={isPosting || isFetching}
                          value={transactionId}
                          onSelect={onTransactionSelect}
                          type="default"
                />
                <DateSelect
                    onChange={setStartDateTime}
                    value={startDate}
                />
              {!isIndefinite && <DateSelect
                  onChange={setEndDateTime}
                  value={endDate}
              />}
              <CheckBox checked={isIndefinite} label={'No end date'} onChange={() => setIsIndefinite(!isIndefinite)}/>
              <CheckBox checked={active} label={'Is Active'} onChange={() => setActive(!active)}/>
              <Button
                    disabled={!isFormValid()}
                    isLoading={isPosting}
                    onClick={() => (editMode ? onSave() : onSubmit())}
                    title={editMode ? 'SAVE' : 'CREATE'}
                />
            </form>
        </View>
  );
};

export default SubscriptionForm;
