import React from 'react';
import gallery from '../AssetGallery/AssetGallery';
import { format } from 'date-fns';

type Props = {
    
  values?: Date | null | undefined;
  onClick? : any
};

const CustomInput = React.memo((props:Props) => {
  const {

    values = null,
    onClick = () => {},

  } = props;
  console.log(values, 'aaaa');
  return (
        <div className="custom-datepicker-input"  onClick={onClick}>
            <img src={gallery.calendarImg} alt="" className="calendar-icon" />
            {values ? (
                <span>{format(values, 'yyyy-MM-dd HH:mm:ss')}</span>
            ) : (
                <span>Select Date</span>
            )}
        </div>
  );
});
CustomInput.displayName = 'CustomInput';
export default CustomInput;
