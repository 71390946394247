import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createRoleGroup   } from '../../../actions/RoleGroup';
import { getRoles } from '../../../selectors/Role';
import { getGroups } from '../../../selectors/Group';
import { fetchRoles } from '../../../actions/Role';
import { fetchGroups } from '../../../actions/Group';


import {
  isPostingRoleGroup,
  postingRoleGroupError,
  postingRoleGroupFailed,
  postingRoleGroupValidationErrors,
  isFetchingRoleGroups,
} from '../../../selectors/RoleGroup';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';



const RoleGroupForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const roles = useSelector(getRoles);
  const groups = useSelector(getGroups);
  const isPosting = useSelector(isPostingRoleGroup);
  const isFetching = useSelector(isFetchingRoleGroups);
  const postingFailed = useSelector(postingRoleGroupFailed);
  const postingError = useSelector(postingRoleGroupError);
  const postingValidationErrors = useSelector(postingRoleGroupValidationErrors);



  const formConfig = [
    { field: 'roleId', validators: [isRequired] },
    { field: 'groupId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [roleId, setRoleId] = useState('');
  const [groupId, setGroupId] = useState('');

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchGroups());

  }, [dispatch]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ROLE_GROUP);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createRoleGroup(roleId, groupId ));
    }
  };

  const itemsRoles = roles?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
  };
  const onRoleSelect = (obj: any) => {
    setRoleId(obj.value);
  };

  return <View>
        <h4>Create RoleGroup</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="roleId"
                      placeholder="Please select an Role"
                      required label="Role"
                      items={itemsRoles}
                      value={roleId}
                      disabled={isPosting || isFetching}
                      onSelect={onRoleSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="groupId"
                      placeholder="Please select a Group"
                      required label="Group"
                      items={itemsGroups}
                      value={groupId}
                      disabled={isPosting || isFetching}
                      onSelect={onGroupSelect}
                      type="default"
            />
            
            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default RoleGroupForm;
