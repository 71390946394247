import { ApiDataModel, FormError } from './Common';

export const FETCH_STATUS_CODES_REQUEST = 'FETCH_STATUS_CODES_REQUEST';
export const FETCH_STATUS_CODES_RESPONSE = 'FETCH_STATUS_CODES_RESPONSE';
export const FETCH_STATUS_CODES_FAILURE = 'FETCH_STATUS_CODES_FAILURE';

export const FETCH_STATUS_CODE_REQUEST = 'FETCH_STATUS_CODE_REQUEST';
export const FETCH_STATUS_CODE_RESPONSE = 'FETCH_STATUS_CODE_RESPONSE';
export const FETCH_STATUS_CODE_FAILURE = 'FETCH_STATUS_CODE_FAILURE';

export const POST_STATUS_CODE_REQUEST = 'POST_STATUS_CODE_REQUEST';
export const POST_STATUS_CODE_RESPONSE = 'POST_STATUS_CODE_RESPONSE';
export const POST_STATUS_CODE_FAILURE = 'POST_STATUS_CODE_FAILURE';

export interface StatusCode {
  id: string;
  value: string;
  code: bigint;
}

export interface StatusCodeModel extends Omit<ApiDataModel, 'data'> {
  data: StatusCode[];
}

export interface StatusCodeState {
  statusCodes: StatusCodeModel | null;
  activeStatusCode: StatusCode | null;
  isFetchingStatusCodes: boolean;
  fetchingStatusCodesFailed: boolean;
  postingStatusCodeFailed? : boolean;
  isPostingStatusCode: boolean;
  postingStatusCodeValidationErrors: FormError[] | null;
  postingStatusCodeError: string | null;
}

interface FetchStatusCodesRequestAction {
  type: typeof FETCH_STATUS_CODES_REQUEST;
  payload: null;
}

interface FetchStatusCodesResponseAction {
  type: typeof FETCH_STATUS_CODES_RESPONSE;
  payload: StatusCodeModel;
}

interface FetchStatusCodesFailureAction {
  type: typeof FETCH_STATUS_CODES_FAILURE;
  payload: null;
}

interface FetchStatusCodeRequestAction {
  type: typeof FETCH_STATUS_CODE_REQUEST;
  payload: null;
}

interface FetchStatusCodeResponseAction {
  type: typeof FETCH_STATUS_CODE_RESPONSE;
  payload: StatusCode;
}

interface FetchStatusCodeFailureAction {
  type: typeof FETCH_STATUS_CODE_FAILURE;
  payload: null;
}

interface PostStatusCodeRequestAction {
  type: typeof POST_STATUS_CODE_REQUEST;
  payload: null;
}

interface PostStatusCodeResponseAction {
  type: typeof POST_STATUS_CODE_RESPONSE;
  payload: null;
}

interface PostStatusCodeFailureAction {
  type: typeof POST_STATUS_CODE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type StatusCodeActionTypes =
  | FetchStatusCodesRequestAction
  | FetchStatusCodesResponseAction
  | FetchStatusCodesFailureAction
  | PostStatusCodeRequestAction
  | PostStatusCodeResponseAction
  | PostStatusCodeFailureAction
  | FetchStatusCodeRequestAction
  | FetchStatusCodeResponseAction
  | FetchStatusCodeFailureAction;