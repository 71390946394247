import { RootState } from '../store/configureStore';

export const getEntryCompositions = (state: RootState) => state?.entryComposition?.entryCompositions;

export const getActiveEntryComposition = (state: RootState) => state?.entryComposition?.activeEntryComposition;

export const isFetchingEntryCompositions = (state: RootState) => state?.entryComposition?.isFetchingEntryCompositions;

export const isPostingEntryComposition = (state: RootState) => state?.entryComposition?.isPostingEntryComposition;

export const postingEntryCompositionFailed = (state: RootState) => state?.entryComposition?.postingEntryCompositionFailed;

export const postingEntryCompositionError = (state: RootState) => state?.entryComposition?.postingEntryCompositionError;

export const postingEntryCompositionValidationErrors = (state: RootState) =>
  state?.entryComposition?.postingEntryCompositionValidationErrors;
