import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createField, fetchActiveField, updateField } from '../../../actions/Field';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import { getFieldTypes } from '../../../selectors/FieldType';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import { fetchCategories } from '../../../actions/Category';
import {
  getActiveField,
  isPostingField,
  postingFieldError,
  postingFieldFailed,
  postingFieldValidationErrors,
  isFetchingFields,
} from '../../../selectors/Field';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { fetchFieldTypes } from '../../../actions/FieldType';
import { getCategories } from '../../../selectors/Category';


const FieldForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeField = useSelector(getActiveField);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const categories = useSelector(getCategories);
  const fieldTypes = useSelector(getFieldTypes);
  const isPosting = useSelector(isPostingField);
  const isFetching = useSelector(isFetchingFields);
  const postingFailed = useSelector(postingFieldFailed);
  const postingError = useSelector(postingFieldError);
  const postingValidationErrors = useSelector(postingFieldValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.FIELD_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'categoryId', validators: [isRequired] },
    { field: 'fieldTypeId', validators: [isRequired] },
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [fieldTypeId, setFieldTypeId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchCategories());
    dispatch(fetchFieldTypes());
    if (editMode) {
      dispatch(fetchActiveField(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients]);

  useEffect(() => {
    if (activeField && editMode) {
      setGroupId(activeField?.groupId);
      setClientId(activeField?.clientId);
      setCategoryId(activeField?.categoryId);
      setFieldTypeId(activeField?.fieldTypeId);
      setTitle(activeField?.title);
      setDescription(activeField?.description);
      // @ts-ignore
      setActive(activeField?.active === 1 || activeField?.active === true);
    }
  }, [activeField]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.FIELD);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createField(groupId, clientId, categoryId, fieldTypeId, title, description, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateField(activeId, groupId, clientId, categoryId, fieldTypeId, title, description, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsCategories = categories?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsFieldTypes = fieldTypes?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  const onFieldTypeSelect = (obj: any) => {
    setFieldTypeId(obj.value);
  };

  const onCategorySelect = (obj: any) => {
    setCategoryId(obj.value);
  };
  
  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Field</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown v={v} err={err}
                          id="groupId"
                          placeholder="Please select a Group"
                          required label="Group"
                          items={itemsGroups}
                          value={groupId}
                          disabled={isPosting || isFetching}
                          onSelect={onGroupSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="clientId"
                          placeholder="Please select a Client"
                          required
                          label="Client"
                          items={itemsClients}
                          disabled={isPosting || isFetching || disabledClient}
                          value={clientId}
                          onSelect={onClientSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                        id="categoryId"
                        placeholder="Please select a Category"
                        required
                        label="Category"
                        items={itemsCategories}
                        disabled={isPosting || isFetching}
                        value={categoryId}
                        onSelect={onCategorySelect}
                        type="default"
                />
                <DropDown v={v} err={err}
                          id="fieldTypeId"
                          placeholder="Please select a Field Type"
                          required
                          label="Field Type"
                          items={itemsFieldTypes}
                          disabled={isPosting || isFetching}
                          value={fieldTypeId}
                          onSelect={onFieldTypeSelect}
                          type="default"
                />
                <Input v={v} err={err}
                       name="title"
                       id="title"
                       label="Title"
                       required
                       value={title}
                       onChange={setTitle}
                       disabled={isPosting || isFetching}
                       onBlur={setTitle}
                       placeholder="Insert a title"
                />
                <Input v={v} err={err}
                       required
                       name="description"
                       id="description"
                       label="Description"
                       value={description}
                       disabled={isPosting || isFetching}
                       onChange={setDescription}
                       onBlur={setDescription}
                       placeholder="Insert a description"
                />
                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default FieldForm;
