import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createRoleClient   } from '../../../actions/RoleClient';
import { getRoles } from '../../../selectors/Role';
import { getClients } from '../../../selectors/Client';
import { fetchRoles } from '../../../actions/Role';
import { fetchClients } from '../../../actions/Client';


import {
  isPostingRoleClient,
  postingRoleClientError,
  postingRoleClientFailed,
  postingRoleClientValidationErrors,
  isFetchingRoleClients,
} from '../../../selectors/RoleClient';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';



const RoleClientForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const roles = useSelector(getRoles);
  const clients = useSelector(getClients);
  const isPosting = useSelector(isPostingRoleClient);
  const isFetching = useSelector(isFetchingRoleClients);
  const postingFailed = useSelector(postingRoleClientFailed);
  const postingError = useSelector(postingRoleClientError);
  const postingValidationErrors = useSelector(postingRoleClientValidationErrors);



  const formConfig = [
    { field: 'roleId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [roleId, setRoleId] = useState('');
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchClients());
  }, [dispatch]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ROLE_CLIENT);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createRoleClient(roleId, clientId ));
    }
  };

  const itemsRoles = roles?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsClients = clients?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };
  const onRoleSelect = (obj: any) => {
    setRoleId(obj.value);
  };

  return <View>
        <h4>Create RoleClient</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="roleId"
                      placeholder="Please select an Role"
                      required label="Role"
                      items={itemsRoles}
                      value={roleId}
                      disabled={isPosting || isFetching}
                      onSelect={onRoleSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="clientId"
                      placeholder="Please select a Client"
                      required label="Client"
                      items={itemsClients}
                      value={clientId}
                      disabled={isPosting || isFetching}
                      onSelect={onClientSelect}
                      type="default"
            />
            
            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default RoleClientForm;
