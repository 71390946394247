import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createFormFieldRole   } from '../../../actions/FormFieldRole';
import { getFormFields } from '../../../selectors/FormField';
import { getRoles } from '../../../selectors/Role';
import { fetchFormFields } from '../../../actions/FormField';
import { fetchRoles } from '../../../actions/Role';


import {
  isPostingFormFieldRole,
  postingFormFieldRoleError,
  postingFormFieldRoleFailed,
  postingFormFieldRoleValidationErrors,
  isFetchingFormFieldRoles,
} from '../../../selectors/FormFieldRole';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';



const FormFieldRoleForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formFields = useSelector(getFormFields);
  const roles = useSelector(getRoles);
  const isPosting = useSelector(isPostingFormFieldRole);
  const isFetching = useSelector(isFetchingFormFieldRoles);
  const postingFailed = useSelector(postingFormFieldRoleFailed);
  const postingError = useSelector(postingFormFieldRoleError);
  const postingValidationErrors = useSelector(postingFormFieldRoleValidationErrors);



  const formConfig = [
    { field: 'formFieldId', validators: [isRequired] },
    { field: 'roleId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [formFieldId, setFormFieldId] = useState('');
  const [roleId, setRoleId] = useState('');

  useEffect(() => {
    dispatch(fetchFormFields());
    dispatch(fetchRoles());

  }, [dispatch]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.FORM_FIELD_ROLE);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createFormFieldRole(formFieldId, roleId));
    }
  };

  const itemsFormFields = formFields?.data.map(r => ({ label: r.id, value: r.id })) || [];
  const itemsRoles = roles?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onFormFieldSelect = (obj: any) => {
    setFormFieldId(obj.value);
  };
  const onRoleSelect = (obj: any) => {
    setRoleId(obj.value);
  };

  return <View>
        <h4>Create FormFieldRole</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="formFieldId"
                      placeholder="Please select a Form Field"
                      required label="Form Field"
                      items={itemsFormFields}
                      value={formFieldId}
                      disabled={isPosting || isFetching}
                      onSelect={onFormFieldSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="roleId"
                      placeholder="Please select a Role"
                      required label="Role"
                      items={itemsRoles}
                      value={roleId}
                      disabled={isPosting || isFetching}
                      onSelect={onRoleSelect}
                      type="default"
            />
            
            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default FormFieldRoleForm;
